import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";
import { EquipmentCategory } from "./types";
import { isInIframe } from "components/utils/dom";

export const useEquipmentCategories = (
    applicationNumber?: string,
    parentCategoryNumber?: string
): [categories: EquipmentCategory[], isLoading: boolean, error: any] => {
    const baseUrl =
        !isInIframe() && applicationNumber ? getUrl(process.env.REACT_APP_EQUIPMENT_CATEGORIES_ENDPOINT, { applicationNumber }) : null;
    const query = new URLSearchParams();
    if (parentCategoryNumber) {
        query.append("categoryNumber", parentCategoryNumber);
    }

    const url = baseUrl ? baseUrl + "?" + query.toString() : null;

    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    if (isInIframe()) {
        return [mockedCategories, false, null];
    }

    return [data?.equipmentCategoryList, isLoading, error];
};

const mockedCategories: EquipmentCategory[] = [
    {
        categorynumber: "eb7ce81c-94b6-4dc2-8451-9f743b969ade",
        category: "Category 1",
        parentcategorynumber: null,
        imgFileLocation: "layer-group",
        childcount: 1,
    },
    {
        categorynumber: "ed9c76e7-7f9e-498e-b432-ea4c9ae54495",
        category: "Category 2",
        parentcategorynumber: null,
        imgFileLocation: "dial",
        childcount: 1,
    },
];
