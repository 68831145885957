import { IconName } from "@fortawesome/fontawesome-svg-core";
import { IconWithText } from "components/ContactCard/IconWithText";
import { ExternalLink } from "components/ExternalLink";
import { ContractorsResponseItem } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { formatPhoneNumber } from "components/utils/format";
import { getExternalLink } from "components/utils/links";
import { Offcanvas } from "react-bootstrap";

const EMPTY_VALUE = "-";

export const ContractorDetailsOffcanvas = ({ contact, onClose }: { contact: ContractorsResponseItem; onClose: () => void }) => {
    const contactEmail = contact.email ? <a href={`mailto:${contact.email}`}>{contact.email}</a> : null;

    return (
        <Offcanvas className="contact-details-sidebar" show={true} placement="end" onHide={onClose} aria-labelledby="contact-details-title">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="contact-details-title">
                    <span className="fw-semi-bold">Contractor Information</span>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center align-self-stretch gap-2 lh-base">
                    <div className="fs-4 fw-semi-bold">{contact.company}</div>
                </div>

                <hr className="my-0"></hr>

                <dl className="d-flex flex-column m-0 gap-3">
                    {(contact.firstName || contact.lastName) && (
                        <ListItem icon={"user"} label="Contact" value={[contact.firstName, contact.lastName].join(" ")} />
                    )}

                    <ListItem icon={"location-dot"} label="Address" value={contact.fullAddress || EMPTY_VALUE} />

                    {contact.url && (
                        <ListItem
                            icon={"globe"}
                            label="Website"
                            value={
                                <ExternalLink className="py-2" href={getExternalLink(contact.url)} openInNewTab>
                                    {contact.url}
                                </ExternalLink>
                            }
                        />
                    )}

                    {contactEmail && <ListItem icon={"envelope"} label="Email" value={contactEmail} />}
                    {contact.phone && (
                        <ListItem
                            icon={"phone"}
                            label="Phone"
                            value={<a href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</a>}
                        />
                    )}
                    {contact.description && <ListItem icon={"memo"} label="Description" value={contact.description} />}
                </dl>

                <hr className="my-0"></hr>

                <dl className="d-flex flex-column m-0 pt-2 gap-3">
                    <div className="mt-2">
                        <div className="fw-semi-bold">Technologies Available:</div>{" "}
                        <div className="text-gray-600">{contact.technologies}</div>
                    </div>
                    <div className="mt-2">
                        <div className="fw-semi-bold">Services Provided:</div> <div className="text-gray-600">{contact.services}</div>
                    </div>
                </dl>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

const ListItem = ({ icon, label, value }: { icon?: IconName; label: string; value: string | React.ReactNode }) => {
    return (
        <div className="row my-0">
            <dt className="col-5">
                {icon ? (
                    <IconWithText icon={icon}>
                        <span className="text-body fw-normal">{label}</span>
                    </IconWithText>
                ) : (
                    <span className="text-body fw-normal">{label}</span>
                )}
            </dt>
            <dd className="col-7 text-break m-0">{value}</dd>
        </div>
    );
};
