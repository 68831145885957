import { getUrl, httpGet, httpPut } from "components/utils/http";

export async function getAnonymousFormData(applicationNumber: string, taskNumber: string, codeNumber: string) {
    const url = getUrl(process.env.REACT_APP_ANONYMOUS_APPLICATION_ENDPOINT, { applicationNumber, taskNumber, codeNumber });
    if (!url) throw new Error("Invalid anonymous application url");

    return httpGet(url, "get_anonymous_form");
}

export async function submitAnonymousApplication(
    applicationNumber: string,
    taskNumber: string,
    codeNumber: string,
    applicationData: object
) {
    const url = getUrl(process.env.REACT_APP_ANONYMOUS_APPLICATION_WIZARD_ENDPOINT, { applicationNumber, taskNumber, codeNumber });
    if (!url) throw new Error("Invalid anonymous application url");

    return httpPut(url, applicationData, "save_anonymous_form");
}
