import { useMemo } from "react";
import { isNil } from "lodash";
import useSWR from "swr";
import { getUrl, httpPostAuthorized } from "components/utils/http";
import SelectWidget from "./SelectWidget";
import CheckboxesWidget from "./CheckboxesWidget";
import RadioWidget from "./RadioWidget";
import { WidgetProps } from "@rjsf/core";

export const GeneralProcedureWidget = (props: WidgetProps) => {
    const { applicationNumber } = props.formContext ?? {};
    const {
        procDisplayType,
        procId,
        parameterNamesList = [],
        parameterValuesList = [],
    } = (props.options || {}) as GeneralProcedureWidgetOptions;

    const [data, isLoading] = useApplicationFormGenProc(applicationNumber, procId, parameterNamesList, parameterValuesList);

    const options = useMemo(
        () => ({
            ...(props.options || {}),
            enumOptions: (data ?? []).map(({ itemDisplay, itemValue }: { itemDisplay: string; itemValue: string | number }) => ({
                label: itemDisplay,
                value: itemValue,
            })),
        }),
        [data, props.options]
    );

    const value = isLoading ? undefined : props.value;
    const placeholder = isLoading ? "Loading..." : props.placeholder || (props?.options?.placeholder as string);

    let widget = null;

    switch (procDisplayType) {
        case "checkboxes":
            widget = (
                <CheckboxesWidget
                    {...props}
                    placeholder={placeholder}
                    value={isNil(value) ? [] : (value || "").split(",").map((i: string) => i.trim())}
                    onChange={(val) => {
                        const valueStr = (val ?? []).join(",");
                        // Return undefined if no value selected. Otherwise form change detection will not work as expected.
                        props.onChange(valueStr === "" ? undefined : valueStr);
                    }}
                    options={options}
                />
            );
            break;
        case "radio":
            widget = <RadioWidget {...props} placeholder={placeholder} value={value} options={options} />;
            break;
        default:
            widget = <SelectWidget {...props} placeholder={placeholder} value={value} options={options} />;
            break;
    }

    return widget;
};

const useApplicationFormGenProc = (
    applicationNumber: string,
    procId: string,
    parameterNamesList: string[],
    parameterValuesList: string[]
) => {
    const url = getUrl(process.env.REACT_APP_APPLICATION_GENPROCS_ENDPOINT, { applicationNumber, procId });
    const namesList = parameterNamesList ?? [""];
    const valuesList = namesList.map((_, index) => parameterValuesList[index] ?? "");

    const body = JSON.stringify({
        parameterNamesList: namesList,
        parameterValuesList: valuesList,
    });

    const { data, error } = useSWR(procId ? [url, body] : null, httpPostAuthorized);
    const isLoading = !error && !data;

    return [data, isLoading, error];
};

type GeneralProcedureWidgetOptions = {
    procDisplayType: string;
    procId: string;
    parameterNamesList: string[];
    parameterValuesList: string[];
};
