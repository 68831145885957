import classNames from "classnames";
import { Button } from "components/Button";
import { DataGridColumnConfig, DataGridConfig } from "components/DataGrid/types";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Badge, CloseButton, Form, Offcanvas, Stack } from "react-bootstrap";
import { DataGridFilterInput } from "./DataGridFilterInput";
import { useFilter } from "./useFilter";
import { formatFilterChipValue, getFilterableColumns } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DataGridFilterChips = (props: DataGridFilterChipsProps) => {
    const { className, gridConfig, filter, formTitle, buttonTitle, onFilterChange, children } = props;

    const [showForm, setShowForm] = useState(false);

    const { isFilterEmpty, getFilterValue, onFilterValueChange, onFilterValueKeyDown, onFilterSubmit, onFilterClear, onFilterCancel } =
        useFilter(filter ?? gridConfig?.filter, onFilterChange, gridConfig?.notClearableFilterKeys);

    const columns = getFilterableColumns(gridConfig);
    const filteredColumns = columns
        .map((c) => ({
            ...c,
            value: getFilterValue(c.key),
        }))
        .filter((f) => !isEmpty(f.value));

    const onShowForm = () => setShowForm(true);
    const onHideForm = () => {
        onFilterCancel();
        setShowForm(false);
    };

    const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onFilterSubmit();
        setShowForm(false);
    };

    const onRemoveFilter = (key: string) => {
        onFilterValueChange(key, "", true);
    };

    return (
        <div className={classNames(className, "data-grid-filter-chips")}>
            <div className="data-grid-filter-chips-container">
                <Stack className="flex-wrap-reverse" direction="horizontal">
                    <FilteredColumns filteredColumns={filteredColumns} onRemove={onRemoveFilter} />
                    <div className="d-flex w-100 justify-content-between mb-2">
                        <Button variant="outline-secondary flex-shrink-0" onClick={onShowForm}>
                            <FontAwesomeIcon icon={"filter-list"} className="me-1" /> {buttonTitle ?? formTitle}
                        </Button>
                        {children}
                    </div>
                </Stack>
            </div>
            <Offcanvas show={showForm} placement="end" onHide={onHideForm}>
                <Offcanvas.Header closeButton={true}>
                    <Offcanvas.Title>{formTitle}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="d-flex flex-column h-100" onSubmit={onSubmitForm}>
                        {columns.map((column, index) => (
                            <Form.Group key={index} className="mb-3">
                                <Form.Label className="text-capitalize">{column.name.split("_").join(" ")}</Form.Label>
                                <DataGridFilterInput
                                    column={column}
                                    value={getFilterValue(column.key)}
                                    onChange={(event) => onFilterValueChange(column.key, event.target.value)}
                                    onKeyDown={(event) => onFilterValueKeyDown(column.key, event)}
                                />
                            </Form.Group>
                        ))}
                        <Stack className="mt-auto pb-2" direction="horizontal" gap={3}>
                            {!isFilterEmpty && (
                                <Button variant="link" className="text-nowrap" onClick={onFilterClear}>
                                    Clear Filter
                                </Button>
                            )}
                            <Button variant="primary" type="submit" className="ms-auto">
                                Apply Filter
                            </Button>
                        </Stack>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

interface DataGridFilterChipsProps {
    className?: string;
    gridConfig?: DataGridConfig;
    filter?: string;
    formTitle: string;
    buttonTitle?: string;
    onFilterChange: (filter: string) => void;
    children?: JSX.Element | boolean;
}

const FilteredColumns = ({ filteredColumns, onRemove }: FilteredColumnsProps) => {
    return (
        <Stack className="data-grid-active-filter-chips flex-wrap" direction="horizontal" gap={2}>
            {filteredColumns.map((item, index) => (
                <Badge key={index} className="border d-flex align-items-center" pill bg="light" text="dark">
                    <span className="text-capitalize me-1">
                        {item?.name?.split("_").join(" ")}:{" "}
                        <span className="fw-normal">{formatFilterChipValue(item.datatype, item.value)}</span>
                    </span>
                    <CloseButton className="ms-1 align-self-end" title="Remove filter" onClick={() => onRemove(item.key)} />
                </Badge>
            ))}
        </Stack>
    );
};

interface FilteredColumnsProps {
    filteredColumns: FilteredColumn[];
    onRemove: (key: string) => void;
}

interface FilteredColumn extends DataGridColumnConfig {
    value: string;
}
