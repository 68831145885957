import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";
import { SubmittedAppData } from "types/SubmittedAppData";
import { isInIframe } from "components/utils/dom";
import submittedAppMock from "./submittedAppMock";

const isIframe = isInIframe();

export const useApplicationDetails = (
    applicationNumber: string
): [
    applicationDetails: SubmittedAppData | undefined,
    isLoading: boolean,
    error: any,
    mutate: () => Promise<SubmittedAppDataResponse | undefined>,
    isValidating: boolean
] => {
    const url = !isIframe && applicationNumber ? getUrl(process.env.REACT_APP_APPLICATION_DETAILS_ENDPOINT, { applicationNumber }) : null;

    const { data, error, mutate, isValidating } = useSWR<SubmittedAppDataResponse>(url, httpGetAuthorized);
    const isLoading = !error && !data;

    if (isIframe) {
        return [submittedAppMock, false, undefined, () => Promise.resolve(undefined), false];
    }

    const applicationDetails = data?.applicationRecord?.applicationDetail;

    return [applicationDetails, isLoading, error, mutate, isValidating];
};

export interface SubmittedAppDataResponse {
    applicationRecord: {
        applicationDetail: SubmittedAppData;
    };
}
