import { toArray } from "components/utils/array";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import { useMemo } from "react";
import useSWR from "swr";
import { AdditionalContactRequirements } from "./types";

export const useAdditionalContacts = (
    applicationNumber: string
): [contacts: AdditionalContactRequirements[], isLoading: boolean, error: any] => {
    const url = applicationNumber ? getUrl(process.env.REACT_APP_APPLICATION_ADDITIONAL_CONTACTS_ENDPOINT, { applicationNumber }) : null;
    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    const contacts = useMemo(() => toArray(data?.AdditionalContactList?.contact), [data?.AdditionalContactList?.contact]);

    return [contacts, isLoading, error];
};
