import { Button } from "./Button";
import { CookiesBanner } from "./CookiesBanner";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Heading } from "./Heading";
import { JsonForm } from "./JsonForm";
import { Col } from "./Layout/Column";
import { Container } from "./Layout/Container";
import { Row } from "./Layout/Row";
import { Page } from "./Page";
import { Notification } from "./Notification";
import { AccountDetailsPage } from "./Page/AccountDetailsPage";
import { ContactsPage } from "./Page/ContactsPage";
import { ApplicationPage } from "./Page/ApplicationPage";
import { PrescreenPage } from "./Page/PrescreenPage";
import { SubmittedApplicationPage as SubmittedApplicationPageV2 } from "./Page/SubmittedApplicationV2";
import { ChangeEmailPage } from "./Page/ChangeEmailPage";
import { ChangePasswordPage } from "./Page/ChangePasswordPage";
import { ContactUsPage } from "./Page/ContactUsPage";
import { ContractorMapPage } from "./Page/ContractorMapPage/ContractorMapPage";
import { CreateAccountPage } from "./Page/CreateAccountPage";
import { ForgotPasswordPage } from "./Page/ForgotPasswordPage";
import { PageNotFoundPage } from "./Page/PageNotFoundPage";
import { ResetPasswordPage } from "./Page/ResetPasswordPage";
import { SignInPage } from "./Page/SignInPage";
import { SignOutPage } from "./Page/SignOutPage";
import { SuccessPage } from "./Page/SuccessPage";
import { ThankYouPage } from "./Page/ThankYouPage";
import { AnonymousThankYouPage } from "./Page/AnonymousThankYouPage";
import { Paragraph } from "./Paragraph";
import { HeaderNavigationWidget } from "./Widgets/HeaderNavigationWidget";
import { Html } from "./Widgets/Html";
import { Image } from "./Widgets/Image";
import { NavigationWidget } from "./Widgets/NavigationWidget";
import { SocialLinks } from "./Widgets/SocialLinks";
import { TextWidget } from "./Widgets/TextWidget";
import { LinkWidget } from "./Widgets/LinkWidget";
import { List } from "./Widgets/List";
import { TextWithButton } from "./Widgets/TextWithButton";
import { CardContent } from "./Widgets/CardContent";
import { AccordionWidget } from "./Widgets/Accordion/Accordion";
import { AnonymousApplicationPage } from "./Page/AnonymousApplicationPage";
import { DashboardPageV2 } from "./Page/DashboardPageV2";
import { PrivacyPolicyCCPAPage } from "./Page/PrivacyPolicyCCPAPage";
import { PrivacyPolicyPage } from "./Page/PrivacyPolicyPage";
import { PowerBiPublicReport } from "./PowerBiPublicReport";
import { CarouselWidget } from "./Widgets/Carousel/Carousel";

export const Components: ComponentDictionary = {
    Page,
    Header,
    Footer,
    Form: JsonForm,
    ContactUsPage,
    AccountDetailsPage,
    ContactsPage,
    SocialMedia: SocialLinks,
    Heading,
    Paragraph,
    Button,
    CustomerDashboardPage: DashboardPageV2,
    SignInPage,
    SignOutPage,
    CreateAccountPage,
    ApplicationPage,
    PrescreenPage,
    ContractorMapPage,
    SuccessPage,
    PageNotFoundPage,
    NavigationWidget,
    TextWidget,
    LinkWidget,
    List,
    TextWithButton,
    CardContent,
    AccordionWidget,
    CarouselWidget,
    ChangePasswordPage,
    ChangeEmailPage,
    ForgotPasswordPage,
    ResetPasswordPage,
    CookiesBanner,
    Notification,
    Container,
    Row,
    Col,
    HeaderNavigationWidget,
    Html,
    Image,
    ThankYouPage,
    AnonymousThankYouPage,
    SubmittedApplicationPage: SubmittedApplicationPageV2,
    AnonymousApplicationPage,
    PrivacyPolicyCCPAPage,
    PrivacyPolicyPage,
    PowerBiPublicReport,
};

interface ComponentDictionary {
    [index: string]: any;
}
