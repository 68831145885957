import cn from "classnames";
import { SubmittedAppData } from "types/SubmittedAppData";
import { useLocation } from "react-router";
import { FormsHeader } from "./FormsHeader";
import { AppForm } from "./AppForm";
import { Icon } from "components/Icon";
import { ScrollTopButton } from "./ScrollTopButton";
import { ReactComponent as FormsPlaceholder } from "../assets/forms-placeholder.svg";
import { EmptyStatePlaceholder } from "../EmptyStatePlaceholder";

import "./style.scss";

export function ApplicationForms({
    applicationNumber,
    appData,
    onFormChanged,
}: {
    applicationNumber: string;
    appData: SubmittedAppData;
    onFormChanged?: () => void;
}) {
    const forms = appData.applicationForms ?? [];
    const showSideNav = forms.length > 1;
    const showEmptyState = forms.length === 0;

    const location = useLocation();
    const currentUrl = location.pathname + location.search;

    return (
        <div className="row align-items-start">
            <div
                className={cn("d-flex flex-column", {
                    "col-12 col-md-8": showSideNav,
                    "col-12": !showSideNav,
                })}
            >
                {showEmptyState ? (
                    <>
                        <h2 className="m-0">Forms</h2>
                        <EmptyStatePlaceholder
                            className="py-5"
                            placeholderImage={<FormsPlaceholder />}
                            title="No forms found"
                            description="There are no viewable forms for this application"
                        />
                    </>
                ) : (
                    <FormsHeader appData={appData} />
                )}
                <div className="d-flex flex-column gap-4p75">
                    {forms.map((form) => (
                        <AppForm
                            key={form.pageNumber}
                            applicationNumber={applicationNumber}
                            appFormData={form}
                            onFormChanged={onFormChanged}
                        />
                    ))}
                </div>
            </div>
            {showSideNav && (
                <aside className="offset-1 col-3 z-0 flex-shrink-0 sticky-top p-4 d-none d-md-flex flex-column gap-3 border-bottom d-sm-none">
                    <h3 id="form-quick-links" className="m-0">
                        Contents
                    </h3>
                    <nav role="navigation" aria-labelledby="form-quick-links">
                        <ul className="d-flex flex-column gap-3 list-unstyled m-0">
                            {forms.map((form) => (
                                <li key={form.pageNumber}>
                                    <a
                                        aria-label={
                                            form.allowFormEdit ? `View or edit form ${form.pageName}` : `View form ${form.pageName}`
                                        }
                                        href={`${currentUrl}#${form.pageNumber}`}
                                    >
                                        {form.pageName}
                                        {form.allowFormEdit && (
                                            <span className="ms-2">
                                                <Icon icon={["fal", "pen"]} />
                                            </span>
                                        )}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside>
            )}
            <ScrollTopButton />
        </div>
    );
}
