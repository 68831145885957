import Form from "@rjsf/core";
import { Button } from "components/Button";
import { SubmitButton } from "components/Button/SubmitButton";
import { ErrorSummary } from "components/ErrorSummary";
import { ApplicationContactsWidgetRef } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { getFormNameFromConfig, submitByRefPromise } from "components/JsonForm/utils";
import { ApplicationForm } from "components/Page/ApplicationPage/ApplicationForm";
import { getApplicationFormData, validateContactsWidget } from "components/Page/ApplicationPage/utils";
import { useApplicationFormPage } from "components/utils/useApplicationFormPage";
import { useEffect, useMemo, useRef, useState } from "react";
import { Offcanvas, Spinner, Stack } from "react-bootstrap";
import { TaskProps } from "./types";
import { completeWorkflowTask, submitApplicationItems } from "./utils";

export const ApplicationFormEntryTask = ({ applicationNumber, task, onComplete, onClose }: TaskProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<any>();

    const applicationFormRef = useRef<Form<any>>();
    const contactsWidgetRef = useRef<ApplicationContactsWidgetRef>();
    const additionalContactsWidgetRef = useRef<ApplicationContactsWidgetRef>();

    const [applicationForm, isLoadingApplicationForm, applicationFormError] = useApplicationFormPage(
        applicationNumber,
        task.formPageNumber
    );
    const formConfig = applicationForm?.formConfiguration;
    const visibleFieldIds = useMemo(() => (task.fieldNumber ? [task.fieldNumber] : undefined), [task.fieldNumber]);
    const formData = useMemo(() => {
        let fields = applicationForm?.formDetails?.fields;

        if (visibleFieldIds && fields?.length > 0) {
            return fields.filter((f: any) => visibleFieldIds.includes(f.number));
        }

        return fields;
    }, [applicationForm?.formDetails?.fields, visibleFieldIds]);

    const formName = getFormNameFromConfig(formConfig);

    useEffect(() => {
        setErrorSummary(applicationFormError);
    }, [applicationFormError]);

    const onSubmit = async () => {
        try {
            if (!applicationFormRef.current || !task.formPageNumber || !formConfig) {
                return;
            }

            setIsSubmitting(true);

            // validate contacts widget
            await validateContactsWidget(contactsWidgetRef, formName);

            // validate additional contacts widget
            await validateContactsWidget(additionalContactsWidgetRef, formName);

            // Validate application form
            await submitByRefPromise(applicationFormRef);

            const { applicationItems } = getApplicationFormData(
                applicationFormRef,
                contactsWidgetRef,
                additionalContactsWidgetRef,
                formConfig,
                formData
            );

            await submitApplicationItems(applicationNumber, task.wfTaskNumber, task.formPageNumber, applicationItems);
            await completeWorkflowTask(applicationNumber, task);

            onComplete(task);
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas className="offcanvas-75" show placement="end" backdrop="false" onHide={onClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{task.stepType}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                {isLoadingApplicationForm ? (
                    <Spinner className="align-self-center flex-shrink-0" animation="border" role="status">
                        <span className="visually-hidden">Loading application form...</span>
                    </Spinner>
                ) : (
                    <>
                        <ErrorSummary errorSummary={errorSummary} />
                        <ApplicationForm
                            formRef={applicationFormRef}
                            contactsWidgetRef={contactsWidgetRef}
                            additionalContactsWidgetRef={additionalContactsWidgetRef}
                            applicationNumber={applicationNumber}
                            configuration={formConfig}
                            formData={formData}
                            visibleFieldIds={visibleFieldIds}
                        />
                        <Stack className="mt-auto justify-content-end" direction="horizontal">
                            <SubmitButton onClick={onSubmit} isSubmitting={isSubmitting} spinnerText="Saving form..." className="me-3">
                                Save
                            </SubmitButton>
                            <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
                                Cancel
                            </Button>
                        </Stack>
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};
