import { useEffect, useState, useMemo } from "react";
import { JsonForm } from "components/JsonForm";
import { Button } from "components/Button";
import { AdditionalContactType, ContactFormProps } from "./types";
import { RequireContact } from "../../../../../types/RequireContact";
import { getContactSchema, refreshRequiredFields } from "./utils";
import { isFunction, isObject } from "lodash";
import { useCustomerContacts } from "components/utils/useCustomerContacts";
import SelectWidget from "../SelectWidget";
import { WidgetProps } from "@rjsf/core";
import { SubmitButton } from "components/Button/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REQUIRED_LAST_AND_FIRST_OR_CONTACT } from "components/utils/validation";
import { ContractorSearch } from "./ContractorSearch";
import RadioWidget from "../RadioWidget";
import { useContractorLabel } from "./useContractorLabel";

export function ContactForm({
    formRef,
    // the actual formRef object in case formRef prop is a function
    formRefObject,
    formData,
    idPrefix,
    title = "",
    description,
    extraErrors,
    requiredFields,
    applicationNumber,
    hiddenFields,
    hide = false,
    readonly = false,
    requirements,
    copyContactUiSchema,
    isAdditionalContact,
    onChange,
    onUseCustomerContact,
    isContactsView,
    isEditMode,
    onSaveClick,
    onClearForm,
    setContactType,
}: ContactFormProps) {
    const [state, setState] = useState(formData);
    // first name, last name, company
    const [requiredFLC, setRequiredFLC] = useState<Array<string>>([]);

    const contactFormRef = isFunction(formRef) ? formRefObject : formRef.current;

    const [customerContacts = []] = useCustomerContacts(1, 100);

    const contractorLabel = useContractorLabel();

    useEffect(() => {
        setState(formData);
    }, [formData]);

    const showCopyContact = isObject(copyContactUiSchema);

    const showContractorSearch = [RequireContact.RequiredFromList, RequireContact.NotRequiredFromList].includes(
        requirements?.requireContractor
    );
    const schema = getContactSchema(
        title,
        description,
        [...requiredFields.filter((s) => !["firstname", "lastname", "company"].includes(s)), ...requiredFLC],
        hiddenFields,
        showCopyContact,
        customerContacts,
        requirements,
        isContactsView,
        isEditMode,
        showContractorSearch,
        isAdditionalContact,
        contractorLabel
    );

    const onContractorSelect = () => {};
    const isContractorRequiredFromList = true;

    const uiSchema = {
        _contactType: {
            "ui:widget": (props: WidgetProps) => <RadioWidget {...props} onChange={(e) => setContactType && setContactType(e)} />,
        },
        ...(copyContactUiSchema ?? {}),
        storedContactNumber: {
            "ui:widget": (props: WidgetProps) => (
                <SelectWidget
                    {...props}
                    onChange={(contactNumber) => onUseCustomerContact?.(customerContacts?.find((c) => c.contactNumber === contactNumber))}
                />
            ),
        },
        ...(applicationNumber
            ? {
                  contractorSearch: {
                      "ui:widget": () => (
                          <ContractorSearch
                              applicationNumber={applicationNumber}
                              hide={!showContractorSearch}
                              onSelect={onContractorSelect}
                              isContractorRequired={isContractorRequiredFromList}
                          />
                      ),
                  },
              }
            : {}),
        _requirementsInfo: {
            "ui:widget": (props: WidgetProps) => (
                <div id={`${idPrefix}-description-first-last-company-name`} className="mt-3 mb-1">
                    <FontAwesomeIcon className="me-2" icon={"circle-info"} />
                    {REQUIRED_LAST_AND_FIRST_OR_CONTACT}
                </div>
            ),
        },
        section: {
            "ui:elementType": "section",
            column1: {
                firstName: {
                    "ui:options": {
                        defaultAriaDescribedBy: `${idPrefix}-description-first-last-company-name`,
                    },
                },
                lastName: {
                    "ui:options": {
                        defaultAriaDescribedBy: `${idPrefix}-description-first-last-company-name`,
                    },
                },
                company: {
                    "ui:options": {
                        defaultAriaDescribedBy: `${idPrefix}-description-first-last-company-name`,
                    },
                },
            },
            column2: {
                state: {
                    "ui:widget": "state",
                    "ui:emptyItem": true,
                },
            },
        },
    };

    const formState = useMemo(() => {
        return { ...state, _contactType: AdditionalContactType.Stored };
    }, [state]);

    if (hide) {
        return null;
    }

    return (
        <JsonForm
            formRef={formRef}
            className="p-4"
            tagName={"div"}
            idPrefix={idPrefix}
            schema={schema}
            uiSchema={uiSchema}
            formData={formState}
            readonly={readonly}
            onChange={(e: any) => {
                refreshRequiredFields(contactFormRef, setRequiredFLC);
                onChange && onChange(e);
            }}
            noValidate
            noAnalytics
            extraErrors={extraErrors}
        >
            {onSaveClick && (
                <SubmitButton onClick={onSaveClick} isSubmitting={readonly} spinnerText="Submitting...">
                    Save
                </SubmitButton>
            )}
            <Button
                className="ms-4 mt-auto"
                variant="secondary"
                disabled={readonly}
                onClick={() => {
                    onClearForm ? onClearForm() : setState({});
                }}
            >
                Clear Contact
            </Button>
        </JsonForm>
    );
}
