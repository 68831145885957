import { Form } from "react-bootstrap";
import type { JSONSchema7 } from "json-schema";
import cn from "classnames";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { UiSchema } from "@rjsf/core";
import HelpModalToggleWidget from "./HelpModalToggleWidget";

const WidgetLabel = ({ id, isFieldset, label, schema, uiSchema = {}, required, rawErrors = [] }: WidgetLabelProps) => {
    const buildLabel = () => {
        return (
            <>
                {label || schema.title}
                {(label || schema.title) && required ? <>&thinsp;{"*"}</> : null}
                <HelpModalToggleWidget uiSchema={uiSchema} />
            </>
        );
    };

    if (isFieldset) {
        return (
            <>
                <legend
                    className={cn("form-label fs-6", {
                        "text-danger": rawErrors.length > 0,
                    })}
                >
                    {buildLabel()}
                </legend>
            </>
        );
    }

    return (
        <Form.Label id={`label-${id}`} className={rawErrors.length > 0 ? "text-danger" : ""} htmlFor={id}>
            {buildLabel()}
        </Form.Label>
    );
};

type WidgetLabelProps = {
    id: string;
    isFieldset?: boolean;
    label: string;
    schema: JSONSchema7;
    uiSchema: UiSchema;
    required: boolean;
    rawErrors?: string[];
    children?: React.ReactNode;
    helpModalTitle?: string;
    helpModalContent?: string;
    helpModalIcon?: IconName;
    show?: boolean;
};

export default WidgetLabel;
