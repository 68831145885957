import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useWindowDimensions from "./hooks/useWindowDimensions";

export function ApplicationStepper(props: ApplicationStepperProps) {
    const {
        activeStep,
        steps,
        labelTextColor,
        stepTextColor,
        activeStepBackground,
        completedStepBackground,
        disabledStepBackground,
        textSize,
    } = props;
    const { width } = useWindowDimensions();

    // Assign 200 pixels of width to each step. If there is not enough space for
    // a horizontal stepper, set the orientation to vertical
    const orientation = width / 200 > steps.length ? "horizontal" : "vertical";

    return (
        <div className="application-stepper d-flex flex-row align-items-center justify-content-center mb-5">
            <Stepper activeStep={activeStep} alternativeLabel={orientation === "horizontal"} orientation={orientation}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step
                            key={label}
                            {...stepProps}
                            sx={{
                                ".MuiSvgIcon-root.Mui-completed": {
                                    color: completedStepBackground,
                                    background: `radial-gradient(26px circle at ${textSize} ${textSize},${stepTextColor} 50%,transparent 50%)`,
                                },
                                ".MuiStepIcon-root.Mui-active": { color: activeStepBackground },
                                ".MuiStepIcon-root": { color: disabledStepBackground },
                                ".MuiStepIcon-text": { fill: stepTextColor, fontSize: textSize },
                            }}
                        >
                            <StepLabel
                                sx={{
                                    ".MuiStepLabel-labelContainer > .MuiStepLabel-label": { color: labelTextColor, fontSize: textSize },
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
}

interface ApplicationStepperProps {
    activeStep: number;
    steps: Array<string>;
    labelTextColor?: string;
    activeStepBackground?: string;
    completedStepBackground?: string;
    disabledStepBackground?: string;
    stepTextColor?: string;
    textSize?: string;
}
