import { useContext, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { getColor, useColor } from "components/utils/color";
import { isNil } from "lodash";
import { TextWidget } from "../TextWidget";
import "./styles.scss";
import { ButtonProps } from "types/ButtonProps";
import { ImageProps } from "../Image";
import { Button } from "components/Button";
import { AppContext } from "components/App/AppContext";
import classNames from "classnames";
import useMediaQuery from "components/utils/useMediaQuery";
import { ImagePlaceholder } from "components/ImagePlaceholder";
import { Icon } from "components/Icon";
import { TextColor } from "types/TextColor";

const getCarouselHeight = (height: number) => {
    let displayedHeight = 360;
    if (!isNil(height) && height >= 360 && height <= 1000) {
        displayedHeight = height;
    }
    return `${displayedHeight}px`;
};

export function CarouselWidget({ carousel, interval, autoplayOn, height }: Readonly<CarouselProps>) {
    const { settings } = useContext(AppContext);
    const { colors = {} } = useContext(AppContext);
    const [activeSlide, setActiveSlide] = useState(0);
    const isMobile = useMediaQuery("(max-width: 768px)");
    const controlColor = carousel?.[activeSlide]?.props.controlColor;

    const bodyBackgroundColor = useColor(settings?.backgroundOff ? "#ffffff" : settings?.backgroundColor, settings?.backgroundColorOpacity);
    const controlClasses = classNames("fs-2", {
        "text-light": !controlColor || controlColor === TextColor.Light,
        "text-dark": controlColor === TextColor.Dark,
    });
    return (
        <div
            className={classNames("carousel-widget w-100", {
                "indicators-dark": controlColor === TextColor.Dark,
            })}
        >
            <Carousel
                nextLabel={"Next Slide"}
                prevLabel={"Previous Slide"}
                nextIcon={
                    <div aria-hidden="true" title="Next Slide">
                        <Icon className={controlClasses} icon={["fas", "circle-chevron-right"]} />
                    </div>
                }
                prevIcon={
                    <div aria-hidden="true" title="Previous Slide">
                        <Icon className={controlClasses} icon={["fas", "circle-chevron-left"]} />
                    </div>
                }
                interval={!autoplayOn || isNil(interval) || interval < 1 || interval > 1000 ? null : interval * 1000}
                controls={!isMobile}
                className="w-100"
                onSelect={(index: number) => setActiveSlide(index)}
            >
                {carousel?.map((item: CarouselItemProps, index) => {
                    const overlayColor = getColor(item?.props.backgroundColor, item?.props.backgroundColorOpacity, colors);
                    const buttonProps = {
                        text: item.props.text,
                        variant: item.props.variant,
                        buttonWidth: item.props.buttonWidth,
                        secondaryButtonType: item.props.secondaryButtonType,
                        href: item.props.href,
                        target: item.props.target,
                    };
                    const backgroundOff = item?.props?.backgroundOff || isNil(item?.props?.backgroundOff);

                    return (
                        <Carousel.Item
                            style={item?.props.imageSize === "fit" && item?.props?.src ? {} : { height: getCarouselHeight(height) }}
                            key={index}
                        >
                            <div
                                className="carousel-image-overlay d-flex"
                                style={{
                                    backgroundColor: item?.props?.backgroundOff ? undefined : overlayColor,
                                }}
                            ></div>
                            {item.props.src && (
                                <img
                                    style={{
                                        objectPosition: `${item?.props.alignBackgroundX} ${item?.props.alignBackgroundY}`,
                                    }}
                                    className={classNames("w-100", {
                                        "image-cover": item?.props.imageSize === "cover",
                                        "image-auto": item?.props.imageSize === "auto",
                                        "image-fit": item?.props.imageSize === "fit",
                                        "h-100": item?.props.imageSize !== "fit",
                                    })}
                                    src={item.props.src}
                                    alt={item.props.altText}
                                />
                            )}
                            {!item.props.src && backgroundOff && (
                                <ImagePlaceholder
                                    text="Please Set Image or Background"
                                    textStyle={{ backgroundColor: bodyBackgroundColor }}
                                />
                            )}

                            <Carousel.Caption>
                                <TextWidget text={item.props?.content} />
                                <div className="pt-3 mx-3">{item.props.text && <Button {...buttonProps} />}</div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </div>
    );
}

type CarouselProps = {
    carousel: CarouselItemProps[];
    interval: number;
    autoplayOn: boolean;
    height: number;
};

interface CardContentProps extends ButtonProps, ImageProps {
    src?: string;
    content?: string;
    headerBackgroundColor?: string;
    headerText?: string;
    state?: string;
    alignBackgroundX: string;
    alignBackgroundY: string;
    imageSize: string;
    controlColor: TextColor;
}

type CarouselItemProps = {
    props: CardContentProps;
    index: string;
    type?: string;
};
