import { useContext, useState } from "react";
import { ApplicationListColumnKeys, CustomerDashboardModel } from "./types";
import { breakTextIntoLines, getVisibleColumns, mapGridRowToObject } from "components/DataGrid/utils";
import { DataGridRowConfig } from "components/DataGrid/types";
import { PageLink } from "components/App/utils";
import { Link } from "react-router-dom";
import { DataGrid } from "components/DataGrid";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { DataGridHeader } from "components/DataGrid/DataGridHeader";
import { DataGridPaging } from "components/DataGrid/DataGridPaging";
import { DataGridBody } from "components/DataGrid/DataGridBody";
import { SubmitButton } from "components/Button/SubmitButton";
import { Button } from "../../Button";
import { Icon } from "components/Icon";
import { Badge, Collapse, Modal } from "react-bootstrap";
import { REMOVE_APP_KEY } from "../../../components/utils";
import { isNil } from "lodash";
import useMediaQuery from "components/utils/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateAndTime } from "components/utils/date";
import { getMessagesAndTasksCount } from "./useCustomerDashboardModel";
import { AppContext } from "components/App/AppContext";
import { TypographyFontSize } from "types/TypographyFontSize";
import classNames from "classnames";

export const ApplicationsList = ({ model }: { model: CustomerDashboardModel }) => {
    const {
        isLoadingApplications,
        isDeletingApplication,
        applicationsGridConfig,
        onSortChange,
        onPageChange,
        onPageSizeChange,
        onDeleteApplication,
    } = model;
    const {
        settings: { fontSize },
    } = useContext(AppContext);
    const [appIdToRemove, setAppIdToRemove] = useState<string | null>();
    const showMessageModal = !isNil(appIdToRemove);
    const isMobile = useMediaQuery("(max-width: 992px)");
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
    const APP_DETAILS_ID = "app-details-id";

    const columns = getVisibleColumns(applicationsGridConfig);

    const rows: DataGridRowConfig[] = (applicationsGridConfig?.rows ?? []).map((item) => ({
        data: item.data,
        actions: [
            {
                content: (row: DataGridRowConfig, actionIndex: number) => (
                    <div key={actionIndex} className="text-end d-flex align-items-center justify-content-start gap-2">
                        {row.data[ApplicationListColumnKeys.Status] === "submitted" ? (
                            <Link
                                key={actionIndex}
                                className="text-nowrap p-2 ps-0"
                                to={`${PageLink.ApplicationView}?applicationNumber=${row.data[ApplicationListColumnKeys.AppId]}`}
                                title="View Application"
                            >
                                <Icon icon={["fal", "circle-arrow-right"]} style={{ fontSize: "1.5rem" }} />
                            </Link>
                        ) : (
                            <>
                                <Link
                                    key={actionIndex}
                                    className="text-nowrap p-2 ps-0"
                                    to={`${PageLink.Application}?applicationNumber=${
                                        row.data[ApplicationListColumnKeys.AppId]
                                    }&pageNumber=${row.data[ApplicationListColumnKeys.PageNumber]}`}
                                    title="Continue Saved Application"
                                >
                                    <Icon icon={["fal", "circle-arrow-right"]} style={{ fontSize: "1.5rem" }} />
                                </Link>
                                <div className="vr button-divider h-50 align-self-center" />
                                <Button
                                    variant="link"
                                    className="text-danger p-2"
                                    onClick={() => setAppIdToRemove(item.data[REMOVE_APP_KEY.appIdKey])}
                                    title="Delete Application"
                                >
                                    <Icon icon={["fal", "trash-can"]} style={{ fontSize: "1.5rem" }} />
                                </Button>
                            </>
                        )}
                    </div>
                ),
            },
        ],
    }));

    const onDelete = async () => {
        if (appIdToRemove) {
            await onDeleteApplication(appIdToRemove);
            setAppIdToRemove(undefined);
        }
    };

    const dataItems = rows.map((r) => mapGridRowToObject(ApplicationListColumnKeys, r.data)) as ApplicationListObject[];

    if (!applicationsGridConfig) {
        return null;
    }

    return (
        <>
            <Modal show={showMessageModal} onHide={() => setAppIdToRemove(undefined)} aria-labelledby="remove-application-title">
                <Modal.Header closeButton>
                    <Modal.Title id="remove-application-title">Remove Application.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this application?</p>
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButton onClick={onDelete} isSubmitting={isDeletingApplication} variant="primary" spinnerText="Is Deleting...">
                        Yes
                    </SubmitButton>
                    <Button onClick={() => setAppIdToRemove(undefined)} variant="secondary">
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            {isMobile ? (
                <div className="d-flex flex-column gap-2">
                    {dataItems.map((di, index) => (
                        <div key={index} className="bg-white d-flex flex-column">
                            <div
                                role="button"
                                aria-controls={APP_DETAILS_ID + `-${index}`}
                                aria-expanded={expanded[di.ProjectNumber]}
                                tabIndex={0}
                                className="d-flex justify-content-between border-bottom px-1 py-2"
                                onClick={() => setExpanded({ ...expanded, [di.ProjectNumber]: !expanded[di.ProjectNumber] })}
                                onKeyPress={(e) =>
                                    e.key === "Enter" && setExpanded({ ...expanded, [di.ProjectNumber]: !expanded[di.ProjectNumber] })
                                }
                                data-metadata={JSON.stringify({
                                    name: expanded[di.ProjectNumber] ? "Collapse project details" : "Expand project details",
                                })}
                            >
                                <div className="d-flex  align-items-center">
                                    {di.Favorite === "1" ? (
                                        <Icon className="p-2 ps-1 text-body" icon="bookmark" />
                                    ) : (
                                        <Icon className="p-2 ps-1 text-body" icon={["fal", "bookmark"]} />
                                    )}
                                    <div className="d-flex flex-column ps-1">
                                        <div>{di.ProjectName}</div>
                                        <div>{di.ProjectNumber}</div>
                                    </div>
                                </div>
                                <FontAwesomeIcon
                                    className="my-auto mx-3"
                                    icon={["fal", expanded[di.ProjectNumber] ? "chevron-up" : "chevron-down"]}
                                    size={"lg"}
                                />
                            </div>
                            <Collapse in={expanded[di.ProjectNumber]}>
                                <div id={APP_DETAILS_ID + `-${index}`}>
                                    <div className="d-flex flex-column gap-3 p-3 border-bottom">
                                        <div className="d-flex mt-2 align-items-baseline">
                                            <div
                                                className={classNames("text-body fw-bold", {
                                                    "w-35": fontSize === TypographyFontSize.Large,
                                                    "w-25": fontSize !== TypographyFontSize.Large,
                                                })}
                                            >
                                                Program
                                            </div>
                                            <span
                                                className={classNames("small text-wrap text-break", {
                                                    "w-65": fontSize === TypographyFontSize.Large,
                                                    "w-75": fontSize !== TypographyFontSize.Large,
                                                })}
                                            >
                                                {di.Program}
                                            </span>
                                        </div>
                                        <AppContactDetails contact={di.Contacts} fontSize={fontSize} />
                                    </div>
                                </div>
                            </Collapse>
                            <div className="d-flex align-items-center p-2 border-bottom">
                                <AppDateCreated application={di} fontSize={fontSize} />
                                <div className="w-50 d-flex justify-content-end align-items-center">
                                    <Badge className="text-wrap my-auto"> {di.AppStatus}</Badge>
                                </div>
                            </div>
                            <div className="d-flex border-bottom justify-content-between">
                                <div className="d-flex flex-row align-items-center gap-3 my-2 mx-2">
                                    {di.Status === "submitted" ? (
                                        <Link
                                            className="text-nowrap view-app-link"
                                            to={`${PageLink.ApplicationView}?applicationNumber=${di.AppId}`}
                                            title="View Application"
                                        >
                                            <Icon icon={["fal", "circle-arrow-right"]} size="lg" />
                                        </Link>
                                    ) : (
                                        <>
                                            <Link
                                                className="text-nowrap"
                                                to={`${PageLink.Application}?applicationNumber=${di.AppId}&pageNumber=${di.PageNumber}`}
                                                title="Continue Saved Application"
                                            >
                                                <Icon className="me-2" icon={["fal", "circle-arrow-right"]} size="lg" />
                                            </Link>
                                            <div className="vr button-divider h-50 align-self-center" />

                                            <Button
                                                className="p-0 text-danger"
                                                variant="link"
                                                onClick={() => setAppIdToRemove(di.AppId)}
                                                title="Delete Application"
                                            >
                                                <Icon className="me-2" icon={["fal", "trash-can"]} size="lg" />
                                            </Button>
                                        </>
                                    )}
                                </div>

                                <div className="d-flex gap-2 my-auto me-2">
                                    {!!getMessagesAndTasksCount(di.MessagesAndTasks).tasksCount && (
                                        <div className="d-flex gap-2 align-items-center">
                                            <FontAwesomeIcon icon={["fal", "list-check"]}></FontAwesomeIcon>
                                            <Badge className="rounded-circle fs-8 bg-danger text-white">
                                                {getMessagesAndTasksCount(di.MessagesAndTasks).tasksCount}
                                            </Badge>
                                        </div>
                                    )}
                                    {!!getMessagesAndTasksCount(di.MessagesAndTasks).messagesCount && (
                                        <div className="d-flex gap-2 align-items-center">
                                            <FontAwesomeIcon icon={["fal", "envelope"]}></FontAwesomeIcon>
                                            <Badge className="rounded-circle fs-8 bg-danger text-white">
                                                {getMessagesAndTasksCount(di.MessagesAndTasks).messagesCount}
                                            </Badge>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    {dataItems.length === 0 && <>{applicationsGridConfig.nothingFoundMessage}</>}
                </div>
            ) : (
                <DataGrid className="dashboard-applications-listv2 align-middle">
                    <caption className="visually-hidden">Applications list</caption>
                    <DataGridHead>
                        <DataGridHeader columns={columns} onSortChange={onSortChange} />
                    </DataGridHead>
                    <DataGridBody
                        rows={rows}
                        columns={columns}
                        nothingFoundMessage={applicationsGridConfig.nothingFoundMessage}
                        isLoadingData={isLoadingApplications}
                    />
                </DataGrid>
            )}

            <DataGridPaging
                pageNumber={applicationsGridConfig.pageNumber}
                pagesCount={applicationsGridConfig.pagesCount}
                pageSize={applicationsGridConfig.pageSize}
                totalRecords={applicationsGridConfig.totalRecords}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
            />
        </>
    );
};

const AppContactDetails = (props: { contact: string; fontSize: TypographyFontSize }) => {
    const { contact, fontSize } = props;

    return (
        <div className="d-flex align-items-baseline">
            <div
                className={classNames("text-body fw-bold", {
                    "w-35": fontSize === TypographyFontSize.Large,
                    "w-25": fontSize !== TypographyFontSize.Large,
                })}
            >
                Contact
            </div>
            <div
                className={classNames("d-flex flex-column", {
                    "w-65": fontSize === TypographyFontSize.Large,
                    "w-75": fontSize !== TypographyFontSize.Large,
                })}
            >
                <div className="small text-wrap text-break">{breakTextIntoLines(contact)}</div>
            </div>
        </div>
    );
};

const AppDateCreated = (props: { application: ApplicationListObject; fontSize: TypographyFontSize }) => {
    const { application, fontSize } = props;
    if (fontSize === TypographyFontSize.Large) {
        const date = formatDateAndTime(application.DateCreated, "", true);

        return (
            <div className="d-flex flex-column w-50">
                <span>{date.formattedDate}</span>
                <span>{date.formattedTime}</span>
            </div>
        );
    } else {
        return <div className="w-50">{formatDateAndTime(application.DateCreated)}</div>;
    }
};

interface ApplicationListObject {
    Contacts: string;
    AppId: string;
    Status: string;
    AppStatus: string;
    PageNumber: string;
    ProjectName: string;
    MessagesAndTasks: string;
    Favorite: string;
    ProjectNumber: string;
    DateCreated: string;
    Program: string;
}
