import { JsonForm } from "components/JsonForm";
import { Button } from "components/Button";
import { useCallback, useEffect, useState, useMemo } from "react";
import { ContractorSearch } from "./ContractorSearch";
import { AdditionalContactType, ContractorContactFormProps } from "./types";
import { ContractorData } from "components/utils/contacts";
import { ContactType } from "components/utils/contacts";
import { RequireContact } from "../../../../../types/RequireContact";
import { createContractorFormDataObject, getContractorSchema, refreshRequiredFields } from "./utils";
import { getHiddenFieldsByContactType, getRequiredFieldsByContactType } from "components/utils/contacts";
import { useCustomerContacts } from "components/utils/useCustomerContacts";
import { WidgetProps } from "@rjsf/core";
import SelectWidget from "../SelectWidget";
import { SubmitButton } from "components/Button/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REQUIRED_LAST_AND_FIRST_OR_CONTACT } from "components/utils/validation";
import RadioWidget from "../RadioWidget";
import { isEmpty } from "lodash";
import { useContractorLabel } from "./useContractorLabel";

export const ContractorContactForm = ({
    formRef,
    formData,
    extraErrors,
    requirements,
    applicationNumber,
    noTitle,
    onUseCustomerContact,
    onChange,
    onSaveClick,
    isAppSubmitted,
    isAdditionalContact,
    readonly,
    title = "",
    setContactType,
}: ContractorContactFormProps) => {
    const [state, setState] = useState(formData);
    const [customerContacts = []] = useCustomerContacts(1, 100);

    const contractorLabel = useContractorLabel();

    useEffect(() => {
        setState(formData);
    }, [formData]);

    const onContractorSelect = useCallback((contractor: ContractorData) => {
        const formData = createContractorFormDataObject(contractor);
        setState(formData);
    }, []);

    // first name, last name, company
    const [requiredFLC, setRequiredFLC] = useState<Array<string>>([]);

    const onFormClear = useCallback(() => {
        setState({});
    }, []);

    const hideContractor =
        (!isAppSubmitted && requirements.requireContractor === RequireContact.Hidden) ||
        (requirements.requireContractor === RequireContact.ReadOnly &&
            !(formData?.section.column1.company || formData?.section.column1.firstName || formData?.section.column1.lastName));
    const formState = useMemo(() => {
        return { ...state, _contactType: AdditionalContactType.Contractor };
    }, [state]);
    if (hideContractor) {
        return null;
    }

    const isContractorRequiredFromList =
        (!isAdditionalContact && [RequireContact.RequiredFromList].includes(requirements.requireContractor!)) ||
        (isAdditionalContact && !requirements.disableAddlContactValidation);
    const showContractorSearch = [RequireContact.RequiredFromList, RequireContact.NotRequiredFromList].includes(
        requirements.requireContractor!
    );

    const showForm =
        [RequireContact.Required, RequireContact.NotRequired, RequireContact.ReadOnly].includes(requirements.requireContractor!) ||
        (requirements.requireContractor === RequireContact.Hidden && isAppSubmitted) ||
        (showContractorSearch && !isEmpty(state));

    const requiredFields = getRequiredFieldsByContactType(requirements.requiredFields, ContactType.Contractor);
    const hiddenFields = getHiddenFieldsByContactType(requirements.hiddenFields, ContactType.Contractor);
    const isFormReadOnly = [RequireContact.ReadOnly].includes(requirements.requireContractor!) || showContractorSearch;
    const getTitle = () => {
        if (noTitle) {
            return "";
        }
        if (title) {
            return title;
        }

        return requirements.contractorDescription ?? "";
    };

    const schema = getContractorSchema(
        getTitle(),
        requirements.contractorDescription || isAdditionalContact ? "" : "Who is installing or completing the work/installation?",
        [...requiredFields.filter((s) => !["firstname", "lastname", "company"].includes(s)), ...requiredFLC],
        hiddenFields,
        showContractorSearch,
        showForm,
        requirements,
        customerContacts,
        isAdditionalContact,
        contractorLabel
    );

    const idPrefix = "contractor-contact";

    const uiSchema = {
        _contactType: {
            "ui:widget": (props: WidgetProps) => (
                <RadioWidget {...props} onChange={(e) => setContactType && setContactType(e)} readonly={false} />
            ),
        },
        contractorSearch: {
            "ui:widget": () => (
                <ContractorSearch
                    applicationNumber={applicationNumber}
                    hide={!showContractorSearch}
                    onSelect={onContractorSelect}
                    isContractorRequired={isContractorRequiredFromList}
                />
            ),
        },
        ...(isAdditionalContact
            ? {}
            : {
                  storedContactNumber: {
                      "ui:widget": (props: WidgetProps) => (
                          <SelectWidget
                              {...props}
                              onChange={(contactNumber) =>
                                  onUseCustomerContact?.(customerContacts?.find((c) => c.contactNumber === contactNumber))
                              }
                          />
                      ),
                  },
              }),
        ...(showContractorSearch
            ? {}
            : {
                  _requirementsInfo: {
                      "ui:widget": (props: WidgetProps) => (
                          <div id={`${idPrefix}-description-first-last-company-name`} className="mt-3 mb-1">
                              <FontAwesomeIcon className="me-2" icon={"circle-info"} />
                              {REQUIRED_LAST_AND_FIRST_OR_CONTACT}
                          </div>
                      ),
                  },
              }),
        section: {
            "ui:elementType": "section",
            column1: {
                ...(showContractorSearch
                    ? {
                          firstName: {
                              "ui:options": {
                                  defaultAriaDescribedBy: `${idPrefix}-description-first-last-company-name`,
                              },
                          },
                          lastName: {
                              "ui:options": {
                                  defaultAriaDescribedBy: `${idPrefix}-description-first-last-company-name`,
                              },
                          },
                          company: {
                              "ui:options": {
                                  defaultAriaDescribedBy: `${idPrefix}-description-first-last-company-name`,
                              },
                          },
                      }
                    : {}),

                technologies: {
                    "ui:widget": "textarea",
                },
                services: {
                    "ui:widget": "textarea",
                },
            },
            column2: {
                state: {
                    "ui:widget": "state",
                    "ui:emptyItem": true,
                },
            },
        },
        contactNumber: {
            "ui:widget": "hidden",
        },
        origId: {
            "ui:widget": "hidden",
        },
    };

    return (
        <JsonForm
            formRef={formRef}
            className="p-4"
            tagName={"div"}
            idPrefix={"contractor-contact"}
            schema={schema}
            uiSchema={uiSchema}
            formData={formState}
            readonly={isFormReadOnly || readonly}
            noValidate
            noAnalytics
            extraErrors={extraErrors}
            onChange={(e: any) => {
                if (!showContractorSearch) {
                    refreshRequiredFields(formRef.current, setRequiredFLC);
                }
                onChange && onChange(e);
            }}
        >
            {onSaveClick && (
                <SubmitButton
                    onClick={onSaveClick}
                    disabled={showContractorSearch && !state}
                    isSubmitting={readonly}
                    spinnerText="Submitting..."
                >
                    Save
                </SubmitButton>
            )}
            <Button variant="secondary" className="ms-4" onClick={onFormClear} hidden={showContractorSearch}>
                Clear Contact
            </Button>
        </JsonForm>
    );
};
