import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ContractorsResponseItem } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { ContractorData } from "components/utils/contacts";

export interface MarkerProps {
    lat?: number;
    lng?: number;
    key: string;
    contractor?: ContractorsResponseItem | null;
    marker?: google.maps.Marker | null;
}

export interface MapProps {
    clustererRef: React.MutableRefObject<MarkerClusterer | null>;
    selectedContractor: ContractorsResponseItem | null;
    setSelectedContractor: (c: ContractorsResponseItem | null) => void;
    mapInitialisationResolver: React.MutableRefObject<((value: boolean) => void) | undefined>;
    mapRef: React.MutableRefObject<google.maps.Map>;
    setContractorOnSlideout: (v: ContractorsResponseItem) => void;
    firstMapLoadFinished: React.MutableRefObject<boolean>;
}

export interface UserCoordinates {
    latitude: number;
    longitude: number;
}

export interface Filter {
    key: string;
    value?: string;
}

export enum LoadingTypes {
    Loading = "Loading",
    LazyLoading = "LazyLoading",
    NotLoading = "Not Loading",
}

export interface ContractorSearchParams {
    technologies: SearchParamsItem[];
    services: SearchParamsItem[];
}

export interface ContractorFilter {
    property: keyof ContractorData | "zipradius";
    propertyLabel: string;
    id: string;
    options?: SearchParamsItem[];
    isLoadingOptions?: boolean;
}

type SearchParamsItem = {
    id: number;
    parameterType?: string;
    parameterValue: string | number;
};
