import cn from "classnames";
import { DataGridColumnConfig, DataGridConfig, DataGridRowConfig } from "components/DataGrid/types";
import { isEmpty } from "lodash";
import { formatCellContent, getTableColumnCount } from "./utils";
import useMediaQuery from "components/utils/useMediaQuery";
import { Form } from "react-bootstrap";

export const DataGridBody = (props: DataGridBodyProps) => {
    const {
        rows,
        columns,
        hasActionsColumn = true,
        nothingFoundMessage,
        isLoadingData,
        stickyActionsColumn,
        canSelectRows,
        handleSelectRow,
        isRowSelected,
        isRowSelectable,
    } = props;
    const isMobile = useMediaQuery("(max-width: 991px)");

    if (rows.length === 0 && nothingFoundMessage) {
        return (
            <tbody className="data-grid-body data-grid-nothing-found">
                <tr>
                    <td className="py-3" colSpan={getTableColumnCount(columns, hasActionsColumn)}>
                        {nothingFoundMessage}
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <tbody className="data-grid-body table-group-divider" aria-hidden={isLoadingData}>
            {rows.map((row, index) => (
                <tr
                    key={index}
                    className={cn(
                        {
                            "bg-light": canSelectRows && isMobile && isRowSelected?.(row),
                        },
                        "d-flex flex-column border-0 rounded rounded-lg-0 box-shadow-1 box-shadow-lg-0 my-3 my-lg-0 p-2 p-lg-0 d-lg-table-row",
                        {
                            "border-bottom-lg border-top-lg-0 mt-0": index === 0,
                            "border-bottom-lg border-top-lg": index > 0,
                        }
                    )}
                    onClick={() => canSelectRows && isMobile && isRowSelectable?.(row) && handleSelectRow?.(row)}
                >
                    {!isMobile && canSelectRows && (
                        <td className="col-auto d-flex border-0 d-lg-table-cell">
                            {isRowSelectable?.(row) && (
                                <Form.Check className="pb-1" checked={isRowSelected?.(row)} onChange={() => handleSelectRow?.(row)} />
                            )}
                        </td>
                    )}
                    {columns.map((column, index) => (
                        <td
                            data-label={column.name}
                            key={index}
                            className={cn(
                                column.className,
                                "col-md-2 d-flex flex-column border-0 fs-7 d-lg-table-cell",
                                {
                                    "col-sorted": !isEmpty(column.sort),
                                    "opacity-50": isLoadingData,
                                },
                                {
                                    "bg-light": canSelectRows && isMobile && isRowSelected?.(row),
                                }
                            )}
                        >
                            {formatCellContent(column, row.data[column.key], row)}
                        </td>
                    ))}
                    {row.actions && (
                        <td
                            className={cn(
                                "border-0 fs-7",
                                { "actions-cell": !isEmpty(row.actions) },
                                { "sticky-column-right bg-gray-200": stickyActionsColumn && !isMobile },
                                {
                                    "bg-light": canSelectRows && isMobile && isRowSelected?.(row),
                                }
                            )}
                        >
                            {row.actions.map((action, index) => action.content(row, index))}
                            {isMobile && canSelectRows && isRowSelectable?.(row) && (
                                <div className={cn({ "p-2": isMobile })}>
                                    <Form.Check checked={isRowSelected?.(row)} onChange={() => handleSelectRow?.(row)} />
                                </div>
                            )}
                        </td>
                    )}
                </tr>
            ))}
        </tbody>
    );
};

interface DataGridBodyProps {
    rows: DataGridRowConfig[];
    columns: DataGridColumnConfig[];
    hasActionsColumn?: boolean;
    nothingFoundMessage?: DataGridConfig["nothingFoundMessage"];
    isLoadingData?: boolean;
    stickyActionsColumn?: boolean;
    canSelectRows?: boolean;
    handleSelectRow?: (row: DataGridRowConfig) => void;
    isRowSelected?: (row: DataGridRowConfig) => boolean;
    isRowSelectable?: (row: DataGridRowConfig) => boolean;
}
