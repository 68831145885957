import { ReactComponent as InfoIcon } from "./info-icon.svg";
import { ReactComponent as AttentionIcon } from "./attention-icon.svg";
import { ReactComponent as RegularIcon } from "./regular-icon.svg";
import { NotificationType } from "types/NotificationType";

export const NotificationIcon = ({ className, gradientId, notificationType }: NotificationIconProps) => {
    return (
        <div className={className}>
            {notificationType === "info" && <InfoIcon />}
            {notificationType === "attention" && <AttentionIcon />}
            {notificationType === "regular" && <RegularIcon />}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="0" height="0">
                <linearGradient id={gradientId} gradientTransform="rotate(45)">
                    <stop offset="0" className="color1" />
                    <stop offset="1" className="color2" />
                </linearGradient>
            </svg>
        </div>
    );
};

interface NotificationIconProps {
    className?: string;
    gradientId: string;
    notificationType?: NotificationType;
}
