import { useEffect, useState } from "react";
import { refreshApplicationWorkflow, useApplicationWorkflow } from "components/WorkflowTasks/useApplicationWorkflow";
import { WorkflowFilterStepType, WorkFlowTaskModel } from "components/WorkflowTasks/types";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { isTaskCompleted } from "components/WorkflowTasks/utils";
import { Button } from "components/Button";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Task } from "./Task";
import { ReactComponent as TaskDonePlaceholder } from "../assets/task-done-placeholder.svg";
import { ReactComponent as TaskToDoPlaceholder } from "../assets/task-todo-placeholder.svg";
import { useToast } from "components/Toast";
import { EmptyStatePlaceholder } from "../EmptyStatePlaceholder";
import cn from "classnames";
import { WorkflowTask } from "components/WorkflowTasks/WorkflowTask";

const DONE_TASK_LIST_ID = "done-tasks-list";
const TODO_TASK_LIST_ID = "todo-tasks-list";

export const WorkflowTasks = ({ applicationNumber, stepType, onAfterTaskComplete, onChange, onWorkflowLoaded }: WorkflowTasksProps) => {
    const [workflow = [], isLoadingWorkflow] = useApplicationWorkflow(applicationNumber, stepType);
    const toast = useToast();
    const [activeTask, setActiveTask] = useState<WorkFlowTaskModel>();
    const [isDoneTasksExpanded, setIsDoneTasksExpanded] = useState(false);
    const hasDoneTasks = workflow.filter((task) => isTaskCompleted(task)).length > 0;
    const hasTodoTasks = workflow.filter((task) => !isTaskCompleted(task)).length > 0;

    useEffect(() => {
        if (!isLoadingWorkflow) {
            onWorkflowLoaded?.();
        }
    }, [isLoadingWorkflow, onWorkflowLoaded]);

    useEffect(() => {
        onChange?.(workflow);
    }, [onChange, workflow]);

    const onTaskClick = (task: WorkFlowTaskModel) => {
        setActiveTask(task);
    };

    const onTaskComplete = async (task: WorkFlowTaskModel) => {
        sendAnalyticsEvent(AnalyticsEventType.WORKFLOW_TASK_COMPLETED, { type: task.stepType });
        toast.success("Task moved to done");
        await refreshApplicationWorkflow(applicationNumber, stepType);
        onAfterTaskComplete();
        setActiveTask(undefined);
    };

    if (isLoadingWorkflow) {
        return null;
    }

    return (
        <>
            <div className="workflow-tasks d-flex align-items-center">
                <div className="container gap-3 d-flex flex-column">
                    <div className="tasks">
                        <h2 className="m-0 pb-3">
                            To-do{" "}
                            {hasTodoTasks && <span className="h3 m-0">• {workflow.filter((task) => !isTaskCompleted(task)).length}</span>}
                        </h2>
                        {hasTodoTasks ? (
                            <div id={TODO_TASK_LIST_ID} className="gap-2 d-flex flex-column">
                                {workflow
                                    .filter((task) => !isTaskCompleted(task))
                                    .map((task, index) => (
                                        <WorkflowTask key={task.wfTaskNumber} task={task} onTaskClick={onTaskClick}></WorkflowTask>
                                    ))}
                            </div>
                        ) : (
                            <div>
                                <EmptyStatePlaceholder
                                    className="pb-5"
                                    placeholderImage={<TaskToDoPlaceholder />}
                                    title="No pending tasks at the moment"
                                    description="Stay tuned for new tasks to appear here as you progress in your application"
                                />
                            </div>
                        )}
                    </div>
                    {workflow.length > 0 && (
                        <>
                            <hr className="my-0" />
                            <div className="d-flex gap-2 align-items-center">
                                {hasDoneTasks && (
                                    <Button
                                        aria-label={isDoneTasksExpanded ? "Hide done tasks" : "Show done tasks"}
                                        variant="link"
                                        className="text-body"
                                        aria-expanded={isDoneTasksExpanded}
                                        aria-controls={DONE_TASK_LIST_ID}
                                        onClick={() => setIsDoneTasksExpanded(!isDoneTasksExpanded)}
                                    >
                                        <FontAwesomeIcon icon={isDoneTasksExpanded ? "chevron-up" : "chevron-down"} />
                                    </Button>
                                )}
                                <h2 className="m-0">
                                    Done{" "}
                                    {hasDoneTasks && (
                                        <span className="h3 m-0">• {workflow.filter((task) => isTaskCompleted(task)).length}</span>
                                    )}
                                </h2>
                            </div>

                            {hasDoneTasks ? (
                                <Collapse in={isDoneTasksExpanded}>
                                    <div id={DONE_TASK_LIST_ID} className={cn("tasks", { "d-flex flex-column": isDoneTasksExpanded })}>
                                        {workflow
                                            .filter((task) => isTaskCompleted(task))
                                            .map((task) => (
                                                <WorkflowTask key={task.wfTaskNumber} task={task} onTaskClick={onTaskClick}></WorkflowTask>
                                            ))}
                                    </div>
                                </Collapse>
                            ) : (
                                <EmptyStatePlaceholder
                                    className="pb-5"
                                    placeholderImage={<TaskDonePlaceholder />}
                                    title="Your completed tasks will appear here"
                                />
                            )}
                        </>
                    )}
                </div>
            </div>

            {activeTask && applicationNumber && (
                <Task
                    applicationNumber={applicationNumber}
                    task={activeTask}
                    onComplete={onTaskComplete}
                    isTaskCompleted={isTaskCompleted(activeTask)}
                    onClose={() => setActiveTask(undefined)}
                />
            )}
        </>
    );
};

interface WorkflowTasksProps {
    applicationNumber?: string;
    stepType?: WorkflowFilterStepType;
    onAfterTaskComplete: () => void;
    onChange?: (workflow: WorkFlowTaskModel[]) => void;
    onWorkflowLoaded?: () => void;
}
