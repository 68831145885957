import { Container } from "components/Layout/Container";
import { deleteUser } from "components/utils";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export function SignOutPage() {
    const history = useHistory();

    useEffect(() => {
        deleteUser();
        history.replace("/");
    }, [history]);

    return <Container className="p-0">Signing out...</Container>;
}
