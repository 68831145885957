import { deleteUser, getRecaptchaToken, getSessionStorageItem, setSessionStorageItem } from "components/utils";
import { isString, isObject } from "lodash";
import { getUser } from "./user";
import { isNullOrWhitespace } from "./validation";

/**
 * Generate url from template
 *
 * @export
 * @param {string} urlTemplate - url with parameter placeholders
 * @param {object} params - url parameters
 * @returns {string} - url
 */
export function getUrl(urlTemplate: string | undefined, params: { [key: string]: string | number }) {
    if (isString(urlTemplate) && isObject(params) && Object.keys(params).length > 0) {
        return Object.keys(params).reduce((path, paramKey) => path.replace(`{${paramKey}}`, String(params[paramKey])), urlTemplate);
    }

    return urlTemplate ?? "";
}

/**
 * Http GET method for anonymous requests.
 *
 * @param url Request URL
 * @param recaptchaAction Optional recaptcha action name
 */
export async function httpGet(url: string, recaptchaAction: string | undefined = undefined) {
    const headers = new Headers([["Content-Type", "application/json"]]);

    if (!isNullOrWhitespace(recaptchaAction)) {
        headers.append("recaptcha", await getRecaptchaToken(recaptchaAction));
    }

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-store",
        headers,
    });

    return getResponseData(response);
}

/**
 * Http POST method for anonymous requests.
 *
 * @export
 * @param url Request URL
 * @param body Request body
 * @param recaptchaAction action name
 */
export async function httpPost(url: string, body: string | object | undefined, recaptchaAction: string = "http_post") {
    const headers = new Headers([
        ["Content-Type", "application/json"],
        ["recaptcha", await getRecaptchaToken(recaptchaAction)],
    ]);

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-store",
        headers,
        body: JSON.stringify(body),
    });

    return getResponseData(response);
}

/**
 * Http PUT method for anonymous requests.
 *
 * @param {string} url Request URL
 * @param {object} body Request body
 * @param {string} [recaptchaAction="http_put"] Recaptcha action name
 */
export async function httpPut(url: string, body: string | object | undefined, recaptchaAction: string = "http_put") {
    const headers = new Headers([
        ["Content-Type", "application/json"],
        ["recaptcha", await getRecaptchaToken(recaptchaAction)],
    ]);

    const response = await fetch(url, {
        method: "PUT",
        mode: "cors",
        cache: "no-store",
        headers,
        body: JSON.stringify(body),
    });

    return getResponseData(response);
}

/**
 * Http DELETE method for anonymous requests.
 *
 * @param {string} url Request URL
 * @param {object} body Request body
 */
export async function httpDelete(url: string, body: string | object | undefined) {
    const headers = new Headers([["Content-Type", "application/json"]]);

    const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        cache: "no-store",
        headers,
        body: JSON.stringify(body),
    });

    return getResponseData(response);
}

/**
 * Http POST method for authorized requests.
 *
 * @param url Request URL
 * @param body Request body
 */
export async function httpPostAuthorized(url: string, body?: string | object | undefined) {
    const request = async () => {
        const { accessToken } = getUser();
        return fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-store",
            headers: {
                authorization: `Bearer ${accessToken}`,
                ...(body instanceof FormData ? {} : { "Content-Type": "application/json" }),
                Accept: "application/json",
            },
            body: body instanceof FormData ? body : isObject(body) ? JSON.stringify(body) : body,
        });
    };

    let response = await request();

    if (response.status === 401) {
        await refreshAccessToken();
        response = await request();
    }

    return getResponseData(response);
}

/**
 * Http GET method for authorized requests.
 *
 * @param url Request URL
 */
export async function httpGetAuthorized(url: string) {
    const request = async () => {
        const { accessToken } = getUser();
        return fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "no-store",
            headers: {
                authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });
    };

    let response = await request();

    if (response.status === 401) {
        await refreshAccessToken();
        response = await request();
    }

    return getResponseData(response);
}

/**
 * Http DELETE method for authorized requests.
 *
 * @param url Request URL
 */
export async function httpDeleteAuthorized(url: string) {
    const request = async () => {
        const { accessToken } = getUser();
        return fetch(url, {
            method: "DELETE",
            mode: "cors",
            cache: "no-store",
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
        });
    };

    let response = await request();

    if (response.status === 401) {
        await refreshAccessToken();
        response = await request();
    }

    return getResponseData(response);
}

/**
 * Http PUT method for authorized requests.
 *
 * @param url Request URL
 * @param body Request body
 * @param {object | undefined} params Additional fetch params
 */
export async function httpPutAuthorized(url: string, body: string | object | undefined = undefined, params: object | undefined = {}) {
    const request = async () => {
        const { accessToken } = getUser();
        return fetch(url, {
            method: "PUT",
            mode: "cors",
            cache: "no-store",
            headers: {
                authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: body ? JSON.stringify(body) : undefined,
            ...(isObject(params) ? params : {}),
        });
    };

    let response = await request();

    if (response.status === 401) {
        await refreshAccessToken();
        response = await request();
    }

    return getResponseData(response);
}

export function getTokens() {
    const data = getSessionStorageItem("user");

    return isString(data)
        ? JSON.parse(data)
        : {
              accessToken: null,
              refreshToken: null,
          };
}

// Flag to monitor if the refresh token request is already in progress
let isRefreshAccessTokenInProgress = false;
// List of refresh token promises for all request waiting for refresh token request to complete
const refreshPromises: { resolve: (value: unknown) => void; reject: (reason?: any) => void }[] = [];

export async function refreshAccessToken() {
    return new Promise((resolve, reject) => {
        refreshPromises.push({ resolve, reject });
        requestAccessTokenRefresh();
    });
}

const requestAccessTokenRefresh = async () => {
    // If the refresh token request is already in progress, do nothing
    if (isRefreshAccessTokenInProgress) {
        return;
    }

    isRefreshAccessTokenInProgress = true;
    const { accessToken, refreshToken } = getTokens();
    const body = { accessToken, refreshToken };

    try {
        const response = await httpPut(process.env.REACT_APP_CUSTOMER_LOGIN_ENDPOINT as string, body, "token_refresh");
        saveTokens({ ...response });
        // Resolve all promises waiting for refresh token request to complete
        refreshPromises.forEach((promise) => promise.resolve(response));
    } catch (error) {
        deleteUser();
        // Reject all promises waiting for refresh token request to complete
        refreshPromises.forEach((promise) => promise.reject(error));
    } finally {
        // Clear the list of promises
        refreshPromises.length = 0;
        // Allow new refresh token requests
        isRefreshAccessTokenInProgress = false;
    }
};

export function saveTokens({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) {
    let user = getUser();
    user = { ...user, accessToken, refreshToken };
    setSessionStorageItem("user", JSON.stringify(user));
}

function isJson(response: Response) {
    const header = response.headers.get("content-type");
    return Boolean(header && (header.includes("application/json") || header.includes("application/problem+json")));
}

function isFileDownload(response: Response) {
    const header = response.headers.get("content-disposition");
    return Boolean(header && header.includes("attachment;"));
}

function getFileName(response: Response) {
    let fileName = null;
    const header = response.headers.get("content-disposition");

    if (header && header.includes("filename=")) {
        fileName = header.split("filename=")[1].split(";")[0];
    }

    return fileName;
}

function isText(response: Response) {
    const header = response.headers.get("content-type");
    return Boolean(header && header.includes("text/plain"));
}

/**
 * Get data from http response or throw error on failure.
 *
 */
async function getResponseData(response: Response) {
    if ([404, 500].includes(response.status)) {
        throw Error(response.statusText);
    }

    let data;
    if (isJson(response)) {
        data = await response.json();
    } else if (isFileDownload(response)) {
        data = {
            blob: await response.blob(),
            fileName: getFileName(response),
        };
    } else if (isText(response)) {
        data = await response.text();
    }

    if (response.status === 401) {
        deleteUser();
    }

    if (response.status !== 200) {
        throw data;
    }

    if (data.responseStatus === "failure") {
        throw Error(data.responseMessage);
    }

    return data;
}

export function getErrorMessage(error: unknown) {
    let errorMessage = "Error";

    if (isObject(error)) {
        if ("message" in error) {
            const message = (error as { message: string }).message;
            if (typeof message === "string") {
                errorMessage = message;
            }
        }

        if ("responseMessage" in error) {
            const responseMessage = (error as { responseMessage: string }).responseMessage;
            if (typeof responseMessage === "string") {
                errorMessage = responseMessage;
            }
        }

        if ("title" in error) {
            const title = (error as { title: string }).title;
            if (typeof title === "string") {
                errorMessage = title;
            }
        }
    }

    return errorMessage;
}
