import React from "react";
import Form from "react-bootstrap/Form";
import { WidgetProps } from "@rjsf/core";
import WidgetLabel from "./WidgetLabel";

const PasswordWidget = ({
    id,
    required,
    readonly,
    disabled,
    value,
    label,
    onFocus,
    onBlur,
    onChange,
    options,
    autofocus,
    schema,
    uiSchema,
    rawErrors = [],
}: WidgetProps) => {
    const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => onChange(value === "" ? options.emptyValue : value);
    const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onBlur(id, value);
    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

    return (
        <>
            <WidgetLabel id={id} label={label} uiSchema={uiSchema} schema={schema} required={required} rawErrors={rawErrors} />
            <Form.Control
                id={id}
                autoFocus={autofocus}
                className={rawErrors.length > 0 ? "is-invalid" : ""}
                required={required}
                disabled={disabled}
                readOnly={readonly}
                type="password"
                value={value ? value : ""}
                onFocus={_onFocus}
                onBlur={_onBlur}
                onChange={_onChange}
            />
        </>
    );
};

export default PasswordWidget;
