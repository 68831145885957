import React from "react";
import { FieldProps } from "@rjsf/core";
import { massageTableData } from "components/utils";

export interface DescriptionFieldProps extends Partial<FieldProps> {
    description?: string;
}

const DescriptionField = ({ description }: Partial<FieldProps>) => {
    if (description) {
        return <div className="mb-3" dangerouslySetInnerHTML={{ __html: massageTableData(description) }} />;
    }

    return null;
};

export default DescriptionField;
