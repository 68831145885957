import { History } from "history";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { isInIframe } from "./dom";

/**
 * Check if provided link is an anchor link.
 *
 */
export function isAnchorLink(link?: string): boolean {
    return String(link ?? "").startsWith("#");
}

export const isInternalLink = (link?: string) => {
    return String(link ?? "").startsWith("/");
};

export const isExternalLink = (link?: string) => {
    return !isInternalLink(link);
};

export const getExternalLink = (link?: string) => {
    if (!link) return link ?? "";
    return link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;
};

export const handleLinkClick = (
    event: MouseEvent | React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>,
    href: string | undefined,
    target: string | undefined,
    history: History
) => {
    if (href && !isAnchorLink(href)) {
        event.preventDefault();

        if (target === "_blank") {
            // Do not open internal links in new tab if we are in portal preview iframe
            if (isInIframe() && isInternalLink(href)) {
                alert(`Opening internal link ${href} in new tab.\nYou cannot open internal links in a new tab from the portal preview.`);
            } else {
                window.open(href, target);
            }
        } else {
            if (isExternalLink(href)) {
                // Do not open external links inside portal preview iframe
                if (isInIframe()) {
                    alert(`Opening external link ${href}.\nYou cannot open external links inside the portal preview.`);
                } else {
                    window.open(href, "_self");
                }
            } else {
                history.push(href);
            }
        }
    }
};

export const useCustomLinkHandler = <ContainerType>() => {
    const history = useHistory();
    const containerRef = useRef<ContainerType>(null);

    // Listen to links and use react-router for navigation
    useEffect(() => {
        let links: NodeListOf<HTMLAnchorElement> | null = null;

        const linkClickHandler = (event: MouseEvent): void => {
            const link = event.target as HTMLAnchorElement | null;
            const href = link?.getAttribute("href") ?? undefined;
            const target = link?.getAttribute("target") ?? undefined;
            handleLinkClick(event, href, target, history);
        };

        if (containerRef.current && containerRef.current instanceof HTMLElement) {
            links = containerRef.current.querySelectorAll("a");
            links.forEach((link) => link.addEventListener("click", linkClickHandler));
        }

        return () => {
            if (links) {
                links.forEach((link) => link.removeEventListener("click", linkClickHandler));
            }
        };
    }, [history]);

    return containerRef;
};
