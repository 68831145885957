import cn from "classnames";
import { isNil } from "lodash";

export const Html = ({ className, content }: HtmlWidgetProps) => {
    if (isNil(content)) {
        return null;
    }

    return <span dangerouslySetInnerHTML={{ __html: content }} className={cn("html-widget", className)} />;
};

type HtmlWidgetProps = {
    className?: string;
    content?: string;
};
