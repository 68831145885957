import { isInIframe } from "components/utils/dom";
import { getUrl, httpPutAuthorized } from "components/utils/http";
import { useEffect } from "react";

export const useApplicationViewActivity = (applicationNumber: string, loadApplication: Function) => {
    useEffect(() => {
        if (applicationNumber) {
            // Use to prevent sending two requests when both events "visibilitychange" and "pagehide" are fired.
            let terminatingEventSent = false;

            /**
             * Reload the app to restart activity if switching back from other tab.
             * Otherwise, terminate the app activity.
             */
            const onPageVisibilityChange = () => {
                if (terminatingEventSent) {
                    return;
                }

                if (document.visibilityState === "visible") {
                    terminatingEventSent = false;
                    loadApplication();
                } else {
                    applicationActivityTerminate(applicationNumber);
                }
            };

            const onPageHide = () => {
                terminatingEventSent = true;
                applicationActivityTerminate(applicationNumber);
            };

            // Triggered when switch to other tab, minimized browser or click "home" on mobile.
            document.addEventListener("visibilitychange", onPageVisibilityChange);

            // Triggered when close tab or navigate away.
            window.addEventListener("pagehide", onPageHide, { capture: true });

            return () => {
                document.removeEventListener("visibilitychange", onPageVisibilityChange);
                window.removeEventListener("pagehide", onPageHide, { capture: true });
                applicationActivityTerminate(applicationNumber);
            };
        }
    }, [applicationNumber, loadApplication]);
};

const applicationActivityTerminate = async (applicationNumber: string) => {
    if (isInIframe()) {
        return;
    }

    try {
        const url = getUrl(process.env.REACT_APP_APPLICATION_ACTIVITY_ENDPOINT, { applicationNumber });
        if (!url) throw new Error("Application activity url is not defined");
        httpPutAuthorized(url, undefined, {
            keepalive: true,
        });
    } catch {
        // Do nothing
    }
};
