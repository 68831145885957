import { Correspondence } from "types/Correspondence";
import { Button } from "components/Button";
import { Offcanvas } from "react-bootstrap";
import { HtmlContentAsPdf } from "components/HtmlContentPreview";

export const CorrespondenceView = ({ correspondence, onClose }: { correspondence: Correspondence; onClose: () => void }) => {
    return (
        <Offcanvas
            className="bg-white"
            show
            placement="end"
            onHide={onClose}
            aria-labelledby="correspondence-view-title"
            style={{ "--bs-offcanvas-width": "650px" }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="correspondence-view-title">Correspondence</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column px-4 py-3 gap-3 h-100 w-100">
                {/* @ts-ignore */}
                <HtmlContentAsPdf fileName={correspondence.item} fileContent={correspondence.content} onClose={onClose} />
                <Button className="mt-auto me-auto" variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Offcanvas.Body>
        </Offcanvas>
    );
};
