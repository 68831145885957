import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { CloseButton, Overlay, Popover } from "react-bootstrap";
import { ValidationMessageImpact } from "types/validation";

export const EVENT_ELEMENT_HIGHLIGHT = "element-highlight";

export const ElementHighlighter = () => {
    const [elementData, setElementData] = useState<ElementData | undefined>(undefined);

    const element = elementData?.element;

    useEffect(() => {
        const onChange: EventListener = (event: Event) => {
            const { detail } = event as CustomEvent<ElementData | undefined>;
            setElementData(detail);
        };

        window.addEventListener(EVENT_ELEMENT_HIGHLIGHT, onChange, false);

        return () => {
            window.removeEventListener(EVENT_ELEMENT_HIGHLIGHT, onChange, false);
        };
    }, []);

    if (isNil(element)) {
        return null;
    }

    return (
        <Overlay target={element} show placement="top" flip>
            {(props) => (
                <Popover id="element-highlight-overlay" {...props}>
                    <Popover.Body>
                        <CloseButton
                            className="position-absolute top-0 end-0"
                            aria-label="Close"
                            onClick={() => dispatchEventElementHighlight(null)}
                        />
                        {elementData?.description}
                    </Popover.Body>
                </Popover>
            )}
        </Overlay>
    );
};

export function dispatchEventElementHighlight(element: Element | null, description?: string, impact?: ValidationMessageImpact) {
    var event = new CustomEvent(EVENT_ELEMENT_HIGHLIGHT, {
        detail: {
            element,
            description,
            impact,
        },
    });
    window.dispatchEvent(event);
}

interface ElementData {
    element?: HTMLElement;
    description?: string;
    impact?: ValidationMessageImpact;
}
