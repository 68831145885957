import cn from "classnames";
import { Nav, Navbar } from "react-bootstrap";
import React from "react";
import Color from "color";
import { useContext, useLayoutEffect, useRef } from "react";
import useWidgetStyles from "components/utils/useWidgetStyles";
import useWidgetClasses from "components/utils/useWidgetClasses";
import { createId } from "components/utils/string";
import { RowContext } from "components/Layout/Row";
import { MenuItem } from "./MenuItem";
import { useNavigationPages } from "./useNavigationPages";
import { WidgetStylesProps } from "types/WidgetStylesProps";
import { WidgetClassesProps } from "types/WidgetClassesProps";
import { RowContextInterface } from "types/RowContextInterface";
import { useColor } from "components/utils/color";
import { updateCssVariables, useUserAuthenticated } from "components/utils";
import { ColumnContextInterface } from "types/ColumnContextInterface";
import { ColumnContext } from "components/Layout/Column";
import { AppContext } from "components/App/AppContext";
import { PageLink } from "components/App/utils";

import "./style.scss";

export function HeaderNavigationWidget(props: HeaderNavigationWidgetProps) {
    const alignXClasses = {
        left: "text-start",
        center: "text-center",
        right: "text-end",
    };

    const alignYClasses = {
        top: "d-flex align-items-start",
        center: "d-flex align-items-center",
        bottom: "d-flex align-items-end",
    };

    const pages = useNavigationPages();
    const isUserAuthenticated = useUserAuthenticated();
    const { settings } = useContext(AppContext);
    //@ts-ignore
    const rowContext: RowContextInterface = useContext(RowContext);
    //@ts-ignore
    const columnContext = useContext<ColumnContextInterface>(ColumnContext);

    const defaultNavBarTextColor = "rgba(0, 0, 0, 0.55)";

    const columnBackground = useColor(
        columnContext?.backgroundOff ? undefined : columnContext?.backgroundColor,
        columnContext?.backgroundColorOpacity
    );
    const rowBackground = useColor(rowContext?.backgroundOff ? undefined : rowContext?.backgroundColor, rowContext?.backgroundColorOpacity);
    let generalBackground = useColor(settings?.backgroundOff ? undefined : settings?.backgroundColor, settings?.backgroundColorOpacity);

    if (generalBackground === "#fffff") {
        generalBackground = "rgb(255,255,255)";
    }

    const background = columnBackground ?? rowBackground ?? generalBackground ?? "rgb(255,255,255)";
    const isLightBackground = Color(background).isLight();
    const className = isLightBackground ? "navbar-light" : "navbar-dark";

    useLayoutEffect(() => {
        if (isLightBackground) {
            updateCssVariables({
                "--header-navigation-link-border-color": Color("rgba(0, 0, 0, 0.08)"),
            });
        } else {
            updateCssVariables({
                "--header-navigation-link-border-color": Color("rgba(255, 255, 255, 0.2)"),
            });
        }
    }, [isLightBackground]);

    const textColor = useColor(props.textColor);

    const { padding, paddingLeft, paddingRight, paddingTop, paddingBottom, hoverTextColor, alignX = "left", alignY = "bottom" } = props;

    const linkStyle = useWidgetStyles({
        textColor: props.textColor,
    });

    const hoverColor = useColor(hoverTextColor);

    const linkWrapperStyle = useWidgetStyles({
        padding,
        paddingLeft,
        paddingRight,
        paddingBottom,
        paddingTop,
    });

    const linkClassName = useWidgetClasses({
        className: cn({
            "text-decoration-underline": props.underline ?? false,
            "menu-item-hover": props.hoverTextColor ?? false,
        }),
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        includeVisibilityClasses: false,
        alignX,
        alignXClasses,
    });

    const wrapperClassName = useWidgetClasses({
        alignY,
        alignYClasses,
    });

    const navBarIdRef = useRef(`row-${rowContext?.index ?? createId()}-navbar-nav`);
    return (
        <Navbar
            expand="md"
            aria-label="Navigation menu"
            className={cn("header-navigation-widget justify-content-end pt-0 pb-0", {
                "w-100": props.sameWidths,
            })}
        >
            <Navbar.Toggle className={className} aria-controls={navBarIdRef.current} />
            <Navbar.Collapse id={navBarIdRef.current}>
                <Nav
                    as="ul"
                    className={cn("justify-content-center nav-item-ul-wrapper", {
                        "nav-justified": props.sameWidths,
                        "flex-wrap": props.version && props.version > 1,
                    })}
                >
                    {pages.map((page, index) => (
                        <React.Fragment key={index}>
                            <MenuItem
                                wrapperClassName={cn(wrapperClassName, {
                                    "limit-max-width": props.sameWidths,
                                })}
                                linkClassName={linkClassName}
                                page={page}
                                linkWrapperStyle={linkWrapperStyle}
                                linkStyle={{ ...linkStyle, "--hover-color": hoverColor }}
                            />
                            {props.separators && (index !== pages.length - 1 || (props.showHeaderSignOutLink && isUserAuthenticated)) && (
                                <li className="separator align-self-stretch d-flex ">
                                    <div
                                        className="h-50 m-auto"
                                        style={{ borderLeft: `1px solid ${textColor ?? defaultNavBarTextColor}` }}
                                    ></div>
                                </li>
                            )}
                        </React.Fragment>
                    ))}
                    {props.showHeaderSignOutLink && isUserAuthenticated && (
                        <MenuItem
                            linkClassName={linkClassName}
                            wrapperClassName={cn(wrapperClassName, {
                                "limit-max-width": props.sameWidths,
                            })}
                            linkWrapperStyle={linkWrapperStyle}
                            page={{
                                title: props.headerSignOutLinkText || "Sign Out",
                                link: PageLink.SignOut,
                                isAnonymous: false,
                                components: [],
                            }}
                            linkStyle={{ ...linkStyle, "--hover-color": hoverColor }}
                        />
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

interface HeaderNavigationWidgetProps extends WidgetStylesProps, WidgetClassesProps {
    /**
     * Underline links. Default value "false"
     */
    underline?: boolean;
    sameWidths?: boolean;
    separators?: boolean;
    showHeaderSignOutLink?: boolean;
    headerSignOutLinkText?: string;
    version?: number;
}
