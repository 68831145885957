import { useCallback, useContext, useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { AppContext } from "components/App/AppContext";
import { useHistory } from "react-router-dom";
import { Heading } from "components/Heading";
import { JsonForm } from "components/JsonForm";
import { userForgotPassword } from "components/utils";
import { Container } from "components/Layout/Container";
import { PageLink } from "components/App/utils";
import { usePageTopAndBottomRows } from "components/utils/page";
import { PageComponentsList } from ".";
import { Alert } from "react-bootstrap";
import { ErrorSummary } from "components/ErrorSummary";

export function ForgotPasswordPage(props) {
    const history = useHistory();
    const submitted = history?.location?.state?.submitted === true;
    const { programNumber } = useContext(AppContext);

    const { search } = useLocation();
    const routeParams = useMemo(() => new URLSearchParams(search), [search]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { topRows, bottomRows } = usePageTopAndBottomRows();
    const [error, setError] = useState();

    const schema = {
        type: "object",
        required: ["userName"],
        properties: {
            userName: {
                type: "string",
                title: "Email Address:",
                format: "email",
                default: routeParams.get("email") ?? undefined,
            },
        },
    };

    const uiSchema = {
        email: {
            "ui:placeholder": "Enter Email Address",
        },
    };

    const onSubmit = useCallback(
        (data) => {
            const { formData } = data;
            setIsSubmitting(true);
            userForgotPassword({
                userName: formData.userName,
                programNumber: programNumber,
            })
                .then(() => history.replace({ pathName: PageLink.ForgotPassword, state: { submitted: true } }))
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [history, programNumber]
    );

    return submitted ? (
        <Container className="p-0">
            <Alert variant="success">
                <h2>Thank you.</h2>
                <p>
                    If an account for this address exists in our system, you will receive an email with password reset instructions.
                    <br />
                    Please check your inbox, including your spam filter.
                </p>
            </Alert>
            <Link to={PageLink.SignIn}>Return to Sign in Page</Link>
        </Container>
    ) : (
        <>
            <Container className="p-0">
                <Heading>Forgot Password</Heading>
                {error && <ErrorSummary errorSummary={error} />}
            </Container>
            <PageComponentsList components={topRows} />
            <Container className="p-0">
                <JsonForm
                    schema={schema}
                    uiSchema={uiSchema}
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                    formName="Forgot Password"
                ></JsonForm>
            </Container>
            <PageComponentsList components={bottomRows} />
        </>
    );
}
