import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";
import { isInIframe } from "components/utils/dom";

const isIframe = isInIframe();

export const useRelationStatistics = (
    applicationNumber: string
): [
    relationsStatistics: RelationsStatisticsDataResponse | undefined,
    isLoading: boolean,
    error: any,
    mutate: () => Promise<RelationsStatisticsDataResponse | undefined>
] => {
    const url =
        !isIframe && applicationNumber
            ? getUrl(process.env.REACT_APP_APPLICATION_RELATIONS_STATISTICS_ENDPOINT, { appId: applicationNumber })
            : null;

    const { data, error, mutate } = useSWR<any>(url, httpGetAuthorized);
    const isLoading = !error && !data;

    const relationsStatistics = data?.CustomerAppRelationsStats;

    return [relationsStatistics, isLoading, error, mutate];
};

export interface RelationsStatisticsDataResponse {
    RebatesTotal: string;
    StatusList: {
        PortalStatus: {
            Status: string;
            Count: string;
        }[];
    };
}
