import "./styles.scss";

export const SkipLink = () => {
    const onClick = (e) => {
        e.preventDefault();
        // Add hash to URL
        window.location.hash = "main-content";
    };

    return (
        <a href="#main-content" className="skip-to-main-content-link outline-0" onClick={onClick}>
            Skip to main content
        </a>
    );
};
