import { Heading } from "components/Heading";
import { Container } from "components/Layout/Container";
import { Link, useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { PageLink } from "components/App/utils";
import { usePageTopAndBottomRows } from "components/utils/page";
import { PageComponentsList } from ".";
import { isInIframe } from "components/utils/dom";

export function ThankYouPage() {
    const location = useLocation();
    // Use fake number if opened in portal builder preview
    const projectNumber = isInIframe() ? "12345678" : new URLSearchParams(location.search).get("projectNumber");
    const { topRows, bottomRows } = usePageTopAndBottomRows();

    return (
        <>
            <PageComponentsList components={topRows} />
            <Container className="p-0">
                <Alert variant="success">
                    <Heading className="alert-heading">Application submitted</Heading>
                    <p className="mb-0">Project number {projectNumber}</p>
                </Alert>

                <Link to={PageLink.CustomerHome}>Return to Account overview</Link>
            </Container>
            <PageComponentsList components={bottomRows} />
        </>
    );
}
