import { Avatar } from "components/Avatar";
import { getContactColor, getContactName } from "components/ContactCard/utils";
import { formatDateAndTime } from "components/utils/date";
import { MessageListProps, Note } from "./utils";
import { isEmpty } from "lodash";
import { ReactComponent as MessagesPlaceholder } from "../../SubmittedApplicationV2/assets/messages-empty-state.svg";
import { EmptyStatePlaceholder } from "components/Page/SubmittedApplicationV2/EmptyStatePlaceholder";
import { stripHtml } from "components/utils/string";

export const MessageList = ({ notes, onNotificationClick }: MessageListProps) => {
    const notesArray = Array.isArray(notes) ? notes : !notes ? [] : [notes];

    return (
        <div className="messages d-flex flex-column overflow-auto">
            {!isEmpty(notesArray) ? (
                <>
                    {notesArray.length > 0 &&
                        notesArray.map((note: Note, index: number) => {
                            if (!note) {
                                return null;
                            }

                            return (
                                <div
                                    role="button"
                                    key={index}
                                    tabIndex={0}
                                    onKeyPress={(e) => e.key === "Enter" && onNotificationClick(note?.appId, "inbox")}
                                    onClick={() => onNotificationClick(note?.appId, "inbox")}
                                    data-metadata={JSON.stringify({ name: "Open application from inbox" })}
                                >
                                    <div className="message cursor-pointer d-flex flex-column gap-2 px-2 pb-3 pt-2">
                                        <div className="d-flex align-items-center gap-2">
                                            <span className="p-1 bg-danger border rounded-circle" />
                                            <Avatar
                                                color={getContactColor(note?.name)}
                                                text={getContactName(note?.name)}
                                                badgeSize={"sm"}
                                            />
                                            {note?.name && <p className="fw-normal m-0">{note?.name} left a message</p>}
                                        </div>
                                        {note?.noteContent && (
                                            <div className="rounded-3 p-2 border border-1 border-success-subtle fw-normal w-fit-content">
                                                <p className="content m-0 text-break">{stripHtml(note?.noteContent)}</p>
                                            </div>
                                        )}
                                        <div className="d-flex fw-normal small">
                                            <p className="m-0">{`#${note?.projectNumber}`}</p>
                                            {note?.projectName && (
                                                <p
                                                    style={{ maxWidth: "28rem" }}
                                                    className="ps-1 m-0 text-truncate"
                                                >{`• ${note?.projectName}`}</p>
                                            )}
                                        </div>
                                        <p className="m-0 small">{formatDateAndTime(note?.date)}</p>
                                    </div>
                                    <hr className="m-0" />
                                </div>
                            );
                        })}
                </>
            ) : (
                <EmptyStatePlaceholder
                    className="mb-auto mt-0"
                    placeholderImage={<MessagesPlaceholder />}
                    title="No new messages yet"
                    description="New messages may appear here as your applications progress"
                />
            )}
        </div>
    );
};
