import { EquipmentAdd } from "components/EquipmentBlock/EquipmentAdd";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";
import { EquipmentBlockContextValue } from "types/EquipmentBlockContextValue";
import { refreshEquipmentList } from "components/EquipmentBlock/useEquipmentList";
import { createEquipment } from "components/EquipmentBlock/utils";
import { EquipmentBlockContext } from "components/utils/contexts";
import { useCallback, useMemo } from "react";
import { completeWorkflowTask } from "components/WorkflowTasks/utils";
import { TaskProps } from "components/WorkflowTasks/types";
import { Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NewMeasureTask = ({ applicationNumber, task, onComplete, onClose, isTaskCompleted }: TaskProps) => {
    const onEquipmentAddSubmit = useCallback(
        async (equipmentItem: EquipmentAddSubmitItem) => {
            const taskNumber = task.wfTaskNumber;
            await createEquipment(applicationNumber, equipmentItem, taskNumber);
            await completeWorkflowTask(applicationNumber, task);
            refreshEquipmentList(applicationNumber);
            onComplete(task);
        },
        [applicationNumber, onComplete, task]
    );

    const contextValue: EquipmentBlockContextValue = useMemo(
        () => ({
            applicationNumber,
            allowEquipmentEntry: true,
            onEquipmentAddSubmit,
            onEquipmentAddClose: () => onClose(),
            onEquipmentAdded: () => completeWorkflowTask(applicationNumber, task).then(() => onComplete(task)),
            isWorkflowTask: true,
            isTaskCompleted: isTaskCompleted,
            task,
        }),
        [applicationNumber, onClose, onComplete, onEquipmentAddSubmit, task, isTaskCompleted]
    );

    if (isTaskCompleted) {
        return (
            <Offcanvas className="equipment-panel" show placement="end" onHide={onClose} aria-labelledby="task-title">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="task-title">Done - My completed task</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="d-flex flex-column">
                    <div className="d-flex flex-column px-2">
                        <p className="d-flex m-0 py-2 fw-medium align-items-center">
                            <FontAwesomeIcon aria-label="done" className="pe-2" icon={["fal", "circle-check"]} size="lg" color="green" />
                            Add equipment measure
                        </p>
                        <div className="d-flex flex-column ps-4">
                            <p className="m-0 text-break fw-medium ps-1">{task.step}</p>
                            {task.content && <p className="m-0 py-2 text-break ps-1">{task.content}</p>}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        );
    }
    return (
        <EquipmentBlockContext.Provider value={contextValue}>
            <EquipmentAdd />
        </EquipmentBlockContext.Provider>
    );
};
