import { SubmittedAppData } from "types/SubmittedAppData";
import { MessagesSection } from "./MessagesSection";
import { CorrespondenceSection } from "./CorrespondenceSection";
import { ButtonGroup } from "react-bootstrap";
import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Sections } from "./types";
import { useSectionChange } from "./useSectionChange";

export const DesktopView = ({
    applicationNumber,
    appData,
    onChanged,
}: {
    applicationNumber: string;
    appData: SubmittedAppData;
    onChanged?: () => void;
}) => {
    const [activeSection, setActiveSection] = useSectionChange();

    return (
        <div className="submitted-app-messages mx-auto d-flex flex-column w-100">
            <h2 className="m-0">Messages and Correspondence</h2>

            <div className="d-flex flex-column align-items-start gap-3 w-100 py-3">
                <ButtonGroup>
                    <Button
                        variant="outline-secondary"
                        active={activeSection === Sections.Messages}
                        aria-pressed={activeSection === Sections.Messages}
                        aria-controls="submitted-app-messages"
                        className="d-flex align-items-center gap-2"
                        onClick={() => setActiveSection(Sections.Messages)}
                    >
                        <Icon icon={["fal", "messages"]} />
                        <span>Messages</span>
                    </Button>
                    <Button
                        variant="outline-secondary"
                        active={activeSection === Sections.Correspondence}
                        aria-pressed={activeSection === Sections.Correspondence}
                        aria-controls="submitted-app-correspondence"
                        className="d-flex align-items-center gap-2"
                        onClick={() => setActiveSection(Sections.Correspondence)}
                    >
                        <Icon icon={["fal", "envelopes-bulk"]} />
                        <span>Mail Correspondence</span>
                    </Button>
                </ButtonGroup>
            </div>
            <div id="submitted-app-messages">
                {activeSection === Sections.Messages && (
                    <MessagesSection appData={appData} applicationNumber={applicationNumber} onChanged={onChanged} />
                )}
            </div>
            <div id="submitted-app-correspondence">
                {activeSection === Sections.Correspondence && <CorrespondenceSection applicationNumber={applicationNumber} />}
            </div>
        </div>
    );
};
