import cn from "classnames";
import { NotificationType } from "types/NotificationType";

export const NotificationContainer = ({ className, notificationType, ariaLabel, children }: NotificationContainerProps) => {
    return (
        <div
            className={cn("notification", className, {
                "notification--info-type": notificationType === "info",
                "notification--attention-type": notificationType === "attention",
                "notification--regular-type": notificationType === "regular",
            })}
            role="region"
            aria-label={ariaLabel}
        >
            {children}
        </div>
    );
};

interface NotificationContainerProps {
    className?: string;
    notificationType?: NotificationType;
    ariaLabel: string;
    children: React.ReactNode;
}
