import cn from "classnames";

export const DataGridHead = ({ className, children }: DataGridHeadProps) => {
    return <thead className={cn("data-grid-head", className)}>{children}</thead>;
};

interface DataGridHeadProps {
    className?: string;
    children: React.ReactNode;
}
