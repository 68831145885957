import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR, { SWRResponse } from "swr";
import { useApprovedMeasureGridId } from "./useApprovedMeasureGridId";

export const useApprovedMeasureGridConfig = (applicationNumber: string | undefined, industryMeasureNumber: string) => {
    const [dataGridId, isLoadingGridId] = useApprovedMeasureGridId(applicationNumber, industryMeasureNumber);

    const url = dataGridId ? getUrl(process.env.REACT_APP_DATA_GRID_CONSTRUCT_ENDPOINT, { dataGridId }) + "?returnType=JSON" : null;
    const { data, error } = useSWR(url, httpGetAuthorized) as SWRResponse<{
        grid: any;
    }>;

    const isLoading = isLoadingGridId || (!error && !data && url !== null);
    let config = undefined;

    if (data?.grid?.[0]) {
        config = data?.grid?.[0];
    }

    return [config, isLoading, error];
};
