import { formatDateAndTime } from "components/utils/date";
import { Table } from "react-bootstrap";
import { Correspondence } from "types/Correspondence";
import { useCorrespondenceListActions } from "./useCorrespondenceListActions";
import { CorrespondenceView } from "./CorrespondenceView";
import { DownloadButton, ViewButton } from "./CorrespondenceList";
import { EmptyStatePlaceholder } from "../EmptyStatePlaceholder";
import { ReactComponent as CorrespondenceEmptyStatePlaceholder } from "./../assets/correspondence-placeholder.svg";

export const MobileViewCorrespondenceList = ({ correspondence }: { correspondence: Correspondence[] }) => {
    const { downloadingCorrespondence, activeCorrespondence, onDownload, onView, onCloseView } = useCorrespondenceListActions();

    const showEmptyState = correspondence?.length === 0;

    if (showEmptyState) {
        return (
            <EmptyStatePlaceholder
                className="pb-5 app-correspondence-empty-state"
                placeholderImage={<CorrespondenceEmptyStatePlaceholder />}
                title="No correspondence yet"
                description="New correspondence, such as letters, emails, and texts, may appear here as you advance in your application."
            />
        );
    }

    return (
        <>
            {correspondence?.map((c, index) => (
                <Table key={index} className="table-v2 m-0 align-middle">
                    <caption className="visually-hidden">Correspondence list</caption>
                    <tbody>
                        <tr>
                            <th scope="row">Type</th>
                            <td className="ps-3 fw-bold">{c.type}</td>
                        </tr>
                        <tr>
                            <th scope="row">Item</th>
                            <td className="ps-3 text-break" id={`${c.documentNumber}-item`}>
                                {c.item}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Date</th>
                            <td className="ps-3">{formatDateAndTime(c.date)}</td>
                        </tr>
                        <tr>
                            <th scope="row">Actions</th>
                            <td className="d-flex align-items-start px-1">
                                <div className="d-flex flex-row w-100 align-items-center px-4 py-2 justify-content-between">
                                    <DownloadButton
                                        downloading={downloadingCorrespondence.includes(c.documentNumber ?? "")}
                                        onClick={() => onDownload(c)}
                                        ariaDescribedby={`${c.documentNumber}-item`}
                                        hideText
                                    />
                                    <div className="vr h-50 align-self-center" />
                                    <ViewButton onClick={() => onView(c)} ariaDescribedby={`${c.documentNumber}-item`} hideText />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            ))}
            {activeCorrespondence && <CorrespondenceView correspondence={activeCorrespondence} onClose={onCloseView} />}
        </>
    );
};
