import { mapGridRowToObject } from "components/DataGrid/utils";
import { toArray } from "components/utils/array";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { Correspondence } from "../../../types/Correspondence";
import { isInIframe } from "components/utils/dom";

enum DocumentsGridColumnKeys {
    appId = "c_32a5c30c-aea6-48a1-bca5-5b79683b0ccd",
    item = "c_ac7e876f-3ea2-405c-97bc-d9555b4e1852",
    status = "c_ec5b63c8-3ebe-4848-9b1e-fd7fbea1ae44",
    content = "c_5cc8a266-5044-42cb-b34f-211f19e634fe",
    documentNumber = "c_4adc7a7e-b42a-4083-b53e-7f0f355aa503",
    type = "c_397d35bc-68cf-4b1c-9cb0-ea14498b3a3e",
    date = "c_4c189a63-c166-44a8-9bca-42b5f4a1701b",
}

export const useAppCorrespondence = (
    applicationNumber: string,
    pageNumber: number,
    pageSize: number
): [correspondenceItems: Array<Correspondence> | undefined, isLoading: boolean, error: any, pagesCount: number] => {
    const [correspondenceItems, setCorrespondenceItems] = useState<Correspondence[]>([]);
    const baseUrl = getUrl(process.env.REACT_APP_APPLICATION_CORRESPONDENCE_ENDPOINT, { applicationNumber });
    const query = new URLSearchParams();
    query.append("pageNum", String(pageNumber));
    query.append("recsPerPage", String(pageSize));
    query.append("sortBy", DocumentsGridColumnKeys.date);
    query.append("sortAsc", "0");

    const loadMockData = isInIframe();

    const url = applicationNumber && !loadMockData ? baseUrl + "?" + query.toString() : null;

    const { data, error } = useSWR(url, httpGetAuthorized);

    const isLoading = !error && !data && !loadMockData;

    // Update data only when not loading anymore. Prevents grid flicker on sort.
    useEffect(() => {
        if (!isLoading) {
            const newData = toArray(data?.grid?.rows).map((r: any) => mapGridRowToObject(DocumentsGridColumnKeys, r));
            setCorrespondenceItems(newData);
        }
    }, [data, isLoading]);

    const pagesCount = Math.ceil((toArray(data?.grid?.rows)[0]?.totRecords || 0) / pageSize);

    if (loadMockData) {
        return [mockedCorrespondenceItems, false, null, 1];
    }

    return [correspondenceItems, isLoading, error, pagesCount];
};

const mockedCorrespondenceItems: Correspondence[] = [
    {
        ...mapGridRowToObject(DocumentsGridColumnKeys, {
            "c_4cbfba29-d979-40a4-b82c-442307fb3627": "12345678",
            "c_32a5c30c-aea6-48a1-bca5-5b79683b0ccd": "?",
            "c_4adc7a7e-b42a-4083-b53e-7f0f355aa503": "?",
            "c_397d35bc-68cf-4b1c-9cb0-ea14498b3a3e": "Email",
            "c_5cc8a266-5044-42cb-b34f-211f19e634fe": "Test message",
            "c_ac7e876f-3ea2-405c-97bc-d9555b4e1852": "Mail",
            "c_3b3f01bf-8fb1-4f4f-b77e-5a1b2c20b934": "AdHoc Task",
            "c_9115ea37-787a-4f8b-b73c-4b2df094923b": "CUSTOMER - PRIMARY; ",
            "c_4c189a63-c166-44a8-9bca-42b5f4a1701b": "2023-11-06T08:10:53.357",
            "c_ec5b63c8-3ebe-4848-9b1e-fd7fbea1ae44": "Public",
            MoreRecords: "0",
            totRecords: "1",
        }),
    },
];
