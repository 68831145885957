import { formatDateAndTime } from "components/utils/date";
import { Task, TaskListProps } from "./utils";
import { isEmpty } from "lodash";
import { ReactComponent as TaskToDoPlaceholder } from "../../SubmittedApplicationV2/assets/task-todo-placeholder.svg";
import { EmptyStatePlaceholder } from "components/Page/SubmittedApplicationV2/EmptyStatePlaceholder";
import { stripHtml } from "components/utils/string";
import { WorkflowTaskStepType } from "components/WorkflowTasks/types";

export const TaskList = ({ tasks, onNotificationClick }: TaskListProps) => {
    const tasksArray = Array.isArray(tasks) ? tasks : !tasks ? [] : [tasks];

    const stepTitle = ({ stepType }: Task) => {
        let taskTitle = stepType;

        switch (stepType) {
            case WorkflowTaskStepType.ApplicationFormFieldEntry:
            case WorkflowTaskStepType.ApplicationFormEntry:
                taskTitle = "Submit form info";
                break;
            case WorkflowTaskStepType.CreateNewMeasure:
                taskTitle = "Add equipment measure";

                break;
            case WorkflowTaskStepType.DocumentUpload:
                taskTitle = "Upload file";
                break;
            default:
                break;
        }
        return taskTitle;
    };

    return (
        <div className="tasks d-flex flex-column overflow-auto">
            {!isEmpty(tasksArray) ? (
                <>
                    {tasksArray.length > 0 &&
                        tasksArray.map((task: Task, index: number) => {
                            if (!task) {
                                return null;
                            }

                            return (
                                <div
                                    role="button"
                                    key={index}
                                    tabIndex={0}
                                    onKeyPress={(e) => e.key === "Enter" && onNotificationClick(task?.appId, "task")}
                                    onClick={() => onNotificationClick(task?.appId, "tasks")}
                                    data-metadata={JSON.stringify({ name: "Open application from tasks" })}
                                >
                                    <div className="task d-flex flex-column gap-1 p-2">
                                        <div className="d-flex align-items-center">
                                            <span className="p-1 bg-danger border rounded-circle" />
                                            <p className="fw-medium m-0 px-2">{task?.stepType && stepTitle(task)}</p>
                                        </div>
                                        {task?.content && <p className="content m-0">{stripHtml(task.content)}</p>}
                                        <div className="d-flex fw-normal small">
                                            <p className="m-0">{`#${task?.projectNumber}`}</p>
                                            {task?.projectName && (
                                                <p
                                                    style={{ maxWidth: "20rem" }}
                                                    className="ps-1 m-0 text-truncate"
                                                >{`• ${task.projectName}`}</p>
                                            )}
                                        </div>
                                        <p className="m-0 small">{formatDateAndTime(task?.date)}</p>
                                    </div>
                                    <hr className="m-0" />
                                </div>
                            );
                        })}
                </>
            ) : (
                <EmptyStatePlaceholder
                    className="mb-auto mt-0"
                    placeholderImage={<TaskToDoPlaceholder />}
                    title="No pending tasks at the moment"
                    description="New tasks may appear here as your applications progress"
                />
            )}
        </div>
    );
};
