const submittedAppMock = {
    primaryContactNumber: "1234",
    premiseContactNumber: "5678",
    contractorContactNumber: "11111",
    status: "Submitted",
    applicationDate: "2022-09-27 06:47:57.070",
    projectNumber: "00000000000000",
    projectName: "Preview",
    progId: "000000000000000",
    programName: "Portal Preview Program",
    applicationFlags: {
        isApplicationLocked: false,
        isEditablePrimaryContact: true,
        isEditablePremiseContact: false,
        isEditableContractorContact: false,
        isEditableEquipment: false,
        allowApprovedMeasureShortSearch: false,
    },
    applicationContacts: [
        {
            contactNumber: "1234",
            contactType: "17",
            contactTypeDesc: "Primary",
            contactTitle: "Primary contact",
            firstName: "John",
            lastName: "Smith",
            company: "Test Company",
            address: "Test Street 10",
            addressCont: "-",
            city: "Test City",
            state: "CA",
            zip: "000000",
            accountNumber: "000",
            phone: "(111) 111-1111",
            cell: "-",
            fax: "-",
            email: "johnsmith@appliedenergygroup.com",
            premiseId: "-",
            taxId: "-",
            meterId: "-",
        },
        {
            contactNumber: "5678",
            contactType: "26",
            contactTypeDesc: "Premise",
            contactTitle: "Premise contact",
            firstName: "John",
            lastName: "Smith",
            company: "Test Company",
            address: "Test Street 10",
            addressCont: "-",
            city: "Test City",
            state: "CA",
            zip: "00000",
            accountNumber: "000",
            phone: "(111) 111-1111",
            cell: "-",
            fax: "-",
            email: "johnsmith@appliedenergygroup.com",
            premiseId: "-",
            taxId: "-",
            meterId: "-",
        },
        {
            contactNumber: "11111",
            contactType: "19",
            contactTypeDesc: "Contractor",
            contactTitle: "Contractor",
            firstName: "John",
            lastName: "Smith",
            company: "Test Company",
            address: "Test Street 10",
            addressCont: "-",
            city: "Test City",
            state: "CA",
            zip: "000000",
            accountNumber: "000",
            phone: "(111) 111-1111",
            cell: "-",
            fax: "-",
            email: "johnsmith@appliedenergygroup.com",
            premiseId: "-",
            taxId: "-",
            meterId: "-",
        },
        {
            contactNumber: "1",
            contactType: "22",
            contactTypeDesc: "Other",
            contactTitle: "Additional Contact Information",
            firstName: "John",
            lastName: "Smith",
            company: "Test Company",
            address: "Test Street 10",
            addressCont: "-",
            city: "Test City",
            state: "CA",
            zip: "000000",
            accountNumber: "000",
            phone: "-",
            cell: "-",
            fax: "-",
            email: "johnsmith@appliedenergygroup.com",
            premiseId: "-",
            taxId: "-",
            meterId: "-",
        },
        {
            contactNumber: "2",
            contactType: "22",
            contactTypeDesc: "Other",
            contactTitle: "Additional Contact Information",
            firstName: "Alice",
            lastName: "Smith",
            company: "Test Company",
            address: "Test Street 10",
            addressCont: "-",
            city: "Test City",
            state: "CA",
            zip: "000000",
            accountNumber: "000",
            phone: "-",
            cell: "-",
            fax: "-",
            email: "johnsmith@appliedenergygroup.com",
            premiseId: "-",
            taxId: "-",
            meterId: "-",
        },
        {
            contactNumber: "3",
            contactType: "22",
            contactTypeDesc: "Other",
            contactTitle: "Additional Contact Information",
            firstName: "Mike",
            lastName: "Smith",
            company: "Test Company",
            address: "Test Street 10",
            addressCont: "-",
            city: "Test City",
            state: "CA",
            zip: "000000",
            accountNumber: "000",
            phone: "-",
            cell: "-",
            fax: "-",
            email: "johnsmith@appliedenergygroup.com",
            premiseId: "-",
            taxId: "-",
            meterId: "-",
        },
    ],
    applicationForms: [
        {
            pageNumber: "1",
            pageName: "Form 1",
            allowFormEdit: true,
        },
        {
            pageNumber: "2",
            pageName: "Form 2",
            allowFormEdit: false,
        },
    ],
    applicationEquipment: [],
    applicationRebates: [
        {
            rebateContactNumber: "test",
            rebateNumber: "46BCFBED34D14179B19DF6A1D80C83F5",
            rebateType: "Check Payment",
            amount: "50",
            payee: "John Smith",
            dateEntered: "2022-09-30 09:41:23.013",
            checkNumber: "1123",
            checkAmount: "100",
            checkDate: "2022-09-29T00:00:00",
            checkResolutionStatus: "",
            checkResolutionStatusDate: "2022-10-03T00:00:00",
        },
    ],
    applicationNotes: [
        {
            noteDate: "2022-09-29 08:50:00.587",
            userName: "John Smith",
            note: "Test.",
            noteType: "public",
            refType: "Application",
        },
        {
            noteDate: "2022-10-11 05:47:30.343",
            userName: "John Smith",
            note: "Test.",
            noteType: "public",
            refType: "Application",
            isUnread: true,
        },
    ],
    applicationFiles: [
        {
            fileId: "000000",
            fileName: "test.txt",
            uploadDate: "2023-02-17T11:53:27.807",
            folder: "FILES",
            fileSize: "13264",
        },
    ],
};

export default submittedAppMock;
