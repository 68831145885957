import React, { memo } from "react";
import ClassNames from "classnames";

import Dropzone from "react-dropzone";
import { fromEvent } from "file-selector";

import "./UploadFile.scss";
import { Button } from "components/Button";
import { MAX_FILE_SIZE } from "components/utils/files";

const UploadFile = memo(({ className, disabled, multiple, files, removeFile, id, onDrop, accept }) => {
    const classNames = ClassNames("upload-file-area", className, {
        "file-upload-disabled": disabled,
    });

    return (
        <Dropzone
            maxSize={MAX_FILE_SIZE}
            className={classNames}
            disabled={disabled}
            multiple={multiple}
            accept={accept}
            getDataTransferItems={(e) => fromEvent(e)}
            id={id}
            onDrop={onDrop}
        >
            <div className="dropzone-text">
                <span>
                    <span>Drop a file to attach, or</span>
                    <span className="upload-link">browse</span>
                </span>
            </div>
            {files && files.length > 0 && (
                <ul className="dropzone-file-list">
                    {files.map((file, i) => {
                        const { name } = file;
                        return (
                            <li className="m-2" key={i}>
                                <Button disabled={disabled} className="me-3" title="Remove" onClick={(e) => removeFile(e, file)}></Button>
                                <span className="me-3">{name}</span>
                            </li>
                        );
                    })}
                </ul>
            )}
        </Dropzone>
    );
});

export default UploadFile;
