import { useEffect, useRef } from "react";
import * as pbi from "powerbi-client";
import useMediaQuery from "components/utils/useMediaQuery";
import cn from "classnames";

export const PowerBiReportEmbed = (props: PowerBiReportEmbedProps) => {
    const { className, accessToken, embedUrl, embedId, pageName, navContentPaneEnabled } = props;

    const reportRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        let report: any = null;

        if (reportRef.current) {
            const mobileSettings = {
                layoutType: pbi.models.LayoutType.MobilePortrait,
            };
            const config = {
                type: "report",
                id: embedId,
                tokenType: pbi.models.TokenType.Embed,
                accessToken,
                embedUrl,
                pageName,
                permissions: pbi.models.Permissions.Read,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: navContentPaneEnabled ?? (pageName ? false : true),
                    hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
                    ...(isMobile ? mobileSettings : {}),
                },
            };

            /* eslint-disable */ // powerbi object is global
            // @ts-ignore
            report = powerbi.embed(reportRef.current, config);
            /*eslint-enable */

            const iframe = reportRef.current?.querySelector("iframe");
            if (iframe) {
                iframe.title = "PowerBI Report " + embedId;
            }
        }

        return () => {
            if (report && reportRef.current) {
                /* eslint-disable */ // powerbi object is global
                // @ts-ignore
                powerbi.reset(reportRef.current);
                /*eslint-enable */
            }
        };
    }, [accessToken, embedId, embedUrl, isMobile, navContentPaneEnabled, pageName]);

    return (
        <div className={cn("powerbi-report d-flex flex-column", className)}>
            <div key={embedId} className="powerbi-report-body flex-grow-1" ref={reportRef} />
        </div>
    );
};

interface PowerBiReportEmbedProps {
    className?: string;
    accessToken: string;
    embedUrl: string;
    embedId: string;
    pageName?: string;
    navContentPaneEnabled?: boolean;
}
