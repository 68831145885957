import cn from "classnames";

export const Tag = ({
    className,
    title,
    value,
    tooltip,
    onRemove,
}: {
    className?: string;
    title?: string;
    value: string;
    tooltip?: string;
    onRemove?: () => void;
}) => {
    return (
        <div
            className={cn(className, "filter-tag d-flex flex-row align-items-center gap-1 small border rounded-1 px-2 py-1")}
            title={tooltip}
        >
            <div className="d-flex flex-wrap gap-1">
                {title && <strong>{title}:</strong>}
                <span>{value}</span>
            </div>
            {onRemove && <button type="button" className="btn-close" aria-label="Close" onClick={onRemove}></button>}
        </div>
    );
};
