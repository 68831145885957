import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

const waitingStates = [
    {
        message: "Application processing",
        timeout: 5000,
    },
    {
        message: "We are still processing this application",
        timeout: 5000,
    },
    {
        message: "This application is taking longer than usual to process, please wait",
        timeout: 0,
    },
];

export function ApplicationPageProcessing() {
    const [waitingState, setWaitingState] = useState(0);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (waitingStates[waitingState].timeout > 0) {
            timeout = setTimeout(() => {
                setWaitingState((prev) => prev + 1);
            }, waitingStates[waitingState].timeout);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [waitingState]);

    return (
        <div className="application-page-processing m-auto d-flex flex-column justify-content-center align-items-center">
            <div className="waiting-message mb-4">{waitingStates[waitingState].message}</div>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}
