import Form from "@rjsf/core";
import { Button } from "components/Button";
import { SubmitButton } from "components/Button/SubmitButton";
import { ErrorSummary } from "components/ErrorSummary";
import { HiddenSubmitButton, JsonForm } from "components/JsonForm";
import { submitByRefPromise } from "components/JsonForm/utils";
import { useRef, useState } from "react";
import { Offcanvas, Stack } from "react-bootstrap";
import { TaskProps } from "./types";
import { completeWorkflowTask } from "./utils";
import { RawHTML } from "components/RawHTML";
import { isNil } from "lodash";

export const CheckboxTask = ({ applicationNumber, task, onComplete, onClose }: TaskProps) => {
    const schema = {
        type: "object",
        required: ["certify"],
        properties: {
            certify: {
                type: "boolean",
                title: "I certify that this task is completed",
            },
        },
    };

    const uiSchema = {
        certify: {
            "ui:widget": "checkbox",
        },
    };

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<any>();
    const formRef = useRef<Form<any>>();

    const onSubmit = async () => {
        try {
            setIsSubmitting(true);

            // validate
            await submitByRefPromise(formRef);

            await completeWorkflowTask(applicationNumber, task);

            onComplete(task);
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas show placement="end" backdrop="false" onHide={onClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{task.stepType}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                <ErrorSummary errorSummary={errorSummary} />

                {!isNil(task.content) && <RawHTML className="pb-3">{task.content}</RawHTML>}

                <JsonForm formRef={formRef} schema={schema} uiSchema={uiSchema}>
                    <HiddenSubmitButton />
                </JsonForm>
                <Stack className="mt-auto justify-content-end" direction="horizontal">
                    <SubmitButton onClick={onSubmit} isSubmitting={isSubmitting} spinnerText="Submitting..." className="me-3">
                        Submit
                    </SubmitButton>
                    <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                </Stack>
            </Offcanvas.Body>
        </Offcanvas>
    );
};
