import { SectionName, Sections } from "./types";
import { useHistory, useLocation } from "react-router";
import { useCallback } from "react";
import useMediaQuery from "components/utils/useMediaQuery";

export const useSectionChange = () => {
    const history = useHistory();
    const location = useLocation();
    const isMobile = useMediaQuery("(max-width: 768px)");

    const activeSection =
        (new URLSearchParams(location.search).get("show") as SectionName) ?? (isMobile ? Sections.Correspondence : Sections.Messages);

    const setActiveSection = useCallback(
        (section: SectionName) => {
            // Update the URL to reflect the active section.
            // Get existing query params and add the "show" param with value of the active section
            const params = new URLSearchParams(location.search);
            params.set("show", section);
            history.push({
                pathname: location.pathname,
                search: params.toString(),
            });
        },
        [history, location.pathname, location.search]
    );

    return [activeSection, setActiveSection] as const;
};
