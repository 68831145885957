import Table from "react-bootstrap/Table";
import cn from "classnames";

import "./style.scss";

export const DataGrid = ({ className, children, withWrapper = true }: DataGridProps) => {
    const table = (
        <Table className={cn("data-grid position-relative", className)} bordered responsive>
            {children}
        </Table>
    );

    if (!withWrapper) {
        return table;
    }

    return <div className="data-grid-wrapper">{table}</div>;
};

interface DataGridProps {
    className?: string;
    children: React.ReactNode;
    withWrapper?: boolean;
}
