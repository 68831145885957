import { DataGrid } from "components/DataGrid";
import { DataGridColumnConfig, DataGridRowConfig } from "components/DataGrid/types";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { DataGridHeader } from "components/DataGrid/DataGridHeader";
import { DataGridFilter } from "components/DataGrid/DataGridFilter";
import { DataGridBody } from "components/DataGrid/DataGridBody";
import { getColumnSortState, getVisibleColumns } from "components/DataGrid/utils";
import { DataGridFilterChips } from "components/DataGrid/DataGridFilterChips";
import { Link } from "react-router-dom";
import { PageLink } from "components/App/utils";
import { RelatedApplicationListColumnKeys } from "./types";
import { Icon } from "components/Icon";
import { SubmitButton } from "components/Button/SubmitButton";
import { Button } from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { isNil } from "lodash";
import { Modal } from "react-bootstrap";
import { relateApplication } from "components/utils";
import { getErrorMessage } from "components/utils/http";
import { useToast } from "components/Toast";

export const RelatedApplicationsGrid = ({
    items,
    gridConfig,
    sortBy,
    sortAsc,
    onSortChange,
    onFilterChange,
    filter,
    isLoadingGrid,
    onExportData,
    isExporting,
    applicationNumber,
    setShowRelationsPanel,
    onRelationsUpdated,
    setPageNumber,
    pageNumber,
}: RelatedApplicationsGridProps) => {
    const [targetAppId, setTargetAppId] = useState<string | null>();
    const showMessageModal = !isNil(targetAppId);
    const columns = getVisibleColumns(gridConfig);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const toast = useToast();

    const removeRelation = async () => {
        let response;
        setIsSubmitting(true);
        try {
            if (targetAppId) {
                response = await relateApplication(applicationNumber, [targetAppId]);
            }
        } catch (error) {
            toast.error(getErrorMessage(error));
        } finally {
            setTargetAppId(null);
            setIsSubmitting(false);
            toast.success(response?.responseMessage);
            onRelationsUpdated();

            // Navigate to previous page if the last item was deleted
            if (items && items?.length === 1 && pageNumber > 1) {
                setPageNumber(pageNumber - 1);
            }
        }
    };

    const rows: DataGridRowConfig[] = (items || []).map((item) => ({
        data: item,
        actions: [
            {
                content: (row: DataGridRowConfig, actionIndex: number) => (
                    <div key={actionIndex} className="text-end d-flex align-items-center justify-content-start gap-2">
                        <Button
                            variant="link"
                            className="text-danger"
                            onClick={() => setTargetAppId(row.data[RelatedApplicationListColumnKeys.appId])}
                            title={"Remove Relation"}
                            aria-haspopup="dialog"
                        >
                            <FontAwesomeIcon icon={["fal", "xmark"]} style={{ fontSize: "1.5rem" }} />
                        </Button>
                        {item.hasAccess && (
                            <>
                                <div className="vr button-divider mx-2 h-50 align-self-center" />
                                <Link
                                    key={actionIndex}
                                    className="text-nowrap d-flex align-items-center p-2 ps-0 gap-2"
                                    to={`${PageLink.ApplicationView}?applicationNumber=${row.data[RelatedApplicationListColumnKeys.appId]}`}
                                    title="View Application"
                                >
                                    <Icon icon={["fal", "circle-arrow-right"]} style={{ fontSize: "1.5rem" }} />
                                </Link>
                            </>
                        )}
                    </div>
                ),
            },
        ],
    }));

    const formattedColumns: DataGridColumnConfig[] = columns.map((col) => ({
        ...col,
        sort: getColumnSortState(col, sortBy, sortAsc),
        name: col.name.split("_").join(" "),
    }));

    return (
        <>
            <DataGridFilterChips
                className="d-md-none"
                gridConfig={gridConfig}
                filter={filter}
                formTitle="Filter"
                onFilterChange={onFilterChange}
            >
                <div className="d-flex gap-1">
                    <Button title="Add Relations" variant="primary" onClick={() => setShowRelationsPanel(true)}>
                        <Icon className="px-1" icon={["fal", "sitemap"]} />
                    </Button>
                    <SubmitButton
                        title="Export CSV"
                        variant="secondary"
                        secondaryButtonType="filled"
                        onClick={onExportData}
                        isSubmitting={isExporting}
                        spinnerText="Exporting"
                    >
                        {!isExporting && <Icon className="px-1" icon={["fal", "file-export"]} />}
                    </SubmitButton>
                </div>
            </DataGridFilterChips>
            <DataGrid className="align-middle" withWrapper={false}>
                <DataGridHead>
                    <DataGridHeader columns={formattedColumns} onSortChange={onSortChange} />
                    <DataGridFilter columns={columns} filter={filter} onFilterChange={onFilterChange} />
                </DataGridHead>
                <DataGridBody
                    rows={rows}
                    columns={formattedColumns}
                    isLoadingData={isLoadingGrid}
                    nothingFoundMessage="No Results Found."
                />
            </DataGrid>
            <Modal show={showMessageModal} onHide={() => setTargetAppId(undefined)} aria-labelledby="remove-relation-title">
                <Modal.Header closeButton>
                    <Modal.Title id="remove-relation-title">Remove Relation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this application relation?</p>
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButton onClick={removeRelation} isSubmitting={isSubmitting} variant="primary" spinnerText="Is Removing...">
                        Yes
                    </SubmitButton>
                    <Button onClick={() => setTargetAppId(undefined)} variant="secondary">
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

interface RelatedApplicationsGridProps {
    gridConfig: any;
    items: any[] | undefined;
    sortBy: string;
    sortAsc: boolean;
    onSortChange: (key: string) => void;
    onFilterChange: (filter: string) => void;
    filter?: string;
    isLoadingGrid: boolean;
    onExportData: () => Promise<void>;
    isExporting: boolean;
    applicationNumber: string;
    setShowRelationsPanel: React.Dispatch<React.SetStateAction<boolean>>;
    onRelationsUpdated: () => void;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
    pageNumber: number;
}
