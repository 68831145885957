import cn from "classnames";
import { Components } from "components";
import useWidgetClasses from "components/utils/useWidgetClasses";
import useWidgetStyles from "components/utils/useWidgetStyles";
import { createContext, useContext } from "react";
import { Col as BootstrapCol } from "react-bootstrap";
import { RowContext } from "components/Layout/Row";

import "./style.scss";

const alignXClasses = {
    left: "justify-content-start",
    center: "justify-content-center",
    right: "justify-content-end",
};

const alignYClasses = {
    top: "align-items-start",
    center: "align-items-center",
    bottom: "align-items-end",
};

export const ColumnContext = createContext();

export function Col(props) {
    const { components = [], xs, md } = props;

    const style = useWidgetStyles(props);
    const className = useWidgetClasses({
        ...props,
        alignXClasses,
        alignYClasses,
    });

    const rowContext = useContext(RowContext);
    const rowContainsHeaderNavigationWidget = rowContext?.components
        .filter((component) => component?.type === "Col")
        .map((col) => col?.props?.components)
        .flat()
        .some((component) => component?.type === "HeaderNavigationWidget");
    const rowContainsImage = rowContext?.components
        .filter((component) => component?.type === "Col")
        .map((col) => col?.props?.components)
        .flat()
        .some((component) => component?.type === "Image");

    return (
        <ColumnContext.Provider value={props}>
            <BootstrapCol className="p-0 d-flex align-items-stretch" xs={xs} md={md}>
                <div
                    className={cn("mimic-bootstrap-gutters layout-column d-flex flex-grow-1", className, {
                        "row-contains-header-navigation-widget": rowContainsHeaderNavigationWidget,
                        "row-contains-image": rowContainsImage,
                    })}
                    style={style}
                >
                    {components.map((component, index) => {
                        const Component = Components[component.type];
                        return Component ? <Component key={index} {...component.props} /> : null;
                    })}
                </div>
            </BootstrapCol>
        </ColumnContext.Provider>
    );
}
