import { isObject, isString } from "lodash";
import { getUrl, httpDelete, httpGet, httpGetAuthorized, httpPost, httpPostAuthorized, httpPut, httpPutAuthorized } from "./utils/http";
import { useEffect, useState } from "react";
import { isInIframe } from "./utils/dom";
import { getUser, getUserNameFromToken, saveUser } from "./utils/user";
import { adjustBackgroundContrast, getColor } from "./utils/color";
import Color from "color";
import useSWR from "swr";

const EVENT_USER_AUTHENTICATED = "user-authenticated";

export const REMOVE_APP_KEY = {
    appIdKey: "c_bfbce9eb-03bb-42dc-b4af-46052cc0cfe1",
    appNumber: "c_051a4456-ab77-436f-8e17-81d137e0d3ec",
};

export const updateCssVariables = (variables = {}) => {
    if (!isObject(variables)) {
        return;
    }

    Object.keys(variables).forEach((key) => {
        const cssVariableKey = key.startsWith("--") ? key : "--" + key;

        if (variables[key]) {
            document.documentElement.style.setProperty(cssVariableKey, variables[key]);
        } else {
            document.documentElement.style.removeProperty(cssVariableKey);
        }
    });
};

export const updateColors = (colorConfig = {}) => {
    if (!isObject(colorConfig)) {
        return;
    }

    // set default colors and override if provided
    const colors = { primary: "#0d6efd", secondary: "#706b85", ...colorConfig };

    let cssVariablesToUpdate = {};

    Object.keys(colors).forEach((key) => {
        switch (key) {
            case "primary":
                cssVariablesToUpdate["--bs-primary"] = colors[key];
                cssVariablesToUpdate["--bs-primary-rgb"] = Color(colors[key]).color;
                cssVariablesToUpdate["--bs-primary-bg"] = adjustBackgroundContrast(colors[key], getColor(key, "20", colors));
                break;
            case "secondary":
                cssVariablesToUpdate["--bs-secondary"] = colors[key];
                cssVariablesToUpdate["--bs-secondary-rgb"] = Color(colors[key]).color;
                cssVariablesToUpdate["--bs-secondary-bg"] = adjustBackgroundContrast(colors[key], getColor(key, "20", colors));
                break;
            default:
                cssVariablesToUpdate[key] = colors[key];
                break;
        }
    });

    updateCssVariables(cssVariablesToUpdate);
};

// Can't use sessionStorage in iFrame if third party cookies are blocked on users browser, so using global variables as workaround in this case
export const setSessionStorageItem = (key, item) => {
    if (isInIframe()) {
        window[`${key}SessionStorage`] = item;
    } else {
        sessionStorage.setItem(key, item);
    }
};

export const getSessionStorageItem = (key) => {
    if (isInIframe()) {
        return window[`${key}SessionStorage`];
    } else {
        return sessionStorage.getItem(key);
    }
};

export const deleteSessionStorageItem = (key) => {
    if (isInIframe()) {
        window[`${key}SessionStorage`] = null;
    } else {
        return sessionStorage.removeItem(key);
    }
};

export async function changeAccountDetails(formData) {
    return httpPutAuthorized(process.env.REACT_APP_CUSTOMERS_ENDPOINT, formData);
}

export const deleteUser = async () => {
    try {
        const { accessToken, refreshToken } = getUser();
        // Do not await the response for faster logout experience.
        httpDelete(process.env.REACT_APP_CUSTOMER_LOGOUT_ENDPOINT, {
            accessToken,
            refreshToken,
        });
    } catch {
        // Do nothing
    }

    deleteSessionStorageItem("user");
    deleteSessionStorageItem("customerNumber");
    dispatchEventUserAuthenticated(false);
};

export function isUserAuthenticated() {
    const { accessToken, refreshToken } = getUser();
    return accessToken?.length > 0 && refreshToken?.length > 0;
}

export function dispatchEventUserAuthenticated(isAuthenticated) {
    var event = new CustomEvent(EVENT_USER_AUTHENTICATED, {
        detail: {
            isAuthenticated,
        },
    });

    // dispatch event in next event loop
    setTimeout(() => window.dispatchEvent(event), 0);
}

/**
 * Hook to get user authentication state.
 *
 * @returns {boolean} true if user is authenticated.
 */
export function useUserAuthenticated() {
    const [isAuthenticated, setIsAuthenticated] = useState(isUserAuthenticated());

    useEffect(() => {
        const onChange = (event) => {
            setIsAuthenticated(event?.detail?.isAuthenticated);
        };

        window.addEventListener(EVENT_USER_AUTHENTICATED, onChange, false);

        return () => {
            window.removeEventListener(EVENT_USER_AUTHENTICATED, onChange, false);
        };
    }, []);

    return isAuthenticated;
}

export async function customerLogin(formData) {
    const data = await httpPost(process.env.REACT_APP_CUSTOMER_LOGIN_ENDPOINT, formData, "customer_login");

    const { accessToken, refreshToken, firstName, lastName, serverTimezoneOffset } = data;
    const userName = getUserNameFromToken(accessToken);

    saveUser({
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: userName,
        serverTimezoneOffset,
        accessToken: accessToken,
        refreshToken: refreshToken,
    });
}

export async function customerTwoFactor(userName, programNumber) {
    return await httpGet(
        process.env.REACT_APP_CUSTOMER_TWO_FACTOR_ENDPOINT +
            "?" +
            new URLSearchParams({
                userName: userName,
                programNumber: programNumber,
            }),
        "customer_two_factor"
    );
}

export async function createAccount(formData) {
    const data = await httpPost(process.env.REACT_APP_CUSTOMER_CREATE_ENDPOINT, formData, "create_account");

    const { customerNumber } = data;

    saveUser({
        customerNumber: customerNumber,
    });
}

export async function verifyAuthCode(formData) {
    const data = await httpPut(process.env.REACT_APP_CUSTOMER_AUTHENTICATION_VERIFY_ENDPOINT, formData, "verify_auth_code");

    const { accessToken, refreshToken, firstName, lastName, serverTimezoneOffset } = data;
    const userName = getUserNameFromToken(accessToken);
    saveUser({
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: userName,
        serverTimezoneOffset,
        accessToken: accessToken,
        refreshToken: refreshToken,
    });
}

export async function userChangePassword(formData) {
    const { accessToken } = getUser();
    const { oldPassword, newPassword } = formData;
    const body = {
        accessToken,
        oldPassword,
        newPassword,
    };
    return httpPutAuthorized(process.env.REACT_APP_CUSTOMER_PASSWORD_UPDATE_ENDPOINT, body);
}

export async function userChangeEmail(formData) {
    const { email, password, programNumber } = formData;
    const body = {
        email,
        password,
        programNumber,
    };
    return httpPostAuthorized(process.env.REACT_APP_CUSTOMERS_REQUEST_AUTHCODE_EMAIL, body);
}

export async function userNewAuthCode(formData) {
    const { programNumber, pageNumber } = formData;
    const items = JSON.parse(localStorage.getItem("user"));
    const userName = items.email;
    const body = {
        userName,
        programNumber,
        pageNumber,
    };
    return httpPost(process.env.REACT_APP_CUSTOMER_AUTHENTICATION_ENDPOINT, body);
}

export async function userResetPassword(formData) {
    const { id, temporaryPassword, newPassword } = formData;
    const body = {
        id,
        temporaryPassword,
        newPassword,
    };

    const data = await httpPost(process.env.REACT_APP_CUSTOMER_RESET_PASSWORD_ENDPOINT, body);
    const { accessToken, refreshToken, firstName, lastName, serverTimezoneOffset } = data;
    const userName = getUserNameFromToken(accessToken);

    saveUser({
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: userName,
        accessToken: accessToken,
        refreshToken: refreshToken,
        serverTimezoneOffset,
    });
}

export async function userForgotPassword(formData) {
    return httpPost(process.env.REACT_APP_CUSTOMER_FORGOT_PASSWORD_ENDPOINT, formData);
}

export const usePasswordRequirements = () => {
    const url = getUrl(process.env.REACT_APP_CUSTOMER_PASSWORD_REQUIREMENTS_ENDPOINT);
    const { data, error } = useSWR(url, httpGet);
    const isLoading = !error && !data;

    return [data?.description, isLoading, error];
};

/**
 * Get recaptcha token.
 *
 * @export
 * @param {string | undefined} action - Any name for the action this token will be used for. Use underscore to separate words.
 * @returns {Promise|null} Promise that resolves to a token or null if recaptcha is disabled.
 */
export async function getRecaptchaToken(action) {
    const grecaptcha = window.grecaptcha;

    return new Promise((resolve, reject) => {
        if (process.env.REACT_APP_RECAPTCHA_ENABLED === "true") {
            grecaptcha.ready(function () {
                grecaptcha
                    .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action })
                    .then(function (token) {
                        resolve(token);
                    })
                    .catch(function (reason) {
                        reject(reason);
                    });
            });
        } else {
            resolve(null);
        }
    });
}

/**
 * Create new application.
 *
 * @param {string} applicationNumber - Application number.
 * @param {string} pageNumber - Page number.
 * @returns {Promise}
 */
export async function getFormData(applicationNumber, pageNumber) {
    const url = getUrl(process.env.REACT_APP_APPLICATION_PAGE_FORM_ENDPOINT, { applicationNumber, pageNumber });
    return httpGetAuthorized(url);
}

export const replaceHttpLinksToHttps = (html) => {
    if (!isString(html)) {
        return html;
    }

    return html.replace(/src="http:\/\//gi, 'src="https://').replace(/src='http:\/\//gi, "src='https://");
};

export async function downloadFile(fileId) {
    const url = getUrl(process.env.REACT_APP_APPLICATION_FILE_DOWNLOAD, { fileId });
    return httpGetAuthorized(url);
}

export async function downloadFileFolder(appId, folder) {
    const query = new URLSearchParams();
    query.append("folder", folder);
    query.append("appId", appId);
    const url = getUrl(process.env.REACT_APP_APPLICATION_FILE_DOWNLOAD_FOLDER);
    return httpGetAuthorized(`${url}?${query}`);
}

export async function postCreatePdf({ content, fileName }) {
    return httpPostAuthorized(process.env.REACT_APP_PDF_ENDPOINT, { content, fileName });
}

export async function submitNote(note, appId) {
    const url = getUrl(process.env.REACT_APP_APPLICATION_NOTE_CREATE, { appId });
    return httpPostAuthorized(url, note);
}

/**
 * Takes in a string and searches for dynamically created tables with ckeditor
 * that were inserted into the string by the user. If these tables exist,
 * adds a data-label attribute to the <td> cells so that the tables are
 * displayed properly on mobile devices.
 *
 * @param {string} description - Description that may contain dynamic table data
 * @returns {string} - Unchanged string, or formatted description with data-label attributes in <td> cells
 */
export const massageTableData = (description) => {
    if (!description) return description;

    const parser = new DOMParser();
    const parsed = parser.parseFromString(description, "text/html");
    const tables = parsed.querySelectorAll("table");

    // If no user tables were found, return the property unchanged
    if (tables.length === 0) return description;

    // Loop through all tables and add data-label attributes to cells
    tables.forEach((table) => {
        // Add bootstrap classes to tables in case they are not already there
        table.classList.add("table");
        table.classList.add("table-bordered");

        // wrap tables with the Bootstrap .table-responsive div
        const tableParent = table.parentElement;
        const tableResponsive = document.createElement("div");
        tableResponsive.classList.add("table-responsive");
        tableParent.insertBefore(tableResponsive, table);
        tableResponsive.appendChild(table);
    });
    description = parsed.body.innerHTML;
    return description;
};

export const getValidFileName = ({ fileName, fileExtension, spaceDelimeter }) => {
    let name = (fileName || "file").trim();

    if (!fileName && !fileExtension) {
        name = name + ".txt";
    } else if (fileExtension) {
        name = name + "." + fileExtension.trim();
    }

    name = name.replace(/(["'])/g, "");

    return spaceDelimeter ? name.replace(/ /g, spaceDelimeter) : name;
};

/**
 * Get submitted form data
 *
 * @param {string} applicationNumber - Application number.
 * @returns {Promise}
 */
export async function getSubmittedAppDetails(applicationNumber) {
    const url = getUrl(process.env.REACT_APP_APPLICATION_DETAILS_ENDPOINT, { applicationNumber });
    return httpGetAuthorized(url);
}

export async function updateAppContact(applicationNumber, contactNumber, body) {
    const url = getUrl(process.env.REACT_APP_APPLICATION_UPDATE_CONTACT_ENDPOINT, { applicationNumber, contactNumber });
    return httpPutAuthorized(url, body);
}

export async function createAppContact(applicationNumber, body) {
    const url = getUrl(process.env.REACT_APP_APPLICATION_CONTACTS_ENDPOINT, { applicationNumber });
    return httpPostAuthorized(url, body);
}

export async function toggleAppFavorite(applicationNumber) {
    const query = new URLSearchParams();
    query.append("entityType", "1219");
    query.append("entityId", applicationNumber);
    const url = getUrl(process.env.REACT_APP_APPLICATION_FAVORITE_ENDPOINT, { applicationNumber });
    return httpPutAuthorized(`${url}?${query}`, undefined);
}

/**
 * Relates an application to a list of target applications.
 *
 * @param {string} applicationNumber - Application to relate.
 * @param {string[]} targetApplications - The list of target applications to relate to.
 * @return {Promise<any>}
 */
export async function relateApplication(applicationNumber, targetApplications) {
    const url = getUrl(process.env.REACT_APP_APPLICATION_RELATIONS_ENDPOINT, { applicationNumber });
    return httpPutAuthorized(url, targetApplications);
}
