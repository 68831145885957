import { useEffect, useState } from "react";
import { Heading } from "components/Heading";
import { Container } from "components/Layout/Container";
import { useCustomerContactsV2 } from "components/utils/useCustomerContacts";
import { Modal, Offcanvas, Spinner } from "react-bootstrap";
import { Button } from "components/Button";
import { SubmitButton } from "components/Button/SubmitButton";
import { ErrorSummary } from "components/ErrorSummary";
import { ContactCard } from "components/ContactCard";
import { deleteCustomerContact } from "components/utils/contacts";
import { DataGridPaging } from "components/DataGrid/DataGridPaging";
import { useToast } from "components/Toast";
import cn from "classnames";
import { Icon } from "components/Icon";
import useMediaQuery from "components/utils/useMediaQuery";
import { ContactEdit } from "components/ContactCard/ContactEdit";

export function ContactsPage() {
    const requiredFields = ["contactTitle", "firstname", "lastname", "company", "address", "city", "zip", "email", "contacttype"];
    const requirements = { requiredFields: requiredFields.map((field) => ({ field })), hiddenFields: [] };
    const [errorSummary, setErrorSummary] = useState<any>();
    const [errorSummaryForList, setErrorSummaryForList] = useState();
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const [contacts, isLoading, error, totalRecords, pagesCount, refreshCustomerContacts] = useCustomerContactsV2(activePage, pageSize);
    const [showContactSidebar, setShowContactSidebar] = useState(false);
    const toast = useToast();
    const isMobile = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        setErrorSummaryForList(error);
    }, [error]);

    const deleteContact = async (contactNumber: string) => {
        try {
            const response = await deleteCustomerContact(contactNumber);
            toast.success(response.responseMessage);
        } catch (error: any) {
            setErrorSummary(error);
        } finally {
            setContactToDelete(null);
            setIsDeleting(false);
        }

        if (contacts && contacts.length === 1) {
            setActivePage(activePage > 1 ? activePage - 1 : 1);
        }

        refreshCustomerContacts();
    };

    const onHideSidebar = () => {
        setShowContactSidebar(false);
    };

    const onAddContact = () => {
        setShowContactSidebar(true);
        setErrorSummary(null);
    };

    const [contactToDelete, setContactToDelete] = useState<string | null>();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    return (
        <>
            <Offcanvas show={showContactSidebar} placement="end" onHide={onHideSidebar} aria-labelledby="add-contact-sidebar-title">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="add-contact-sidebar-title" as="h4">
                        Add Contact
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <ErrorSummary errorSummary={errorSummary} />
                <Offcanvas.Body>
                    <ContactEdit
                        newContact
                        isCustomerContact
                        requirements={requirements}
                        hideSaveContactSection
                        onClose={onHideSidebar}
                        onSaved={refreshCustomerContacts}
                    />
                </Offcanvas.Body>
            </Offcanvas>
            <Modal
                contentClassName="bg-white"
                show={!!contactToDelete}
                onHide={() => setContactToDelete(null)}
                aria-labelledby="delete-contact-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="delete-contact-title">Delete Contact</Modal.Title>
                </Modal.Header>
                <hr className="m-0" />
                <Modal.Body className="p-3">
                    <p className="m-0">Are you sure you want to delete this contact?</p>
                </Modal.Body>
                <hr className="m-0" />
                <Modal.Footer>
                    <SubmitButton
                        spinnerText="Deleting..."
                        isSubmitting={isDeleting}
                        onClick={() => {
                            setIsDeleting(true);
                            deleteContact(contactToDelete ?? "");
                        }}
                    >
                        Yes
                    </SubmitButton>
                    <Button
                        onClick={() => {
                            setContactToDelete(null);
                        }}
                        variant="secondary"
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container className="p-0">
                <div className={cn("d-flex justify-content-between gap-3 pb-3", { "flex-column": isMobile })}>
                    <Heading>Contacts</Heading>
                    <Button
                        variant="primary"
                        className="d-flex align-items-center gap-2 px-3p5 py-2 justify-content-center"
                        onClick={onAddContact}
                    >
                        <Icon icon={["fal", "square-plus"]} />
                        Add Contact
                    </Button>
                </div>
                <ErrorSummary errorSummary={errorSummaryForList} />
                {isLoading && (
                    <div className="w-100 h-100 d-flex">
                        <Spinner className="m-auto" animation="border" role="status">
                            <span className="visually-hidden">Loading contacts...</span>
                        </Spinner>
                    </div>
                )}
                <div className="customer-contact-list d-grid gap-4">
                    {!isLoading &&
                        contacts?.map((c) => (
                            <ContactCard
                                key={c.contactNumber}
                                contact={c}
                                onDeleteContact={(contactNumber) => {
                                    setContactToDelete(contactNumber);
                                }}
                                isCustomerContact
                                isEditable
                                hideSaveContactSection
                                requirements={{ requiredFields: requiredFields.map((field) => ({ field })), hiddenFields: [] }}
                                onContactChanged={refreshCustomerContacts}
                            />
                        ))}
                </div>
                <div className={cn({ "d-flex justify-content-center": isMobile })}>
                    <DataGridPaging
                        pageSize={pageSize}
                        onPageSizeChange={setPageSize}
                        pageNumber={activePage}
                        pagesCount={pagesCount}
                        totalRecords={totalRecords}
                        isLoading={isLoading}
                        onPageChange={(page) => setActivePage(page)}
                    />
                </div>
            </Container>
        </>
    );
}
