import { useCallback, useContext, useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { AppContext } from "components/App/AppContext";
import { Button } from "components/Button";
import { Container } from "components/Layout/Container";
import { isInIframe } from "components/utils/dom";
import { COOKIE_COOKIES_PREFERENCES_SET, getCookie, setCookiesPolicyCookie, setCookiesPreferencesSetCookie } from "../utils/cookies";
import { ExternalLink } from "components/ExternalLink";
import { PageLink } from "components/App/utils";

import "./CookiesBanner.scss";

export function CookiesBanner() {
    const [showBanner, setShowBanner] = useState(false);
    const location = useLocation();
    const { settings = {} } = useContext(AppContext);
    const enableCookiesBanner = settings?.enableCookiesBanner ?? true;
    const cookiesBannerOverlay = settings?.cookiesBannerOverlay ?? true;
    const position = settings?.cookiesBannerPosition ?? "bottom";
    const text = settings?.cookiesBannerText ?? "This site uses cookies to learn about you and enhance your experience.";
    const linkText = "Read more";
    const buttonText = "Got it!";
    const enableOpenInNewTab = settings?.cookiesBannerLinkOpenInNewTab ?? false;
    const cookiesPageLink = settings?.cookiesPageLink ?? "internal";
    const cookiesPageExternalLink = settings?.cookiesBannerLinkUrl;
    const enableCookiesBannerLink = settings?.enableCookiesBannerLink ?? true;

    useLayoutEffect(() => {
        const cookie = getCookie(COOKIE_COOKIES_PREFERENCES_SET);

        if (isInIframe()) {
            setShowBanner(location.pathname !== PageLink.Cookies && enableCookiesBanner);
        } else {
            setShowBanner(cookie !== "true" && location.pathname !== PageLink.Cookies && enableCookiesBanner);
        }
    }, [location.pathname, enableCookiesBanner]);

    const onAcceptAll = useCallback((event) => {
        event.preventDefault();
        setCookiesPreferencesSetCookie();
        setCookiesPolicyCookie(true);
        setShowBanner(false);
    }, []);

    if (!showBanner) {
        return null;
    }

    const readMoreLinkClassName = "d-block mt-2 mt-md-0 text-white";

    return (
        <div
            className={cn("cookies-banner", "cookies-banner--" + position, { "cookies-banner--overlay": cookiesBannerOverlay })}
            role="region"
            aria-label="cookies banner"
        >
            <Container className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-start">
                <div className="cookies-content">
                    <p className="mb-0 lh-sm lh-md-base">{text}</p>
                    {enableCookiesBannerLink && (
                        <>
                            {cookiesPageLink === "internal" && (
                                <Link className={readMoreLinkClassName} to={PageLink.Cookies}>
                                    {linkText}
                                </Link>
                            )}
                            {cookiesPageLink === "external" && (
                                <ExternalLink
                                    className={readMoreLinkClassName}
                                    href={cookiesPageExternalLink}
                                    openInNewTab={enableOpenInNewTab}
                                >
                                    {linkText}
                                </ExternalLink>
                            )}
                        </>
                    )}
                </div>
                <Button className="px-5 mt-3 mt-md-2 ms-md-5 text-nowrap" onClick={onAcceptAll}>
                    {buttonText}
                </Button>
            </Container>
        </div>
    );
}
