import Form from "@rjsf/core";
import { ContactData } from "components/utils/contacts";
import type { JSONSchema7 } from "json-schema";
import { MutableRefObject } from "react";
import { ContactRequirements } from "types/ContactRequirements";

export enum ContactTypeCode {
    Contractor = 19,
    Primary = 17,
    Premise = 26,
}

export enum AdditionalContactType {
    Contractor = "Contractor",
    Stored = "Stored",
}

export interface ContactInboundData {
    contactType: string;
    contactNumber: string;
    contactTitle?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    address?: string;
    address_cont?: string;
    city?: string;
    state?: string;
    zip?: string;
    shortZip?: string;
    phone?: string;
    cell?: string;
    fax?: string;
    email?: string;
    accountNumber?: string;
    taxId?: string;
    premiseId?: string;
    meterid?: string;
    workflowTargetGroupId?: number;
    workflowTargetGroupName?: string;
    origId?: string;
}

export interface ContractorsResponse {
    totalRecords: number;
    remainingRecords: number;
    items: ContractorsResponseItem[];
}

export interface ContractorsResponseItemBase {
    contactNumber: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone?: string | null;
    email?: string | null;
    technologies?: string;
    services?: string;
    url?: string | null;
    description?: string | null;
    fullName?: string;
    fullAddress?: string;
}

export interface ContractorsResponseItemData extends ContractorsResponseItemBase {
    lat?: string | number;
    lng?: string | number;
}

export interface ContractorsResponseItem extends ContractorsResponseItemBase {
    lat?: number | undefined | null;
    lng?: number | undefined | null;
    href?: string | null;
}

export interface ContactFormProps {
    className?: string;
    formRefObject?: any;
    formRef: any;
    formData?: any;
    idPrefix: string;
    title?: string;
    showTypeSelect?: boolean;
    isAdditionalContact?: boolean;
    applicationNumber?: string;
    description?: string;
    extraErrors: any;
    requiredFields: string[];
    hiddenFields: string[];
    hide?: boolean;
    readonly?: boolean;
    requirements?: ContactRequirements | any;
    copyContactUiSchema?: any;
    onChange?: (form: any) => void;
    onClearForm?: () => void;
    onSaveClick?: () => void;
    onUseCustomerContact?: (contact: ContactInboundData | undefined) => void;
    isContactsView?: boolean;
    isEditMode?: boolean;
    setContactType?: (type: AdditionalContactType) => void;
}

export interface ContactFormData {
    origId?: string;
    storedContactNumber?: string;
    section: {
        column1: {
            contactType?: string;
            contactTitle?: string;
            firstName?: string;
            lastName?: string;
            company?: string;
            premiseId?: string;
            meterId?: string;
            acct_number?: string;
        };
        column2: {
            address?: string;
            address_cont?: string;
            city?: string;
            state?: string;
            zip?: string;
            phone?: string;
            cell?: string;
            fax?: string;
            email?: string;
            taxId?: string;
        };
    };
}

export interface ContractorFormData {
    section: {
        column1: {
            contactTitle?: string;
            company?: string;
            firstName?: string;
            lastName?: string;
            technologies?: string;
            services?: string;
            premiseId?: string;
            meterId?: string;
            acct_number?: string;
        };
        column2: {
            address?: string;
            address_cont?: string;
            city?: string;
            state?: string;
            zip?: string;
            phone?: string;
            cell?: string;
            fax?: string;
            email?: string;
            taxId?: string;
        };
    };
    contactNumber?: string;
    origId?: string;
}
export interface PremiseContactFormProps {
    formRef: any;
    formData?: ContactFormData;
    extraErrors: any;
    requirements: ContactRequirements;
    noTitle?: boolean;
    isAppSubmitted?: boolean;
    readonly?: boolean;
    onChange?: (form: any) => void;
    onSaveClick?: () => void;
    onUseCustomerContact?: (contact: ContactInboundData | undefined) => void;
}

export interface PrimaryContactFormProps extends PremiseContactFormProps {
    onCopyContact?: () => void;
}
export interface ContractorContactFormProps extends PremiseContactFormProps {
    applicationNumber: string;
    formData?: any;
    isAdditionalContact?: boolean;
    title?: string;
    setContactType?: (type: AdditionalContactType) => void;
}

export interface FormState {
    formData: any;
    schema: JSONSchema7;
}

export type ContactValidationResult = {} | undefined;

export interface ApplicationContactsWidgetValidationResult {
    primaryContactErrors?: ContactValidationResult;
    premiseContactErrors?: ContactValidationResult;
    contractorContactErrors?: ContactValidationResult;
}

export interface ApplicationContactsWidgetRef {
    validate: () => ApplicationContactsWidgetValidationResult;
    validateDataFormat: () => ApplicationContactsWidgetValidationResult;
    fieldNumber: string;
    getSubmitValues: () => ContactData[];
}

export interface ApplicationContactsWidgetValidationParams {
    primaryContactFormRef?: MutableRefObject<Form<any> | undefined>;
    premiseContactFormRef?: MutableRefObject<Form<any> | undefined>;
    contractorContactFormRef?: MutableRefObject<Form<any> | undefined>;
    requirements: ContactRequirements;
    isAppSubmitted?: boolean;
    contractorLabel: string;
}
