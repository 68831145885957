import Form from "react-bootstrap/Form";
import { Button } from "components/Button";
import { getUrl, httpGetAuthorized, httpPostAuthorized } from "components/utils/http";
import { useState } from "react";
import { Offcanvas, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaskProps } from "components/WorkflowTasks/types";
import { SubmitButton } from "components/Button/SubmitButton";
import useSWR from "swr";

export const FileUploadTask = ({ applicationNumber, task, onComplete, onClose, isTaskCompleted }: TaskProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const url = getUrl(process.env.REACT_APP_APPLICATION_FILE_SUPPORTED_TYPES, { entityTypeId: "71" });
    const { data, error } = useSWR(url, httpGetAuthorized);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const body = new FormData(e.currentTarget);

            if (e.currentTarget.file.files.length < 1) {
                setIsError(true);
                return;
            }

            const query = new URLSearchParams();
            query.append("entityId", applicationNumber);
            query.append("taskNumber", task.wfTaskNumber);

            setIsSubmitting(true);

            await httpPostAuthorized(`${process.env.REACT_APP_APPLICATION_FILE_UPLOAD}?${query.toString()}`, body);
            onComplete(task);
        } catch (e) {
            setIsError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas show placement="end" onHide={onClose} aria-labelledby="task-title">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-4" id="task-title">
                    {isTaskCompleted ? "Done - My completed task" : "My to-do task"}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                {isTaskCompleted && (
                    <div className="d-flex flex-column px-2">
                        <p className="d-flex m-0 py-2 fw-medium align-items-center">
                            <FontAwesomeIcon aria-label="done" className="pe-2" icon={["fal", "circle-check"]} size="lg" color="green" />
                            Upload file
                        </p>
                        <div className="d-flex flex-column ps-4">
                            <p className="m-0 text-break fw-medium ps-1">{task.step}</p>
                            {task.content && <p className="m-0 text-break pt-2 ps-1">{task.content}</p>}
                        </div>
                    </div>
                )}
                {!isTaskCompleted && (
                    <>
                        <div className="d-flex flex-column gap-2 pb-3">
                            <p className="m-0 fw-medium pb-2">Upload file</p>
                            <p className="m-0 fw-medium text-break">{task.step}</p>
                        </div>
                        {!error && data && (
                            <div className="text-break">
                                <p>
                                    <b>Supported formats: </b>
                                    {data.supportedFileTypes}
                                </p>
                                <p>
                                    <b>Maximum upload file size: </b>
                                    {data.fileSizeLimit}
                                </p>
                            </div>
                        )}
                        <Form className="d-flex flex-column" noValidate onSubmit={onSubmit}>
                            <Form.Control
                                className="bg-white"
                                type="file"
                                name="file"
                                aria-label="File"
                                aria-invalid={isError}
                                disabled={isSubmitting}
                                isInvalid={isError}
                                required
                                onChange={() => {
                                    setIsError(false);
                                }}
                            />
                            <Form.Control.Feedback type="invalid" id="file-error">
                                Please choose a file to submit
                            </Form.Control.Feedback>
                            <Stack className="justify-content-start gap-2 pt-4" direction="horizontal">
                                <SubmitButton spinnerText="Submitting..." type="submit" variant="primary" isSubmitting={isSubmitting}>
                                    Submit file
                                </SubmitButton>
                                <Button secondaryButtonType="filled" variant="secondary" onClick={onClose} disabled={isSubmitting}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Form>
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};
