import ReactDOM from "react-dom";
import { ContractorCard } from "./ContractorCard";
import CustomMarkerSelected from "./assets/custom-marker-selected.png";
import CustomMarker from "./assets/custom-marker.png";
import { ContractorsResponse, ContractorsResponseItem } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/types";
import { MarkerProps, UserCoordinates } from "./types";
import { isNil, isNumber, mean } from "lodash";

const getCoordinates = (contractor: ContractorsResponseItem) => {
    const { lat, lng } = contractor;
    if (!isNil(lat) && !isNil(lng)) {
        return { lat, lng };
    } else {
        return undefined;
    }
};

export const filterTitles: { [key: string]: string } = {
    zip: "Postal Code",
    fullAddress: "Address",
};

export const gatherMarkers = (contractorData: ContractorsResponse): MarkerProps[] => {
    if (contractorData) {
        const newMarkers = contractorData.items.map((contractor) => {
            const coordinates = getCoordinates(contractor);
            const { lat, lng } = coordinates ?? {};
            return {
                key: contractor.contactNumber ?? undefined,
                contractor,
                lat,
                lng,
            };
        });
        return newMarkers;
    } else {
        return [];
    }
};

export const hasValidCoordinates = (m: MarkerProps) => {
    return !isNil(m.lat) && !isNil(m.lng) && !isNaN(m.lat) && !isNaN(m.lng) && isNumber(m.lat) && isNumber(m.lng);
};

export const getCenter = (markers: MarkerProps[], userCoordinates: UserCoordinates | null) => {
    if (userCoordinates) return { lat: userCoordinates.latitude, lng: userCoordinates.longitude };
    if (!markers || !markers.length) return { lat: 0, lng: 0 };
    const latMean = mean(markers.filter((m) => hasValidCoordinates(m)).map((m) => m.lat));
    const lngMean = mean(markers.filter((m) => hasValidCoordinates(m)).map((m) => m.lng));
    return {
        lat: !isNaN(latMean) ? latMean : 0,
        lng: !isNaN(lngMean) ? lngMean : 0,
    };
};

export const openInfoWindow = (
    marker: google.maps.Marker,
    contractor: ContractorsResponseItem,
    mapRef?: google.maps.Map,
    openedInfoWindowRef?: React.MutableRefObject<google.maps.InfoWindow | null>,
    contractorsWithMarkersRef?: MarkerProps[]
) => {
    const { lat, lng } = contractor;
    contractorsWithMarkersRef?.forEach((m) => {
        m.marker?.setIcon(CustomMarker);
    });
    if (!isNil(lat) && !isNil(lng)) {
        mapRef?.setCenter({ lat, lng });
    }
    mapRef?.setZoom(15);
    marker.setIcon(CustomMarkerSelected);
    marker.setZIndex(100);
    let infoWindowContent = document.createElement("div");
    if (openedInfoWindowRef?.current) {
        openedInfoWindowRef.current.close();
        openedInfoWindowRef.current = null;
    }

    ReactDOM.render(
        <div className="marker-popup">
            <ContractorCard isInfoWindow c={contractor} />
        </div>,
        infoWindowContent
    );
    const infowindow = new google.maps.InfoWindow({
        content: infoWindowContent,
        ariaLabel: `${contractor.company} card`,
    });
    infowindow.addListener("closeclick", () => {
        marker.setIcon(CustomMarker);
    });

    infowindow.setContent(infoWindowContent);
    infowindow.setOptions({
        pixelOffset: new google.maps.Size(0, -60),
    });
    infowindow.setPosition(marker.getPosition());
    infowindow.open(mapRef);
    if (openedInfoWindowRef) {
        openedInfoWindowRef.current = infowindow;
    }
};

export const MAP_STYLES = [
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#e9e9e9",
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
            {
                color: "#d6dde9",
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                lightness: 17,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                lightness: 29,
            },
            {
                weight: 0.2,
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                lightness: 18,
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#f5f5f5",
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
            {
                color: "#eaedf3",
            },
            {
                lightness: 21,
            },
        ],
    },
    {
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "on",
            },
            {
                color: "#ffffff",
            },
            {
                lightness: 16,
            },
        ],
    },
    {
        elementType: "labels.text.fill",
        stylers: [
            {
                saturation: 36,
            },
            {
                color: "#333333",
            },
            {
                lightness: 40,
            },
        ],
    },
    {
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
            {
                color: "#f2f2f2",
            },
            {
                lightness: 19,
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#fefefe",
            },
            {
                lightness: 20,
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: "#fefefe",
            },
            {
                lightness: 17,
            },
            {
                weight: 1.2,
            },
        ],
    },
];

export const CENTER_USA = {
    lat: 39.8097343,
    lng: -98.5556199,
};
