import { Avatar } from "components/Avatar";
import { ApplicationContact, getHiddenFieldsByContactType } from "components/utils/contacts";
import { Offcanvas } from "react-bootstrap";
import { getAvatarColor, getAvatarText, getContactName, getContactTypeByCode, EMPTY_VALUE } from "./utils";
import { capitalize, isEmpty } from "lodash";
import { IconWithText } from "./IconWithText";
import { formatPhoneNumber } from "components/utils/format";
import { ContactRequirements } from "types/ContactRequirements";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { useStates } from "components/utils/useStates";
import { ContactSubTitle } from "./ContactSubTitle";
import { ContactTitle } from "./ContactTitle";

export const ContactDetails = ({
    contact,
    isAdditionalContact,
    hideAcctNumberOnPortal,
    requirements,
    onClose,
}: {
    contact: ApplicationContact;
    isAdditionalContact?: boolean;
    hideAcctNumberOnPortal?: boolean;
    requirements: ContactRequirements;
    onClose: () => void;
}) => {
    const contactType = getContactTypeByCode(Number(contact.contactType));
    const hiddenFields = getHiddenFieldsByContactType(requirements.hiddenFields, contactType);

    const avatarColor = getAvatarColor(contact);
    const avatarText = getAvatarText(contact);

    const contactTitle = getContactName(contact);
    const contactSubTitle = isAdditionalContact ? contact.contactTitle ?? EMPTY_VALUE : capitalize(contactType) + " Contact";

    const contactEmail = contact.email ? <a href={`mailto:${contact.email}`}>{contact.email}</a> : EMPTY_VALUE;

    const [states = []] = useStates();
    const state = states.find((s) => s.abbreviation === contact.state)?.state ?? contact.state;
    const contactState = contact.state === "DC" ? state : state?.split(" ").map(capitalize).join(" ");

    return (
        <Offcanvas className="contact-details-sidebar" show={true} placement="end" onHide={onClose} aria-labelledby="contact-details-title">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="contact-details-title" className="fs-4">
                    Contact Information
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center align-self-stretch gap-2 lh-base">
                    <Avatar color={avatarColor} text={avatarText} />
                    <div className="d-flex flex-column">
                        <ContactSubTitle>{contactSubTitle}</ContactSubTitle>
                        <ContactTitle>{contactTitle}</ContactTitle>
                    </div>
                </div>

                <hr className="my-0"></hr>

                <dl className="d-flex flex-column m-0 gap-3">
                    {!hiddenFields.includes("type") && <ListItem icon={"tag"} label="Type" value={contactSubTitle} />}
                    {!hiddenFields.includes("company") && (
                        <ListItem icon={"buildings"} label="Company" value={contact.company || EMPTY_VALUE} />
                    )}
                    {!hiddenFields.includes("email") && <ListItem icon={"envelope"} label="Email" value={contactEmail} />}
                    {!hiddenFields.includes("phone") && <ListItem icon={"phone"} label="Phone" value={getContactPhoneData(contact)} />}
                </dl>

                <hr className="my-0"></hr>

                <dl className="d-flex flex-column m-0 pt-2 gap-3">
                    {!hiddenFields.includes("address") && <ListItem label="Address" value={contact.address || EMPTY_VALUE} />}
                    {!hiddenFields.includes("address_cont") && (
                        <ListItem label="Address (cont)" value={contact.addressCont || EMPTY_VALUE} />
                    )}
                    {!hiddenFields.includes("city") && <ListItem label="City" value={contact.city || EMPTY_VALUE} />}
                    {!hiddenFields.includes("state") && <ListItem label="State/Province" value={contactState || EMPTY_VALUE} />}
                    {!hiddenFields.includes("zip") && <ListItem label="Postal code" value={contact.zip || EMPTY_VALUE} />}
                    {!hiddenFields.includes("fax") && <ListItem label="Fax" value={contact.fax || EMPTY_VALUE} />}
                </dl>

                <hr className="my-0"></hr>

                <dl className="d-flex flex-column m-0 pt-2 gap-3">
                    {!hiddenFields.includes("acct_number") && !hideAcctNumberOnPortal && (
                        <ListItem label="Account number" value={contact.accountNumber || EMPTY_VALUE} />
                    )}
                    {!hiddenFields.includes("taxid") && <ListItem label="Tax ID" value={contact.taxId || EMPTY_VALUE} />}
                    {!hiddenFields.includes("premiseid") && <ListItem label="Premise ID" value={contact.premiseId || EMPTY_VALUE} />}
                    {!hiddenFields.includes("meterid") && <ListItem label="Meter ID" value={contact.meterId || EMPTY_VALUE} />}
                </dl>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

const ListItem = ({ icon, label, value }: { icon?: IconName; label: string; value: string | React.ReactNode }) => {
    return (
        <div className="row my-0">
            <dt className="col-5">
                {icon ? (
                    <IconWithText icon={icon}>
                        <span className="text-body fw-normal">{label}</span>
                    </IconWithText>
                ) : (
                    <span className="text-body fw-normal">{label}</span>
                )}
            </dt>
            <dd className="col-7 text-break m-0">{value}</dd>
        </div>
    );
};

const getContactPhoneData = (contact: ApplicationContact) => {
    const contactPhone = !isEmpty(contact.phone) ? <a href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</a> : EMPTY_VALUE;
    const contactCell = !isEmpty(contact.cell) ? <a href={`tel:${contact.cell}`}>{formatPhoneNumber(contact.cell)}</a> : EMPTY_VALUE;

    const cellPart =
        contactCell !== EMPTY_VALUE ? (
            <p className="m-0">
                <span className="small">Cell</span>
                <br />
                {contactCell}
            </p>
        ) : null;

    const phonePart =
        contactPhone !== EMPTY_VALUE ? (
            <p className="m-0">
                <span className="small">Phone</span>
                <br />
                {contactPhone}
            </p>
        ) : null;

    if (cellPart || phonePart) {
        return (
            <div className="d-flex flex-column gap-3">
                {cellPart}
                {phonePart}
            </div>
        );
    }

    return EMPTY_VALUE;
};
