import { Icon } from "components/Icon";
import { isNil, isNumber, range, times } from "lodash";
import { Form, Pagination, Spinner } from "react-bootstrap";
import { Button } from "components/Button";
import { useState } from "react";
import classNames from "classnames";
import useMediaQuery from "components/utils/useMediaQuery";

import "./DataGridPaging.scss";

export const DataGridPaging = (props: DataGridPagingProps) => {
    const { pageNumber, pageSize, pagesCount, isLoading, totalRecords = 0, onPageChange, onPageSizeChange } = props;
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [jumpPage, setJumpPage] = useState<number>();
    const existingPages = range(1, pagesCount + 1);

    if (totalRecords < 10) {
        return null;
    }

    const activePageTitle = (title: number) =>
        isLoading ? (
            <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        ) : (
            title
        );

    const pageOffset = isMobile ? 1 : 3;
    const shownNumberOfPages = isMobile ? 3 : 5;

    const firstPageNumber = pageNumber - pageOffset < 1 ? 1 : pageNumber - pageOffset;
    const showPrevNext = pagesCount > shownNumberOfPages;
    const isActivePage = (page: number) => page === pageNumber;

    const pageItemsCount =
        pagesCount < shownNumberOfPages
            ? pagesCount
            : firstPageNumber + shownNumberOfPages > pagesCount
            ? pagesCount - firstPageNumber + 1
            : shownNumberOfPages;

    const pageDoesntExist = !isNil(jumpPage) && !existingPages.find((ep) => ep === jumpPage);

    return (
        <div
            className={classNames("data-grid-paging d-flex flex-row align-items-center flex-shrink-0 mt-3 mt-md-0", {
                "gap-2": !isMobile,
                "mx-auto": isMobile,
            })}
        >
            <div className="d-flex flex-column mt-3 gap-2">
                <div
                    className={classNames("d-flex align-items-center justify-content-center mb-auto", {
                        "flex-column": isMobile,
                        "gap-3": !isMobile,
                    })}
                >
                    <span
                        className={classNames("text-nowrap", {
                            "mb-2 fs-5": isMobile,
                        })}
                    >
                        Total {totalRecords} Items
                    </span>
                    <nav role="navigation" aria-label="Pagination Navigation" className="my-auto">
                        <Pagination {...(isMobile ? { size: "lg" } : {})} className={classNames("my-auto", props.className)}>
                            {showPrevNext && (
                                <Pagination.Item
                                    aria-label={pageNumber === 1 ? undefined : `Goto Previous Page, Page ${pageNumber - 1}`}
                                    disabled={pageNumber === 1}
                                    onClick={() => onPageChange(pageNumber - 1)}
                                    data-metadata={JSON.stringify({ name: `Goto Previous Page, Page ${pageNumber - 1}` })}
                                >
                                    <Icon icon="chevron-left" />
                                </Pagination.Item>
                            )}
                            {times(pageItemsCount, (index) => (
                                <Pagination.Item
                                    key={index}
                                    className={classNames("", {
                                        "wide-title": (isActivePage(firstPageNumber + index) && isLoading) || firstPageNumber + index > 9,
                                    })}
                                    active={firstPageNumber + index === pageNumber}
                                    onClick={() => onPageChange(firstPageNumber + index)}
                                    data-metadata={JSON.stringify({ name: `Page ${firstPageNumber + index}` })}
                                >
                                    {isActivePage(firstPageNumber + index)
                                        ? activePageTitle(firstPageNumber + index)
                                        : firstPageNumber + index}
                                </Pagination.Item>
                            ))}
                            {showPrevNext && (
                                <Pagination.Item
                                    aria-label={pageNumber === pagesCount ? undefined : `Goto Next Page, Page ${pageNumber + 1}`}
                                    disabled={pageNumber === pagesCount}
                                    onClick={() => onPageChange(pageNumber + 1)}
                                    data-metadata={JSON.stringify({ name: `Goto Next Page, Page ${pageNumber + 1}` })}
                                >
                                    <Icon icon="chevron-right" />
                                </Pagination.Item>
                            )}
                        </Pagination>
                    </nav>
                    {!isMobile && onPageSizeChange && (
                        <Form.Select
                            value={pageSize}
                            name="pageSize"
                            aria-label="Change Page Size"
                            className="bg-white page-select w-auto"
                            onChange={(e) => {
                                onPageChange(1);
                                onPageSizeChange(Number(e.target.value));
                            }}
                        >
                            <option value="10">10 / page</option>
                            <option value="20">20 / page</option>
                        </Form.Select>
                    )}
                    {!isMobile && <span className="text-nowrap">Go to</span>}
                </div>
                <span aria-hidden="true" className="invisible">
                    Error layout placeholder
                </span>
            </div>
            {!isMobile && (
                <div className="gap-2 d-flex flex-column justify-content-center align-items-center mt-3">
                    <div className="d-flex gap-2 me-auto align-items-center">
                        <Form.Control
                            name="page"
                            aria-label="Jump To Page Input"
                            onKeyDown={(e) => !pageDoesntExist && e.key === "Enter" && jumpPage && onPageChange(jumpPage)}
                            onChange={(e) => {
                                const value = Number(e.target.value);
                                isNumber(value);
                                if (e.target.value === "") {
                                    setJumpPage(undefined);
                                } else if (!isNumber(value)) {
                                    setJumpPage(0);
                                } else {
                                    setJumpPage(Number(e.target.value));
                                }
                            }}
                            className={classNames("bg-white page-input", {
                                "is-invalid": pageDoesntExist,
                            })}
                            type="number"
                        />
                        <Button
                            title="Jump To Page"
                            disabled={isNil(jumpPage) || pageDoesntExist}
                            onClick={() => jumpPage && onPageChange(jumpPage)}
                            variant="secondary"
                        >
                            Go
                        </Button>
                    </div>
                    <span
                        className={classNames("text-danger me-auto", {
                            invisible: !pageDoesntExist,
                        })}
                    >
                        Page doesn't exist
                    </span>
                </div>
            )}
        </div>
    );
};

interface DataGridPagingInfoProps {
    className?: string;
    pageNumber: number;
    pageSize?: number;
    totalRecords?: number;
}
interface DataGridPagingProps extends DataGridPagingInfoProps {
    pagesCount: number;
    isLoading?: boolean;
    onPageChange: (pageNumber: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
}
