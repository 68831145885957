import Form, { WidgetProps } from "@rjsf/core";
import { useContactTypes } from "components/utils/useContactTypes";
import { useCallback, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { ContractorContactForm } from "./ContractorContactForm";
import { PremiseContactForm } from "./PremiseContactForm";
import { PrimaryContactForm } from "./PrimaryContactForm";
import { ContactInboundData, ContactValidationResult, FormState } from "./types";
import { ContactType, ContactData } from "components/utils/contacts";
import { useApplicationContactRequirements } from "./useApplicationContactRequirements";
import { clearApplicationContactsCache, useApplicationContacts } from "./useApplicationContacts";
import {
    copyPremiseToPrimaryContact,
    createContactFormDataObject,
    createContractorFormDataObjectFromContact,
    getContactTypeByName,
    getCopyContactState,
    getFormRefValues,
    getStoredContactState,
    validate,
    validateDataFormat,
} from "./utils";
import { useCustomerContacts } from "components/utils/useCustomerContacts";
import { useContractorLabel } from "./useContractorLabel";

const ApplicationContactsWidget = (props: WidgetProps) => {
    const { applicationNumber, contactsWidgetRef, onContactsLoaded, appSubmitted } = props.formContext ?? {};
    const [requirements, isLoadingRequirements] = useApplicationContactRequirements(applicationNumber);
    const [contacts, isLoadingContacts] = useApplicationContacts(applicationNumber);
    const [contactTypes = [], isLoadingContactTypes] = useContactTypes();
    const [customerContacts = [], isLoadingCustomerContacts] = useCustomerContacts(1, 100);

    const contractorLabel = useContractorLabel();

    const primaryContactFormRef = useRef<Form<any>>();
    const premiseContactFormRef = useRef<Form<any>>();
    const contractorContactFormRef = useRef<Form<any>>();

    const [primaryContactData, setPrimaryContactData] = useState<any>();
    const [premiseContactData, setPremiseContactData] = useState<any>();
    const [contractorContactData, setContractorContactData] = useState<any>();

    const [primaryContactErrors, setPrimaryContactErrors] = useState<ContactValidationResult>();
    const [premiseContactErrors, setPremiseContactErrors] = useState<ContactValidationResult>();
    const [contractorContactErrors, setContractorContactErrors] = useState<ContactValidationResult>();

    const isLoading = isLoadingRequirements || isLoadingContactTypes || isLoadingContacts || isLoadingCustomerContacts;

    // Setup widget
    useEffect(() => {
        if (contactsWidgetRef) {
            contactsWidgetRef.current = {
                validate: () => {
                    const errors = validate({
                        primaryContactFormRef,
                        premiseContactFormRef,
                        contractorContactFormRef,
                        requirements,
                        contractorLabel,
                    });
                    setPrimaryContactErrors(errors.primaryContactErrors);
                    setPremiseContactErrors(errors.premiseContactErrors);
                    setContractorContactErrors(errors.contractorContactErrors);

                    return errors.primaryContactErrors || errors.premiseContactErrors || errors.contractorContactErrors;
                },
                validateDataFormat: () => {
                    const errors = validateDataFormat({
                        primaryContactFormRef,
                        premiseContactFormRef,
                        contractorContactFormRef,
                        requirements,
                        contractorLabel,
                    });
                    setPrimaryContactErrors(errors.primaryContactErrors);
                    setPremiseContactErrors(errors.premiseContactErrors);
                    setContractorContactErrors(errors.contractorContactErrors);

                    return errors.primaryContactErrors || errors.premiseContactErrors || errors.contractorContactErrors;
                },
                getSubmitValues: () => {
                    let values = [];

                    if (requirements.requirePremise && premiseContactFormRef.current) {
                        const contact = contacts?.find((c) => c.contactType.toLowerCase() === ContactType.Premise);
                        const contactType = getContactTypeByName(contactTypes, ContactType.Premise);
                        const formData = getFormRefValues(premiseContactFormRef.current) as ContactData;

                        // Add contact if form has at least some data filled
                        if (!(Object.values(formData ?? {}).every(isEmpty) && !contact?.contactNumber)) {
                            values.push({
                                ...formData,
                                contactNumber: contact?.contactNumber,
                                workflowTargetGroupId: contact?.workflowTargetGroupId,
                                applicationContactType: ContactType.Premise,
                                contactType,
                                contactTitle: "Premise contact",
                            });
                        }
                    }

                    if (requirements.requirePrimaryContact && primaryContactFormRef.current) {
                        const contact = contacts?.find((c) => c.contactType.toLowerCase() === ContactType.Primary);
                        const contactType = getContactTypeByName(contactTypes, ContactType.Primary);
                        const formData = getFormRefValues(primaryContactFormRef.current) as ContactData;

                        // Add contact if form has at least some data filled
                        if (!(Object.values(formData ?? {}).every(isEmpty) && !contact?.contactNumber)) {
                            values.push({
                                ...formData,
                                contactNumber: contact?.contactNumber,
                                workflowTargetGroupId: contact?.workflowTargetGroupId,
                                applicationContactType: ContactType.Primary,
                                contactType,
                                contactTitle: "Primary contact",
                            });
                        }
                    }

                    if (requirements.requireContractor && contractorContactFormRef.current) {
                        const contact = contacts?.find((c) => c.contactType.toLowerCase() === ContactType.Contractor);
                        const contactType = getContactTypeByName(contactTypes, ContactType.Contractor) as number;
                        const formData = getFormRefValues(contractorContactFormRef.current) as ContactData;

                        // Add contact if form has at least some data filled
                        if (!(Object.values(formData ?? {}).every(isEmpty) && !contact?.contactNumber)) {
                            values.push({
                                ...formData,
                                contactNumber: contact?.contactNumber,
                                workflowTargetGroupId: contact?.workflowTargetGroupId,
                                applicationContactType: ContactType.Contractor,
                                contactType,
                                contactTitle: "Contractor",
                            });
                        }
                    }

                    return values;
                },
                fieldNumber: props.uiSchema["af:fieldNumber"],
            };
        }

        return () => {
            if (contactsWidgetRef?.current) {
                contactsWidgetRef.current = undefined;
            }
        };
    }, [contactTypes, contacts, contactsWidgetRef, props.uiSchema, requirements, contractorLabel]);

    // Init contact data
    useEffect(() => {
        if (contacts) {
            const primaryContact = contacts.find((c) => c.contactType.toLowerCase() === ContactType.Primary);
            const primaryContactData = createContactFormDataObject(primaryContact);
            if (primaryContactData) {
                setPrimaryContactData(primaryContactData);
            }

            const premiseContact = contacts.find((c) => c.contactType.toLowerCase() === ContactType.Premise);
            const premiseContactData = createContactFormDataObject(premiseContact);
            if (premiseContactData) {
                setPremiseContactData(premiseContactData);
            }

            const contractorContact = contacts.find((c) => c.contactType.toLowerCase() === ContactType.Contractor);
            const contractorContactData = createContractorFormDataObjectFromContact(contractorContact);
            if (contractorContactData) {
                setContractorContactData(contractorContactData);
            }
        }
    }, [contacts]);

    useEffect(() => {
        if (!isLoading) {
            onContactsLoaded?.();
        }
    }, [isLoading, onContactsLoaded]);

    // Clear contact data on unmount
    useEffect(() => {
        return () => {
            clearApplicationContactsCache(applicationNumber);
        };
    }, [applicationNumber]);

    const onUseCustomerContactForPremiseContact = useCallback((selectedContact: ContactInboundData | undefined) => {
        if (selectedContact) {
            const formData = createContactFormDataObject(selectedContact);
            setPremiseContactData({
                ...formData,
                storedContactNumber: selectedContact.contactNumber,
            });
        }
    }, []);

    const onUseCustomerContactPrimaryContact = useCallback((selectedContact: ContactInboundData | undefined) => {
        if (selectedContact) {
            const formData = createContactFormDataObject(selectedContact);
            setPrimaryContactData({
                ...formData,
                storedContactNumber: selectedContact.contactNumber,
            });
        }
    }, []);

    const onUseCustomerContactContractor = useCallback((selectedContact: ContactInboundData | undefined) => {
        if (selectedContact) {
            const formData = createContractorFormDataObjectFromContact(selectedContact);
            setContractorContactData({
                ...formData,
                storedContactNumber: selectedContact.contactNumber,
            });
        }
    }, []);

    const onCopyPremiseToPrimaryContact = useCallback(() => {
        const formData = copyPremiseToPrimaryContact(premiseContactFormRef);
        setPrimaryContactData(formData);
    }, []);

    const onChange = useCallback(
        (form: any, contactType: ContactType) => {
            if (premiseContactFormRef.current) {
                const { formData } = contactType === ContactType.Premise ? form : (premiseContactFormRef.current.state as FormState);
                const storedContactNumber = getStoredContactState(formData, customerContacts);
                if (formData.storedContactNumber !== storedContactNumber) {
                    setPremiseContactData({
                        ...formData,
                        storedContactNumber,
                    });
                }
            }

            if (primaryContactFormRef.current) {
                const { formData } = contactType === ContactType.Primary ? form : (primaryContactFormRef?.current.state as FormState);
                const storedContactNumber = getStoredContactState(formData, customerContacts);
                let copyContact = Boolean(formData.copyContact);

                if (premiseContactFormRef.current) {
                    copyContact = getCopyContactState(premiseContactFormRef, formData);
                }

                if (Boolean(formData.copyContact) !== copyContact || formData.storedContactNumber !== storedContactNumber) {
                    setPrimaryContactData({
                        ...formData,
                        copyContact,
                        storedContactNumber,
                    });
                }
            }

            if (contractorContactFormRef.current) {
                const { formData } = contactType === ContactType.Contractor ? form : (contractorContactFormRef?.current.state as FormState);
                const storedContactNumber = getStoredContactState(formData, customerContacts, true);
                if (formData.storedContactNumber !== storedContactNumber) {
                    setContractorContactData({
                        ...formData,
                        storedContactNumber,
                    });
                }
            }
        },
        [customerContacts]
    );

    if (isLoading || props.readonly || appSubmitted) {
        return null;
    }

    return (
        <div className="application-contacts-widget border">
            <PremiseContactForm
                formRef={premiseContactFormRef}
                formData={premiseContactData}
                extraErrors={premiseContactErrors}
                requirements={requirements}
                onUseCustomerContact={onUseCustomerContactForPremiseContact}
                onChange={(form) => onChange(form, ContactType.Premise)}
            />
            <PrimaryContactForm
                formRef={primaryContactFormRef}
                formData={primaryContactData}
                extraErrors={primaryContactErrors}
                requirements={requirements}
                onCopyContact={requirements.requirePremise ? onCopyPremiseToPrimaryContact : undefined}
                onUseCustomerContact={onUseCustomerContactPrimaryContact}
                onChange={(form) => onChange(form, ContactType.Primary)}
            />
            <ContractorContactForm
                formRef={contractorContactFormRef}
                formData={contractorContactData}
                extraErrors={contractorContactErrors}
                requirements={requirements}
                applicationNumber={applicationNumber}
                onUseCustomerContact={onUseCustomerContactContractor}
                onChange={(form) => onChange(form, ContactType.Contractor)}
            />
        </div>
    );
};

export default ApplicationContactsWidget;
