import useSWR from "swr";
import SelectWidget from "./SelectWidget";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import { WidgetProps } from "@rjsf/core";
import { ReadOnlyValueWithNoDataWidget } from "./ReadOnlyValueWithNoDataWidget";

export const ContractorLookupListWidget = (props: WidgetProps) => {
    const { applicationNumber } = props.formContext ?? {};

    const baseUrl = getUrl(process.env.REACT_APP_APPLICATION_CONTRACTOR_LIST_ENDPOINT, { applicationNumber });
    const query = new URLSearchParams();

    const url = applicationNumber ? baseUrl + "?" + query.toString() : null;

    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    if (!data && !isLoading) {
        return <ReadOnlyValueWithNoDataWidget {...props} />;
    }

    const options = {
        ...(props.options || {}),
        enumOptions: (data ?? []).map(({ display, value }: { display: string; value: string }) => ({
            label: display,
            value: value,
        })),
    };

    return <SelectWidget {...props} options={options}></SelectWidget>;
};
