import { PowerBiReportEntityType } from "types/PowerBiReportEntityType";
import { Alert, Spinner } from "react-bootstrap";
import { usePowerBiReport } from "./usePowerBiReport";
import { PowerBiReportEmbed } from "./PowerBiReportEmbed";

export const PowerBiReport = (props: PowerBiReportProps) => {
    const { reportId, entityNumber, entityTypeId } = props;

    const [report, isLoading, error] = usePowerBiReport(reportId, entityNumber, entityTypeId);

    if (!report && isLoading) {
        return (
            <Spinner animation="border" className="align-self-center" role="status">
                <span className="visually-hidden">Loading report...</span>
            </Spinner>
        );
    }

    if (!report || !report.token || !report.url || error) {
        return <Alert variant="danger">Failed to load the report.</Alert>;
    }

    return (
        <div className="d-flex flex-column gap-3">
            <h2 className="m-0">Report</h2>
            <PowerBiReportEmbed embedId={reportId} embedUrl={report.url} accessToken={report.token} />
        </div>
    );
};

interface PowerBiReportProps {
    reportId: string;
    entityNumber?: string;
    entityTypeId?: PowerBiReportEntityType;
}
