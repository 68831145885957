import { MapProps } from "./types";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { CENTER_USA, MAP_STYLES } from "./utils";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ContractorCard } from "./ContractorCard";
export const ContractorMap = (props: MapProps) => {
    const {
        clustererRef,
        mapRef,
        selectedContractor,
        setSelectedContractor,
        mapInitialisationResolver,
        firstMapLoadFinished,
        setContractorOnSlideout,
    } = props;

    const containerStyle = {
        width: "100%",
        height: "100%",
    };

    const { isLoaded } = useJsApiLoader({
        id: "contractor-search",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
    });

    if (!isLoaded) return <div></div>;

    return (
        <div className="d-flex flex-column h-100">
            <div className="flex-grow-1">
                <GoogleMap
                    onLoad={(gmap) => {
                        mapRef.current = gmap;
                        gmap.setZoom(8);
                        gmap.setCenter(CENTER_USA);
                        clustererRef.current = new MarkerClusterer({
                            map: mapRef.current,
                            markers: [],
                            algorithmOptions: {
                                maxZoom: 14,
                            },
                        });
                        firstMapLoadFinished.current = true;
                        mapInitialisationResolver.current?.(true);
                    }}
                    options={{
                        styles: MAP_STYLES,
                        streetViewControl: false,
                        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
                        mapTypeControl: false,
                        fullscreenControl: false,
                    }}
                    mapContainerStyle={containerStyle}
                    zoom={5}
                ></GoogleMap>
            </div>
            {!!selectedContractor && (
                <div className="w-100 bg-white p-3">
                    <ContractorCard
                        c={selectedContractor}
                        setSelectedContractor={setSelectedContractor}
                        setContractorOnSlideout={setContractorOnSlideout}
                        showClose
                    />
                </div>
            )}
        </div>
    );
};
