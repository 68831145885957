import { Button } from "components/Button";
import { useRef, useState } from "react";
import { EquipmentListItem } from "types/EquipmentListItem";
import { Equipment } from "./Equipment";
import Form from "@rjsf/core";
import { ErrorSummary } from "components/ErrorSummary";
import { submitByRefPromise } from "components/JsonForm/utils";
import { Offcanvas, Spinner, Stack } from "react-bootstrap";
import { omit } from "lodash";
import { SubmitButton } from "components/Button/SubmitButton";
import { useEquipmentDetails } from "components/EquipmentBlock/useEquipmentDetails";
import { EquipmentLabel } from "./EquipmentLabel";
import { createId } from "components/utils/string";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";

export const EquipmentCopy = ({ applicationNumber, onEquipmentCopyClose, onEquipmentCopySubmit, item }: EquipmentCopyProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorSummary, setErrorSummary] = useState<any>();
    const formRef = useRef<Form<any>>();

    const [equipment, isLoading] = useEquipmentDetails(applicationNumber, item.equipid);

    const onSubmit = async () => {
        try {
            // Validate equipment form
            const formData = await submitByRefPromise(formRef);
            const catalogNumber = equipment.catalogNumber;
            const hasApprovedMeasure = equipment.attributes.some((attr) => attr.hasApproveMeasure);
            if (catalogNumber && onEquipmentCopySubmit) {
                const equipmentItem: EquipmentAddSubmitItem = {
                    equipID: createId(),
                    catalogNumber,
                    quantity: formData.quantity,
                    attributes: Object.keys(omit(formData, "quantity")).map((key: string) => ({
                        attributename: key,
                        attributevalue: String(formData[key] ?? ""),
                    })),
                };

                setIsSubmitting(true);
                await onEquipmentCopySubmit(equipmentItem, hasApprovedMeasure);
            }
        } catch (error) {
            setErrorSummary(error);
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas className="equipment-panel" show placement="end" onHide={onEquipmentCopyClose} aria-labelledby="edit-equipment title">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="edit-equipment title" className="fs-4">
                    Equipment
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                {isLoading ? (
                    <Spinner className="align-self-center" animation="border" role="status">
                        <span className="visually-hidden">Loading equipment...</span>
                    </Spinner>
                ) : (
                    <>
                        <EquipmentLabel equipment={item} className="lh-sm small" />
                        <hr className="my-3" />
                        <ErrorSummary errorSummary={errorSummary} />
                        {equipment && <Equipment formRef={formRef} item={equipment} />}
                        <Stack className="equipment-controls justify-content-start" direction="horizontal">
                            {equipment && (
                                <SubmitButton
                                    onClick={onSubmit}
                                    isSubmitting={isSubmitting}
                                    spinnerText="Copying equipment..."
                                    className="me-3"
                                >
                                    Submit
                                </SubmitButton>
                            )}
                            <Button variant="secondary" onClick={onEquipmentCopyClose} disabled={isSubmitting}>
                                Cancel
                            </Button>
                        </Stack>
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

interface EquipmentCopyProps {
    applicationNumber: string;
    onEquipmentCopySubmit: (equipmentItem: EquipmentAddSubmitItem, hasApprovedMeasure?: boolean) => Promise<void>;
    onEquipmentCopyClose: () => void;
    item: EquipmentListItem;
}
