import { isInIframe } from "components/utils/dom";
import { httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";

export const useCustomerNotifications = (programNumber?: string): [tasks: Task[], notes: Note[], isLoading: boolean, error: any] => {
    const baseUrl = process.env.REACT_APP_CUSTOMER_NOTIFICATIONS_ENDPOINT;
    let url = null;
    if (baseUrl && !isInIframe()) {
        const query = new URLSearchParams();
        query.append("programNumber", String(programNumber));

        url = baseUrl + "?" + query.toString();
    }
    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data && url !== null;

    if (isInIframe()) {
        return [mockedTasks, mockedNotes, false, undefined];
    }

    return [data?.CustomerNotifications?.Tasks?.Task, data?.CustomerNotifications?.Notes?.Note, isLoading, error];
};

const mockedTasks = [
    {
        programNumber: "12345",
        program: "Sample program",
        appId: "67890",
        projectNumber: "D2RC123456",
        projectName: "Project Name",
        taskNumber: "12345",
        date: "2024-02-22T15:24:20.947",
        stepType: "Create New Contact",
        step: "Create New Contact",
        content: "Task content",
    },
];

const mockedNotes = [
    {
        programNumber: "12345",
        program: "Sample program",
        appId: "67890",
        projectNumber: "D2RC123456",
        projectName: "Project Name",
        date: "2024-02-22T15:24:20.947",
        name: "A note",
        noteContent: "Note content",
    },
];

export enum NotificationsTab {
    Tasks = "tasks",
    Inbox = "inbox",
}

export interface NotificationsMenuProps {
    className?: string;
    notificationCount: number;
    tasks: Task[];
    notes: Note[];
    navTabs: Array<any>;
    onNotificationClick: (applicationNumber: string, tab: string) => void;
}

export interface TaskListProps {
    tasks: Task[];
    onNotificationClick: (applicationNumber: string, tab: string) => void;
}

export interface MessageListProps {
    notes: Note[];
    onNotificationClick: (applicationNumber: string, tab: string) => void;
}

export type Note = {
    programNumber: string;
    program: string;
    appId: string;
    projectNumber: string;
    projectName: string;
    date: string;
    name: string;
    noteContent?: string;
};

export type Task = {
    programNumber: string;
    program: string;
    appId: string;
    projectNumber: string;
    projectName: string;
    taskNumber: string;
    date: string;
    stepType: string;
    step: string;
    content?: string;
};
