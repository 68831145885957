import React, { useEffect } from "react";
import { WidgetProps } from "@rjsf/core";

const ApplicationNameWidget = (props: WidgetProps) => {
    const { applicationNameRef } = props.formContext ?? {};
    const { registry } = props;
    const { TextWidget } = registry.widgets;

    // Setup widget
    useEffect(() => {
        if (applicationNameRef) {
            applicationNameRef.current = {
                /** Will need the field number to find the application name on form submit */
                fieldNumber: props.uiSchema["af:fieldNumber"],
            };
        }

        return () => {
            if (applicationNameRef?.current) {
                applicationNameRef.current = undefined;
            }
        };
    }, [props.uiSchema, applicationNameRef]);

    return <TextWidget {...props} label="Name this project" />;
};

export default ApplicationNameWidget;
