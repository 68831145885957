import useSWR, { SWRResponse } from "swr";
import { ApplicationFormPageResponse } from "../../types/ApplicationFormPageResponse";
import { getUrl, httpGetAuthorized } from "./http";
import { isInIframe } from "./dom";

export const useApplicationFormPage = (
    applicationNumber: string | undefined,
    pageNumber: string | null | undefined
): [data: ApplicationFormPageResponse | undefined, isLoading: boolean, error: any] => {
    const url =
        !isInIframe() && applicationNumber && pageNumber
            ? getUrl(process.env.REACT_APP_APPLICATION_PAGE_FORM_ENDPOINT, { applicationNumber, pageNumber })
            : null;

    const { data, error } = useSWR(url, httpGetAuthorized) as SWRResponse<ApplicationFormPageResponse>;
    const isLoading = !error && !data && url !== null;

    if (pageNumber && isInIframe()) {
        return [getMockedFormPage(pageNumber), false, undefined];
    }

    return [data, isLoading, error];
};

const getMockedFormPage = (pageNumber: string): ApplicationFormPageResponse => {
    return {
        formAllowance: {
            allowFormEdit: pageNumber !== "2",
        },
        formConfiguration: getMockedFormConfiguration(pageNumber),
        formDetails: {
            fields: [],
        },
        pageNavigation: {
            previousPage: "1",
            nextPage: "3",
        },
    };
};

const getMockedFormConfiguration = (pageNumber: string) => {
    return {
        schema: {
            title: `Form ${pageNumber}`,
            description: "Page description",
            type: "object",
            properties: {
                section1: {
                    type: "object",
                    title: "Section 1",
                    description: "Section description",
                    properties: {
                        leftcol: {
                            type: "object",
                            title: "",
                            description: "",
                            properties: {
                                field1: {
                                    type: "string",
                                    title: "Field 1",
                                    anyOf: [
                                        {
                                            title: "a",
                                            enum: ["a"],
                                        },
                                        {
                                            title: "b",
                                            enum: ["b"],
                                        },
                                        {
                                            title: "c",
                                            enum: ["c"],
                                        },
                                        {
                                            title: "d",
                                            enum: ["d"],
                                        },
                                    ],
                                },
                                field2: {
                                    type: "string",
                                    title: "Field 2",
                                },
                            },
                            required: ["field2"],
                        },
                    },
                },
                section2: {
                    type: "object",
                    title: "Section 2",
                    description: "Section description",
                    properties: {
                        column1: {
                            type: "object",
                            title: "Column 1",
                            description: "Column description",
                            properties: {
                                field1: {
                                    type: "string",
                                    title: "Field 1",
                                },
                            },
                        },
                        column2: {
                            type: "object",
                            title: "Column 2",
                            description: "Column description",
                            required: ["field2"],
                            properties: {
                                field2: {
                                    type: "number",
                                    title: "Field 2",
                                    maxLength: 5,
                                },
                            },
                        },
                    },
                },
            },
        },
        uiSchema: {
            "af:pageNumber": pageNumber,
            section1: {
                "ui:elementType": "section",
                leftcol: {
                    "ui:elementType": "column",
                },
            },
            section2: {
                "ui:elementType": "section",
                column1: {
                    "ui:elementType": "column",
                },
                column2: {
                    "ui:elementType": "column",
                },
            },
        },
        rules: [],
    };
};
