import { toArray } from "components/utils/array";
import { getUrl, httpPostAuthorized } from "components/utils/http";
import { isEmpty, omit } from "lodash";
import { useMemo, useState } from "react";
import useSWR, { KeyedMutator } from "swr";
import { ApplicationListColumnKeys, FilterPair } from "./types";
import { isNullOrWhitespace } from "components/utils/validation";
import { isInIframe } from "components/utils/dom";

export const APP_LIST_GRID_ID = "60BA59A3-2BA9-430E-9D46-60249C4531FA";

export const useApplicationList = (
    programNumber: string | undefined,
    filterPairs: FilterPair[],
    pageNumber: number,
    pageSize: number,
    sortBy: string = "",
    sortAsc: boolean = true,
    timeOffset: number = 0
): [gridResponse: GridResponse | undefined, isLoading: boolean, error: any, refresh: KeyedMutator<any>] => {
    const [response, setResponse] = useState<any>();
    const baseUrl = programNumber && pageSize > 0 ? getUrl(process.env.REACT_APP_APPLICATION_GRIDV2_ENDPOINT, { programNumber }) : null;

    let url: string | null = null;
    if (baseUrl) {
        url = baseUrl + "?" + getQueryParams(pageNumber, pageSize, timeOffset).toString();
    }

    const body = useMemo(() => getSearchParams(filterPairs, sortBy, sortAsc), [filterPairs, sortBy, sortAsc]);
    const key = useMemo(() => (isInIframe() ? null : [url, body]), [url, body]);

    const { data, error, mutate } = useSWR(key, () => httpPostAuthorized(url!, body));
    const isLoading = !error && !data && url !== null;

    // Update data only when not loading anymore. Prevents grid flicker.
    if (!isLoading && response !== data) {
        setResponse(data);
    }

    const result = useMemo(() => {
        let result: GridResponse | undefined = undefined;

        if (response?.grid) {
            const rows = toArray(response?.grid.rows);
            if (rows?.length > 0) {
                const items = rows.map((i: any) => omit(i, ["MoreRecords", "totRecords"]));
                const totalRecords = Number(rows[0]["totRecords"]);
                const remainingRecords = Number(rows[0]["MoreRecords"]);

                result = {
                    items,
                    totalRecords,
                    remainingRecords,
                };
            }
        }

        return result;
    }, [response]);

    return [result, isLoading, error, mutate];
};

export const getQueryParams = (pageNumber: number, pageSize: number, timeOffset: number) => {
    const query = new URLSearchParams();
    query.append("tableId", APP_LIST_GRID_ID);
    query.append("pageNum", String(pageNumber));
    query.append("recsPerPage", String(pageSize));
    query.append("timeOffset", String(timeOffset));

    return query;
};

export const getSearchParams = (filterPairs: FilterPair[], sortBy: string, sortAsc: boolean) => {
    const searchFilter: typeof searchParams.search.searchFilter = [];

    for (const pair of filterPairs) {
        const searchField =
            pair.field !== "*"
                ? [pair.field]
                : [
                      ApplicationListColumnKeys.ProjectNumber,
                      ApplicationListColumnKeys.ProjectName,
                      ApplicationListColumnKeys.Program,
                      ApplicationListColumnKeys.Contacts,
                  ];

        let searchValue: string[] = [];
        let searchMinValue = "";
        let searchMaxValue = "";

        if (pair.field === ApplicationListColumnKeys.Tasks || pair.field === ApplicationListColumnKeys.Messages) {
            searchMinValue = pair.value;
        } else if (pair.field === ApplicationListColumnKeys.DateCreated) {
            const [startDate, endDate] = pair.value.split("|");
            searchMinValue = startDate;
            searchMaxValue = endDate;
        } else if (pair.field === ApplicationListColumnKeys.AppStatus) {
            const existingSearchFilter = searchFilter.find((f) => f.fields.searchField[0] === pair.field);
            if (existingSearchFilter) {
                existingSearchFilter.searchValue.push(addWildcards(pair.value));
                continue;
            } else {
                searchValue.push(addWildcards(pair.value));
            }
        } else {
            searchValue.push(addWildcards(pair.value));
        }

        // Search value cannot be empty.
        if (isEmpty(searchValue)) {
            searchValue = [""];
        }

        if (searchField) {
            searchFilter.push({
                fields: {
                    searchField: searchField,
                },
                searchValue,
                searchMinValue,
                searchMaxValue,
            });
        }
    }

    const searchSort = [];

    if (!isEmpty(sortBy)) {
        searchSort.push({
            sortField: sortBy,
            sortAsc: sortAsc ? "1" : "0",
            sortOrder: "1",
        });
    }

    const searchParams: SearchParams = {
        search: {
            searchFilter,
        },
        sort: {
            searchSort,
        },
    };

    return searchParams;
};

const addWildcards = (value: string) => {
    if (isNullOrWhitespace(value)) {
        return value;
    }

    return `*${value.replace(/^\*|\*$/g, "")}*`;
};

export interface GridResponse {
    totalRecords: number;
    remainingRecords: number;
    items: any[];
}

type SearchParams = {
    search: {
        searchFilter: {
            fields: {
                searchField: string[];
            };
            searchValue: string[];
            searchMinValue: string;
            searchMaxValue: string;
        }[];
    };
    sort: {
        searchSort: {
            sortField: string;
            sortAsc: string;
            sortOrder: string;
        }[];
    };
};
