import { Correspondence } from "types/Correspondence";
import { useCallback, useState } from "react";
import { postCreatePdf, replaceHttpLinksToHttps } from "components/utils";
import { download } from "components/utils/files";
import { useToast } from "components/Toast";
import { getErrorMessage } from "components/utils/http";

export const useCorrespondenceListActions = () => {
    const toast = useToast();

    const [downloadingCorrespondence, setDownloadingCorrespondence] = useState<string[]>([]);
    const [activeCorrespondence, setActiveCorrespondence] = useState<Correspondence | null>(null);

    const onDownload = useCallback(
        async (correspondence: Correspondence) => {
            const documentNumber = correspondence.documentNumber ?? "";
            const fileName = correspondence.item ?? "";
            const content = correspondence.content ?? "";

            try {
                setDownloadingCorrespondence((prev) => [...prev, documentNumber]);
                const response = await postCreatePdf({
                    fileName,
                    content: replaceHttpLinksToHttps(content),
                });
                download(response?.blob, fileName);
            } catch (error) {
                toast.error(getErrorMessage(error));
            } finally {
                setDownloadingCorrespondence((prev) => prev.filter((x) => x !== documentNumber));
            }
        },
        [toast]
    );

    const onView = useCallback((correspondence: Correspondence) => {
        setActiveCorrespondence(correspondence);
    }, []);

    const onCloseView = useCallback(() => {
        setActiveCorrespondence(null);
    }, []);

    return {
        downloadingCorrespondence,
        activeCorrespondence,
        onDownload,
        onView,
        onCloseView,
    };
};
