import { Correspondence } from "types/Correspondence";
import { formatDateAndTime } from "components/utils/date";
import { Button } from "components/Button";
import { Table } from "react-bootstrap";
import { Icon } from "components/Icon";
import { CorrespondenceView } from "./CorrespondenceView";
import { useCorrespondenceListActions } from "./useCorrespondenceListActions";
import { SubmitButton } from "components/Button/SubmitButton";

export const CorrespondenceList = ({ correspondence }: { correspondence: Correspondence[] }) => {
    const { downloadingCorrespondence, activeCorrespondence, onDownload, onView, onCloseView } = useCorrespondenceListActions();

    return (
        <div className="d-flex flex-column gap-4">
            <Table className="application-correspondence table-v2 align-middle m-0 w-100">
                <caption className="visually-hidden">Correspondence list</caption>
                <thead>
                    <tr>
                        <th style={{ width: "20%" }}>Type</th>
                        <th style={{ width: "35%" }}>Item</th>
                        <th style={{ width: "35%" }}>Date</th>
                        <th className="ps-4" style={{ width: "10%" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {correspondence?.map((c, index) => (
                        <tr key={c.documentNumber}>
                            <td className="fw-bold">{c.type}</td>
                            <td className="text-break" id={`${c.documentNumber}-item`}>
                                {c.item}
                            </td>
                            <td className="fs-7">{formatDateAndTime(c.date)}</td>
                            <td>
                                <div className="d-flex flex-row gap-2 align-items-start">
                                    <DownloadButton
                                        downloading={downloadingCorrespondence.includes(c.documentNumber ?? "")}
                                        onClick={() => onDownload(c)}
                                        ariaDescribedby={`${c.documentNumber}-item`}
                                    />
                                    <div className="vr" />
                                    <ViewButton onClick={() => onView(c)} ariaDescribedby={`${c.documentNumber}-item`} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {activeCorrespondence && <CorrespondenceView correspondence={activeCorrespondence} onClose={onCloseView} />}
        </div>
    );
};

export const DownloadButton = ({
    downloading,
    onClick,
    ariaDescribedby,
    hideText = false,
}: {
    downloading: boolean;
    onClick: () => void;
    ariaDescribedby: string;
    hideText?: boolean;
}) => {
    return (
        <SubmitButton
            onClick={onClick}
            disabled={downloading}
            variant="link"
            className="d-flex align-items-center py-0"
            isSubmitting={downloading}
            spinnerText="Downloading..."
            aria-describedby={ariaDescribedby}
            title="Download"
        >
            {!downloading && <Icon icon={["fal", "arrow-down-to-line"]} className="me-2" style={{ width: "1rem" }} />}
            {!hideText && "Download"}
        </SubmitButton>
    );
};

export const ViewButton = ({
    onClick,
    ariaDescribedby,
    hideText,
}: {
    onClick: () => void;
    ariaDescribedby: string;
    hideText?: boolean;
}) => {
    return (
        <Button
            className="d-flex align-items-center gap-2 py-0"
            variant="link"
            onClick={onClick}
            aria-describedby={ariaDescribedby}
            aria-haspopup="dialog"
            title="View"
        >
            <Icon icon={["fal", "circle-arrow-right"]} />
            {!hideText && "View"}
        </Button>
    );
};
