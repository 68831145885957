import { httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";
import { ReferenceResult } from "types/ReferenceResult";

export const useTrueFalseTypes = (): [trueFalseTypes: ReferenceResult[], isLoading: boolean, error: any] => {
    const url = process.env.REACT_APP_REFERENCE_ENDPOINT + "?type=truefalse&outputType=id";
    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    return [data?.referenceResults, isLoading, error];
};
