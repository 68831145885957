import { useEffect, useState } from "react";
import { debounce } from "lodash";
import Fade from "react-bootstrap/Fade";
import { Icon } from "components/Icon";
import { Button } from "components/Button";

export const ScrollTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const onScrollTop = (e: React.MouseEvent<HTMLButtonElement>) => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        // move focus to main content
        const mainContent = document.getElementById("main-content");
        if (mainContent) {
            mainContent.focus();
        }
    };

    useEffect(() => {
        const handleScroll = debounce(() => setIsVisible(document.documentElement.scrollTop > 500), 200);

        window.addEventListener("scroll", handleScroll, true);

        return () => {
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, []);

    return (
        <Fade in={isVisible}>
            <div className="position-fixed bottom-0 end-0 p-4 z-1 w-auto">
                <Button variant="secondary" size="lg" onClick={onScrollTop} title="Scroll to top">
                    <Icon icon={["fal", "arrow-up-to-line"]} size="lg" />
                </Button>
            </div>
        </Fade>
    );
};
