import { useContext } from "react";
import useMediaQuery from "components/utils/useMediaQuery";
import { AppContext } from "components/App/AppContext";
import { TypographyFontSize } from "types/TypographyFontSize";

export const useIsMobileFilters = () => {
    const { settings } = useContext(AppContext);
    const isLargeFont = settings.fontSize === TypographyFontSize.Large;

    return useMediaQuery(`(max-width: ${isLargeFont ? BreakPoints.MobileLargeFontSize : BreakPoints.Mobile})`);
};

const BreakPoints = {
    Mobile: "1070px",
    MobileLargeFontSize: "1180px",
};
