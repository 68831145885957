export enum RelatedApplicationListColumnKeys {
    appId = "c_7ff65faa-d4a0-44d9-b096-b686bd41747c",
    projectNumber = "c_53aabff2-5a29-4968-9c64-8b62b75f31b7",
    projectName = "c_f23d667b-0ff9-49b0-a070-771aa7f1d94d",
    contact = "c_84f179e0-056d-41ed-8008-8bb84beea022",
    dateCreated = "c_8131ab88-3e84-4e5b-a6f9-48995b4df7ee",
    program = "c_6a7b2efb-d112-446f-80a7-ad7153f885e3",
    status = "c_82910f75-a420-4ed3-bf4b-e69ce0d62165",
    relSourceAppId = "c_9e4a8b70-faa2-4467-bec9-f003f059fcc3",
}

export enum RelationsManagementGridColumnKeys {
    appId = "c_b1ce5fe1-996b-486c-b308-96bc16fcaaac",
    projectNumber = "c_8056a13f-0f8d-4ec4-b123-d8f0b5aa22e5",
    projectName = "c_e231a06d-1b99-4854-b746-cd9305ac7d56",
    firstName = "c_08a210d9-a69a-40e9-8af3-e2ddf9c76510",
    lastName = "c_f24c221a-4dad-4f73-83b9-6277198656dd",
    program = "c_724278ef-b452-4b6d-8be3-565b1da17a98",
}

export interface GridResponse {
    totalRecords: number;
    remainingRecords: number;
    items: any[];
}

export type FilterPair = {
    title: string;
    field: string;
    value: string;
    displayValue?: string;
};

export type SearchParams = {
    search: {
        searchFilter: {
            fields: {
                searchField: string[];
            };
            searchValue: string[];
            searchMinValue: string;
            searchMaxValue: string;
        }[];
    };
    sort: {
        searchSort: {
            sortField: string;
            sortAsc: string;
            sortOrder: string;
        }[];
    };
};
