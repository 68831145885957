import { isInIframe } from "components/utils/dom";
import { useCallback, useContext, useMemo, useState } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import { Button } from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@mui/material";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { EquipmentListItem } from "types/EquipmentListItem";
import { AppContext } from "components/App/AppContext";
import { refreshEquipmentList, useEquipmentList } from "components/EquipmentBlock/useEquipmentList";
import { createEquipment, deleteEquipment, updateEquipment } from "components/EquipmentBlock/utils";
import { EquipmentEditSubmitItem } from "types/EquipmentEditSubmitItem";
import { EquipmentEdit } from "./EquipmentEdit";
import { EquipmentCopy } from "./EquipmentCopy";
import { ReactComponent as EquipmentPlaceholder } from "../assets/equipment-placeholder.svg";
import { SubmitButton } from "components/Button/SubmitButton";
import { useToast } from "components/Toast";
import { getErrorMessage } from "components/utils/http";
import { EquipmentAdd } from "components/EquipmentBlock/EquipmentAdd";
import { EquipmentBlockContextValue } from "types/EquipmentBlockContextValue";
import { EquipmentBlockContext } from "components/utils/contexts";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";
import { EquipmentLabel } from "./EquipmentLabel";
import cn from "classnames";
import { EmptyStatePlaceholder } from "../EmptyStatePlaceholder";
import { useEquipmentDetails } from "components/EquipmentBlock/useEquipmentDetails";

export function ApplicationEquipment({
    applicationNumber,
    readOnly,
}: Readonly<{
    applicationNumber: string;
    readOnly?: boolean;
}>) {
    const [equipmentList = [], isLoadingEquipment, , allowEquipmentCopy] = useEquipmentList(applicationNumber);

    const [activeEquipment, setActiveEquipment] = useState<EquipmentListItem>();
    const [activeCopyEquipment, setActiveCopyEquipment] = useState<EquipmentListItem>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { requirements } = useContext(AppContext);

    const [equipment, setEquipment] = useState<any>(false);
    const [showEquipmentAdd, setShowEquipmentAdd] = useState<boolean>(false);

    const allowEquipmentEntry = readOnly ?? requirements.allowEquipmentEntry;

    const toast = useToast();
    const isMobile = useMediaQuery("(max-width: 768px)");

    const onEquipmentEditSubmit = useCallback(
        async (equipmentNumber: string, equipmentItem: EquipmentEditSubmitItem, hasApprovedMeasure?: boolean) => {
            const response = await updateEquipment(applicationNumber, equipmentNumber, equipmentItem);
            setActiveEquipment(undefined);
            toast.success(hasApprovedMeasure ? "Approved measure successfully updated" : response.responseMessage);
            refreshEquipmentList(applicationNumber);
        },
        [applicationNumber, toast]
    );

    const onEquipmentCopySubmit = useCallback(
        async (equipmentItem: EquipmentAddSubmitItem, hasApprovedMeasure?: boolean) => {
            const response = await createEquipment(applicationNumber, equipmentItem);
            setActiveCopyEquipment(undefined);
            toast.success(hasApprovedMeasure ? "Approved measure successfully created" : response.responseMessage);
            refreshEquipmentList(applicationNumber);
        },
        [applicationNumber, toast]
    );

    const onEquipmentAddSubmit = useCallback(
        async (equipmentItem: EquipmentAddSubmitItem) => {
            const response = await createEquipment(applicationNumber, equipmentItem);
            setShowEquipmentAdd(false);
            toast.success(response.responseMessage);
            refreshEquipmentList(applicationNumber);
        },
        [applicationNumber, toast]
    );

    const onEquipmentAddClose = useCallback(() => {
        setShowEquipmentAdd(false);
        refreshEquipmentList(applicationNumber);
    }, [applicationNumber]);

    const onEquipmentDelete = useCallback(
        async (equipmentNumber: string, hasApprovedMeasure?: boolean) => {
            let response;
            try {
                if (equipmentNumber) {
                    response = await deleteEquipment(applicationNumber, equipmentNumber);
                }
            } catch (error) {
                toast.error(getErrorMessage(error));
            } finally {
                toast.success(hasApprovedMeasure ? "Approved measure successfully deleted" : response.responseMessage);
            }
            refreshEquipmentList(applicationNumber);
        },
        [applicationNumber, toast]
    );

    const contextValue: EquipmentBlockContextValue = useMemo(
        () => ({
            applicationNumber,
            onEquipmentAdd: () => setShowEquipmentAdd(true),
            onEquipmentAddSubmit,
            onEquipmentAddClose,
        }),
        [applicationNumber, onEquipmentAddClose, onEquipmentAddSubmit]
    );

    return (
        <EquipmentBlockContext.Provider value={contextValue}>
            <div className="application-equipment d-flex flex-column gap-3">
                {showEquipmentAdd && <EquipmentAdd />}
                <EquipmentDeleteModal
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    applicationNumber={applicationNumber}
                    onEquipmentDelete={onEquipmentDelete}
                    equipment={equipment}
                />
                <div className={cn("d-flex justify-content-between gap-3", { "flex-column": isMobile })}>
                    <h2 className="m-0">Equipment</h2>
                    {allowEquipmentEntry && (
                        <Button
                            variant="primary"
                            className="d-flex align-items-center gap-2 px-3p5 py-2 justify-content-center"
                            onClick={() => setShowEquipmentAdd(true)}
                            aria-haspopup="dialog"
                        >
                            <FontAwesomeIcon icon={["fal", "square-plus"]} />
                            Add Equipment
                        </Button>
                    )}
                </div>
                {isLoadingEquipment && (
                    <Spinner className="align-self-center flex-shrink-0" animation="border" role="status">
                        <span className="visually-hidden">Loading equipment list</span>
                    </Spinner>
                )}
                {equipmentList?.length > 0 && (
                    <div className="d-flex flex-column gap-5">
                        {!isMobile ? (
                            <Table className="table-v2">
                                <caption className="visually-hidden">Equipment list</caption>
                                <DataGridHead>
                                    <tr className="fw-bold">
                                        <th className="px-2 py-2 col-6">Equipment</th>
                                        <th className="px-2 py-2 col-6">Quantity</th>
                                        <th className="px-2 py-2 col-3">Actions</th>
                                    </tr>
                                </DataGridHead>
                                <tbody className="table-group-divider">
                                    {equipmentList?.map((e) => (
                                        <tr key={e.equipid}>
                                            <td data-label="Equipment">
                                                <EquipmentLabel equipment={e} className="pt-2 lh-sm small" />
                                            </td>
                                            <td data-label="Quantity" className="py-3 small">
                                                <p className="m-0">{e.quantity}</p>
                                            </td>
                                            <td data-label="Actions" className="px-0">
                                                {allowEquipmentEntry ? (
                                                    <div className="d-flex gap-2 py-2">
                                                        <EditButton
                                                            onClick={() => setActiveEquipment(e)}
                                                            ariaDescribedby={`${e.equipid}-name`}
                                                        />
                                                        {allowEquipmentCopy && (
                                                            <>
                                                                <div className="vr align-self-center" />
                                                                <CopyButton
                                                                    onClick={() => setActiveCopyEquipment(e)}
                                                                    ariaDescribedby={`${e.equipid}-name`}
                                                                />
                                                            </>
                                                        )}
                                                        <div className="vr align-self-center" />
                                                        <DeleteButton
                                                            onClick={() => {
                                                                setShowDeleteModal(true);
                                                                setEquipment(e);
                                                            }}
                                                            ariaDescribedby={`${e.equipid}-name`}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="d-flex gap-2 py-2">
                                                        <ViewButton
                                                            onClick={() => setActiveEquipment(e)}
                                                            ariaDescribedby={`${e.equipid}-name`}
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <div className="gap-4 d-flex flex-column">
                                {equipmentList.map((e) => (
                                    <div key={e.equipid} className="flex-column w-100">
                                        <div className="flex-column px-1 py-1 bg-white bg-opacity-10 row justify-content-center">
                                            <div className="d-flex align-items-center border-bottom">
                                                <EquipmentLabel equipment={e} className="lh-sm py-2 small" />
                                            </div>
                                            <div className="d-flex align-items-center border-bottom">
                                                <p className="m-0 py-2 col-4 fw-bold quantity">Quantity</p>
                                                <p className="m-0 ms-1 ps-2 small">{e.quantity}</p>
                                            </div>
                                            <div className="d-flex align-items-center border-bottom">
                                                <p className="m-0 py-2 col-4 fw-bold">Actions</p>
                                                {allowEquipmentEntry ? (
                                                    <div className="d-flex flex-row gap-1 w-100 align-items-center justify-content-between pe-2">
                                                        <EditButton
                                                            onClick={() => setActiveEquipment(e)}
                                                            ariaDescribedby={`${e.equipid}-name`}
                                                            hideText
                                                        />
                                                        {allowEquipmentCopy && (
                                                            <>
                                                                <div className="vr h-50 align-self-center" />
                                                                <CopyButton
                                                                    onClick={() => setActiveCopyEquipment(e)}
                                                                    ariaDescribedby={`${e.equipid}-name`}
                                                                    hideText
                                                                />
                                                            </>
                                                        )}
                                                        <div className="vr h-50 align-self-center" />
                                                        <DeleteButton
                                                            onClick={() => {
                                                                setShowDeleteModal(true);
                                                                setEquipment(e);
                                                            }}
                                                            ariaDescribedby={`${e.equipid}-name`}
                                                            hideText
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="d-flex w-100 justify-content-end pe-4">
                                                        <ViewButton
                                                            onClick={() => setActiveEquipment(e)}
                                                            ariaDescribedby={`${e.equipid}-name`}
                                                            hideText
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {!isLoadingEquipment && equipmentList?.length === 0 && (
                    <EmptyStatePlaceholder
                        className="py-5"
                        placeholderImage={<EquipmentPlaceholder />}
                        title="No equipment yet"
                        description="This is where you'll find all your equipment"
                    />
                )}

                {activeEquipment && (
                    <EquipmentEdit
                        applicationNumber={applicationNumber}
                        onEquipmentEditSubmit={onEquipmentEditSubmit}
                        onEquipmentEditClose={() => setActiveEquipment(undefined)}
                        item={activeEquipment}
                        readonly={!allowEquipmentEntry}
                    />
                )}
                {activeCopyEquipment && (
                    <EquipmentCopy
                        applicationNumber={applicationNumber}
                        onEquipmentCopySubmit={onEquipmentCopySubmit}
                        onEquipmentCopyClose={() => setActiveCopyEquipment(undefined)}
                        item={activeCopyEquipment}
                    />
                )}
            </div>
        </EquipmentBlockContext.Provider>
    );
}

const ViewButton = ({
    onClick,
    ariaDescribedby,
    hideText = false,
}: {
    onClick: () => void;
    ariaDescribedby: string;
    hideText?: boolean;
}) => {
    return (
        <Button
            className="d-flex align-items-center gap-2 py-0"
            variant="link"
            disabled={isInIframe()}
            onClick={onClick}
            aria-describedby={ariaDescribedby}
            aria-haspopup="dialog"
            title="View Equipment"
        >
            <FontAwesomeIcon icon={["fal", "circle-arrow-right"]} />
            {!hideText && "View"}
        </Button>
    );
};

const EditButton = ({
    onClick,
    ariaDescribedby,
    hideText = false,
}: {
    onClick: () => void;
    ariaDescribedby: string;
    hideText?: boolean;
}) => {
    return (
        <Button
            className="d-flex align-items-center gap-2 py-0"
            variant="link"
            disabled={isInIframe()}
            onClick={onClick}
            aria-describedby={ariaDescribedby}
            aria-haspopup="dialog"
            title="Edit Equipment"
        >
            <FontAwesomeIcon icon={["fal", "pen-to-square"]} />
            {!hideText && "Edit"}
        </Button>
    );
};

const DeleteButton = ({ onClick, ariaDescribedby, hideText }: { onClick: () => void; ariaDescribedby: string; hideText?: boolean }) => {
    return (
        <Button
            className="d-flex align-items-center gap-2 py-0 text-danger"
            variant="link"
            disabled={isInIframe()}
            onClick={onClick}
            aria-describedby={ariaDescribedby}
            aria-haspopup="dialog"
            title="Delete Equipment"
        >
            <FontAwesomeIcon icon={["fal", "trash-can"]} />
            {!hideText && "Delete"}
        </Button>
    );
};

const CopyButton = ({
    onClick,
    ariaDescribedby,
    hideText = false,
}: {
    onClick: () => void;
    ariaDescribedby: string;
    hideText?: boolean;
}) => {
    return (
        <Button
            className="d-flex align-items-center gap-2 py-0"
            variant="link"
            onClick={onClick}
            aria-describedby={ariaDescribedby}
            aria-haspopup="dialog"
            title="Copy Equipment"
        >
            <FontAwesomeIcon icon={["fal", "clone"]} />
            {!hideText && "Copy"}
        </Button>
    );
};

const EquipmentDeleteModal = ({
    showDeleteModal,
    setShowDeleteModal,
    applicationNumber,
    onEquipmentDelete,
    equipment,
}: {
    showDeleteModal: boolean;
    setShowDeleteModal: React.Dispatch<any>;
    applicationNumber: string | undefined;
    onEquipmentDelete: (equipmentNumber: string, hasApprovedMeasure?: boolean) => Promise<void>;
    equipment: any;
}) => {
    const [isSubmitting, setIsSubmitting] = useState<any>(false);
    const [equipmentDetails] = useEquipmentDetails(applicationNumber, equipment?.equipid);

    const deleteEquipment = async (equipmentNumber?: string) => {
        const hasApprovedMeasure = equipmentDetails?.attributes?.some((attr: any) => attr.hasApproveMeasure);
        if (equipmentNumber && onEquipmentDelete) {
            await onEquipmentDelete(equipmentNumber, hasApprovedMeasure);
        }
        setShowDeleteModal(false);
        setIsSubmitting(false);
    };

    return (
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} aria-labelledby="delete-equipment-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-equipment-title">Delete equipment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to delete <strong>{equipment?.name}</strong> equipment?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <SubmitButton
                    onClick={() => {
                        deleteEquipment(equipment?.equipid);
                        setIsSubmitting(true);
                    }}
                    isSubmitting={isSubmitting}
                    variant="primary"
                    spinnerText="Is Deleting..."
                >
                    Yes
                </SubmitButton>
                <Button onClick={() => setShowDeleteModal(false)} variant="secondary">
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
