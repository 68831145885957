import { toArray, findRelSchemaAndField, findRelUiSchema } from "../utils";
import { validateFields } from "./validateAction";

function doRemove({ field, schema }, uiSchema) {
    let requiredIndex = schema.required ? schema.required.indexOf(field) : -1;
    if (requiredIndex !== -1) {
        schema.required.splice(requiredIndex, 1);
    }
    delete schema.properties[field];
    delete uiSchema[field];
    let fieldIndex = (uiSchema["ui:order"] ? uiSchema["ui:order"] : []).indexOf(field);
    if (fieldIndex !== -1) {
        uiSchema["ui:order"].splice(fieldIndex, 1);
    }
}

/**
 * Remove specified field both from uiSchema and schema
 *
 * @param field
 * @param schema
 * @param uiSchema
 * @returns {{schema: *, uiSchema: *}}
 */
function removeField({ field }, schema, uiSchema) {
    toArray(field).forEach((field) => doRemove(findRelSchemaAndField(field, schema), findRelUiSchema(field, uiSchema)));
}

removeField.validate = validateFields("remove", function ({ field }) {
    return field;
});

export default removeField;
