import { PowerBiReportEntityType } from "types/PowerBiReportEntityType";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR from "swr/immutable";
import { isEmpty } from "lodash";
import { PowerBiReportResponse } from "types/PowerBiReportResponse";

export const usePowerBiReport = (
    reportId: string,
    entityNumber?: string,
    entityTypeId?: PowerBiReportEntityType
): [report: PowerBiReportResponse | undefined, isLoading: boolean, error: any] => {
    const query = new URLSearchParams();
    query.append("isPaginated", "false");
    if (entityNumber) {
        query.append("entityNumber", entityNumber);
    }

    if (entityTypeId) {
        query.append("entityTypeID", entityTypeId);
    }

    const baseUrl = !isEmpty(reportId) ? getUrl(process.env.REACT_APP_POWERBI_REPORT_ENDPOINT, { reportId }) : null;
    const queryString = query.toString();
    const url = baseUrl ? baseUrl + "?" + queryString : null;

    const { data, error } = useSWR<PowerBiReportResponse, any>(url, httpGetAuthorized);
    const isLoading = !error && !data;

    return [data, isLoading, error];
};
