import { useCallback } from "react";
import { ButtonGroup, Collapse, Dropdown, Form, InputGroup } from "react-bootstrap";
import { CustomerDashboardModel, DateRanges } from "./types";
import { Icon } from "components/Icon";
import { Button } from "components/Button";
import { useHistory, useLocation } from "react-router";
import { SubmitButton } from "components/Button/SubmitButton";
import cn from "classnames";
import useMediaQuery from "components/utils/useMediaQuery";
import NotificationsMenu from "./NotificationsMenu";
import { DatePickerWithRange } from "components/Input/DateRange";
import { dateToJson } from "components/utils/date";
import { useColor } from "components/utils/color";
import Color from "color";
import { useIsMobileFilters } from "./useIsMobileFilters";
import { MobileFiltersSlideOut } from "./MobileFiltersSlideOut";
import { Tag } from "components/Tag";

const APP_FILTERS_ID = "filters-list";

export const ApplicationListControls = ({ model }: { model: CustomerDashboardModel }) => {
    const isMobile = useIsMobileFilters();

    return isMobile ? <ApplicationListControlsForMobile model={model} /> : <ApplicationListControlsForDesktop model={model} />;
};

export const ApplicationListControlsForMobile = ({ model }: { model: CustomerDashboardModel }) => {
    const { isExporting, onExportData } = model;

    const [filterState, setFilterState] = useQueryParamState("filter");

    return (
        <div className="app-list-controls d-flex flex-column py-4 gap-4">
            <div className="d-flex flex-row align-items-center">
                <NotificationsMenu
                    notificationCount={model.totalNotifications}
                    totalNotes={model.totalNotes}
                    totalTasks={model.totalTasks}
                />
                <div className="d-flex flex-row ms-auto gap-2">
                    <Button
                        variant="secondary"
                        secondaryButtonType="filled"
                        onClick={() => setFilterState(filterState === "0" ? "1" : "0")}
                        title="Filters"
                    >
                        <Icon icon={["fal", "filter"]} />
                    </Button>
                    <SubmitButton
                        variant="secondary"
                        secondaryButtonType="filled"
                        onClick={onExportData}
                        isSubmitting={isExporting}
                        spinnerText="Exporting"
                    >
                        {!isExporting && <Icon className="me-2" icon={["fal", "file-export"]} />}
                        Export CSV
                    </SubmitButton>
                </div>
            </div>
            <SearchForm model={model} />
            <TodoSwitch model={model} />
            <FilterTags model={model} isMobile />
            {filterState === "1" && <MobileFiltersSlideOut show model={model} />}
        </div>
    );
};

export const ApplicationListControlsForDesktop = ({ model }: { model: CustomerDashboardModel }) => {
    const {
        isExporting,
        applicationStatuses,
        selectedCreatedDate,
        startDate,
        endDate,
        withUnreadMessages,
        statuses,
        setStatuses,
        onExportData,
        setWithUnreadMessages,
        onDateRangeChange,
        onStartDateChange,
        onEndDateChange,
    } = model;

    const [filterState, setFilterState] = useQueryParamState("filter");

    const primaryColor = useColor("primary");
    const isPrimaryColorLight = Color(primaryColor).isLight();
    const activeTextColor = isPrimaryColorLight ? "var(--bs-dark)" : "var(--bs-white)";

    return (
        <div className="app-list-controls pt-4">
            <div className="d-flex flex-row align-items-center gap-4p5">
                <SearchForm model={model} />
                <TodoSwitch model={model} />
                <div className="d-flex flex-row ms-auto gap-2 flex-2">
                    <Button
                        variant="secondary"
                        onClick={() => setFilterState(filterState === "0" ? "1" : "0")}
                        aria-controls={APP_FILTERS_ID}
                        aria-expanded={filterState === "1"}
                    >
                        <Icon className="me-2" icon={["fal", "filter"]} />
                        Filters
                    </Button>
                    <SubmitButton variant="secondary" onClick={onExportData} isSubmitting={isExporting} spinnerText="Exporting">
                        {!isExporting && <Icon className="me-2" icon={["fal", "file-export"]} />}
                        Export CSV
                    </SubmitButton>
                </div>
            </div>
            <hr className="my-3" />
            <Collapse in={filterState === "1"}>
                <div id={APP_FILTERS_ID}>
                    <div className="d-flex flex-row align-items-center gap-4 pt-1">
                        <InputGroup className="w-auto align-self-stretch">
                            <Dropdown as={ButtonGroup}>
                                <Dropdown.Toggle
                                    id="created-date-list"
                                    variant="outline-secondary"
                                    className="d-flex align-items-center justify-content-between"
                                    style={
                                        {
                                            width: "calc(var(--btn-font-size) * 8.5)",
                                            "--bs-btn-hover-color": activeTextColor,
                                            "--btn-ghost-text-color-focusActive": activeTextColor,
                                            "--bs-btn-active-color": activeTextColor,
                                            "--bs-btn-border-color": "var(--bs-border-color)",
                                        } as React.CSSProperties
                                    }
                                >
                                    {selectedCreatedDate}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    style={
                                        {
                                            "--bs-btn-active-color": activeTextColor,
                                        } as React.CSSProperties
                                    }
                                >
                                    <Dropdown.Item as="button" onClick={() => onDateRangeChange(DateRanges.LastWeek)}>
                                        {DateRanges.LastWeek}
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" onClick={() => onDateRangeChange(DateRanges.LastMonth)}>
                                        {DateRanges.LastMonth}
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" onClick={() => onDateRangeChange(DateRanges.Custom)}>
                                        {DateRanges.Custom}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        as="button"
                                        onClick={() => onDateRangeChange(DateRanges.Created)}
                                        className="btn btn-link text-center"
                                    >
                                        Reset
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <DatePickerWithRange
                                id="created-date-picker"
                                variant="outline-secondary"
                                value={{
                                    from: startDate ? new Date(startDate) : undefined,
                                    to: endDate ? new Date(endDate) : undefined,
                                }}
                                onSelect={(range) => {
                                    onStartDateChange(dateToJson(range?.from) ?? "");
                                    onEndDateChange(dateToJson(range?.to) ?? "");
                                }}
                                style={
                                    {
                                        "--bs-btn-hover-color": activeTextColor,
                                        "--bs-btn-active-color": activeTextColor,
                                        "--btn-ghost-text-color-focusActive": activeTextColor,
                                        "--bs-btn-border-color": "var(--bs-border-color)",
                                    } as React.CSSProperties
                                }
                            />
                        </InputGroup>

                        {applicationStatuses.length > 0 && (
                            <Dropdown as={ButtonGroup} autoClose="outside" className="align-self-stretch flex-shrink-0">
                                <Dropdown.Toggle
                                    id="status-list"
                                    variant="outline-secondary"
                                    className="d-flex align-items-center justify-content-between gap-2 py-0"
                                    style={
                                        {
                                            width: "calc(var(--btn-font-size) * 8.5)",
                                            "--bs-btn-hover-color": activeTextColor,
                                            "--bs-btn-active-color": activeTextColor,
                                            "--btn-ghost-text-color-focusActive": activeTextColor,
                                            "--bs-btn-border-color": "var(--bs-border-color)",
                                        } as React.CSSProperties
                                    }
                                    data-metadata={JSON.stringify({ name: "Status" })}
                                >
                                    Status{" "}
                                    {statuses.length > 0 && (
                                        <Tag
                                            className="bg-gray-200 fw-bold text-body py-0p5"
                                            value={String(statuses.length)}
                                            tooltip="Filters selected"
                                        />
                                    )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    style={
                                        {
                                            "--bs-btn-active-color": activeTextColor,
                                        } as React.CSSProperties
                                    }
                                >
                                    {applicationStatuses.map((status, index) => (
                                        <Dropdown.Item key={index} as="div">
                                            <Form.Check id={`status-${index}`}>
                                                <Form.Check.Input
                                                    checked={statuses.some((s) => s === status.Status)}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setStatuses([...statuses, status.Status]);
                                                        } else {
                                                            setStatuses(statuses.filter((s) => s !== status.Status));
                                                        }
                                                    }}
                                                />
                                                <Form.Check.Label className="w-100">{status.Status}</Form.Check.Label>
                                            </Form.Check>
                                        </Dropdown.Item>
                                    ))}
                                    <Dropdown.Divider />
                                    <Dropdown.Item as="button" className="btn btn-link text-center" onClick={() => setStatuses([])}>
                                        Reset
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <Form.Check
                            type="switch"
                            id="messages-switch"
                            label="Unread inbox items only"
                            className="text-nowrap min-h-auto"
                            onChange={(e) => setWithUnreadMessages(e.target.checked)}
                            checked={withUnreadMessages}
                        />
                        <Button variant="primary" className="ms-auto flex-shrink-0" onClick={() => model.applyFilters()}>
                            Apply filters
                        </Button>
                    </div>
                    <hr className="my-3" />
                </div>
            </Collapse>
            <FilterTags model={model} />
        </div>
    );
};

const FilterTags = ({ model, isMobile }: { model: CustomerDashboardModel; isMobile?: boolean }) => {
    if (model.filterPairs.length === 0) {
        return null;
    }

    return (
        <div
            className={cn("d-flex flex-row flex-wrap align-items-center gap-2", {
                "mb-3": !isMobile,
            })}
        >
            <div className="d-flex flex-row gap-2 flex-wrap">
                {model.filterPairs.map((filterPair, index) => (
                    <Tag
                        key={index}
                        title={filterPair.title}
                        value={filterPair.displayValue ?? filterPair.value}
                        onRemove={() => model.removeFilterPair(filterPair)}
                    />
                ))}
            </div>
            <Button variant="link" className="ms-auto flex-shrink-0 align-self-end" onClick={() => model.onResetFilters()}>
                Reset all filters
            </Button>
        </div>
    );
};

const TodoSwitch = ({ model }: { model: CustomerDashboardModel }) => {
    return (
        <Form.Check
            type="switch"
            id="todo-switch"
            label="With to-do tasks"
            className="text-nowrap min-h-auto"
            onChange={(e) => model.onToDoTasksChange(e.target.checked)}
            checked={model.withToDoTasks}
        />
    );
};

const SearchForm = ({ model }: { model: CustomerDashboardModel }) => {
    const { searchOptions, selectedSearchOption, setSelectedSearchOption, onSearch } = model;
    const isMobile = useMediaQuery("(max-width: 480px)");

    const primaryColor = useColor("primary");
    const isPrimaryColorLight = Color(primaryColor).isLight();
    const activeTextColor = isPrimaryColorLight ? "var(--bs-dark)" : "var(--bs-white)";

    return (
        <Form onSubmit={onSearch}>
            <InputGroup>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                        id="search-options-list"
                        variant="outline-secondary"
                        className="d-flex align-items-center justify-content-between"
                        style={
                            {
                                width: isMobile ? "auto" : "calc(var(--btn-font-size) * 10)",
                                "--bs-btn-hover-color": activeTextColor,
                                "--btn-ghost-text-color-focusActive": activeTextColor,
                                "--bs-btn-active-color": activeTextColor,
                                "--bs-btn-border-color": "var(--bs-border-color)",
                            } as React.CSSProperties
                        }
                    >
                        {selectedSearchOption.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        style={
                            {
                                "--bs-btn-active-color": activeTextColor,
                            } as React.CSSProperties
                        }
                    >
                        {searchOptions.map((option) => (
                            <Dropdown.Item key={option.key} onClick={() => setSelectedSearchOption(option)}>
                                {option.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <Form.Control aria-label="Search input" placeholder="Search..." name="search" />
                <Button variant="secondary" title="Search" type="submit" value="search">
                    <Icon icon={["fal", "magnifying-glass"]} />
                </Button>
            </InputGroup>
        </Form>
    );
};

const useQueryParamState = (paramName: string) => {
    const history = useHistory();
    const location = useLocation();

    const state = new URLSearchParams(location.search).get(paramName) ?? "0";

    const setState = useCallback(
        (state: string) => {
            // Update the URL to reflect the state.
            const params = new URLSearchParams(location.search);
            params.set(paramName, state);
            history.push({
                pathname: location.pathname,
                search: params.toString(),
            });
        },
        [history, location.pathname, location.search, paramName]
    );

    return [state, setState] as const;
};
