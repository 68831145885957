import { RelationsStatisticsDataResponse } from "./utils";
import { Spinner } from "react-bootstrap";
import { formatMoney } from "components/utils/money";
import { useMediaQuery } from "@mui/material";
import cn from "classnames";
import React, { Suspense } from "react";
import { isInIframe } from "components/utils/dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ApplicationStatusChart = React.lazy(() => import("./ApplicationStatusChart"));

export const RelationStatistics = ({ statistics }: { statistics: RelationsStatisticsDataResponse | undefined }) => {
    const isIFrame = isInIframe();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const chartData = !isIFrame ? statistics?.StatusList?.PortalStatus : statisticsForPortalBuilderPreview.StatusList.PortalStatus;
    const rebatesTotal = !isIFrame ? statistics?.RebatesTotal : statisticsForPortalBuilderPreview.RebatesTotal;

    return (
        <div className="d-flex flex-column gap-2">
            <div className={cn("d-flex flex-nowrap gap-3", { "flex-column": isMobile })}>
                {rebatesTotal && rebatesTotal !== "0" && (
                    <div
                        className={cn(
                            "d-flex bg-white text-nowrap rounded shadow-sm flex-column p-3 align-items-start justify-content-between border",
                            {
                                "ms-auto": !isMobile,
                            }
                        )}
                    >
                        <div className="d-flex gap-1 align-items-center">
                            <FontAwesomeIcon icon={["fal", "circle-dollar"]} color="black" />
                            <span className="m-0 pt-1 text-dark fs-7 lh-1">Rebates</span>
                        </div>
                        <div className="">
                            <span className="text-gray-600">Total</span>
                            <p className="m-0 h7 text-dark fs-2 lh-1">{formatMoney(rebatesTotal)}</p>
                        </div>
                    </div>
                )}
                <Suspense
                    fallback={
                        <Spinner animation="border" size="sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    }
                >
                    <div className={cn("bg-white shadow-sm rounded border w-100 overflow-auto")}>
                        <div className="d-flex ps-4 pt-3 gap-1">
                            <FontAwesomeIcon icon={["fal", "wave-pulse"]} color="black" />
                            <p className="m-0 h6 text-dark fs-7">Applications by Status</p>
                        </div>
                        {chartData && <ApplicationStatusChart data={chartData} />}
                    </div>
                </Suspense>
            </div>
        </div>
    );
};

const statisticsForPortalBuilderPreview = {
    RebatesTotal: "10000",
    StatusList: {
        PortalStatus: [
            {
                Status: "Application Entry",
                Count: "1",
            },
            {
                Status: "Application Received",
                Count: "2",
            },
        ],
    },
};
