import cn from "classnames";
import useMediaQuery from "components/utils/useMediaQuery";
import { EmptyStatePlaceholder } from "../SubmittedApplicationV2/EmptyStatePlaceholder";
import { ReactComponent as EmptyDashboardIcon } from "./empty-dashboard-icon.svg";
import { Button } from "components/Button";

export const EmptyFilteredGrid = ({ onResetFilters }: { onResetFilters: () => void }) => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    return (
        <div
            className={cn("d-flex flex-column align-items-center", {
                "py-5 my-3": !isMobile,
                "pb-5": isMobile,
            })}
        >
            <EmptyStatePlaceholder
                className="pb-3"
                placeholderImage={<EmptyDashboardIcon />}
                title="No results found"
                description="No results match the filter criteria. Reset all filters and try again."
            />
            <Button variant="primary" onClick={onResetFilters}>
                Reset all filters
            </Button>
        </div>
    );
};

export const EmptyGrid = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    return (
        <EmptyStatePlaceholder
            className={cn({
                "py-5 my-3": !isMobile,
                "pb-5": isMobile,
            })}
            placeholderImage={<EmptyDashboardIcon />}
            title="No applications yet"
            description="Your applications will appear here once created."
        />
    );
};
