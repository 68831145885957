import { useContext, useState } from "react";
import { DataGrid } from "components/DataGrid";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { useEquipmentList } from "./useEquipmentList";
import { Button } from "components/Button";
import { Modal, Spinner } from "react-bootstrap";
import { EquipmentBlockContext } from "components/utils/contexts";
import { ErrorSummary } from "components/ErrorSummary";
import { SubmitButton } from "components/Button/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEquipmentDetails } from "./useEquipmentDetails";
import useMediaQuery from "components/utils/useMediaQuery";
import cn from "classnames";

export const EquipmentList = () => {
    const {
        applicationNumber,
        allowEquipmentEntry,
        allowEquipmentCopy,
        onEquipmentAdd,
        onEquipmentEdit,
        onEquipmentCopy,
        onEquipmentDelete,
    } = useContext(EquipmentBlockContext);
    const [equipmentList = [], isLoadingEquipmentList] = useEquipmentList(applicationNumber);
    const [errorSummary, setErrorSummary] = useState<any>();
    const [showMessageModal, setShowMessageModal] = useState(false);

    const [equipment, setEquipment] = useState<any>(false);
    const isMobile = useMediaQuery("(max-width: 768px)");

    if (isLoadingEquipmentList) {
        return (
            <Spinner animation="border" className="align-self-center" role="status">
                <span className="visually-hidden">Loading equipment list...</span>
            </Spinner>
        );
    }

    if (!isLoadingEquipmentList && equipmentList.length === 0) {
        return (
            <div className="d-flex flex-column">
                <span>No equipment added.</span>
                {allowEquipmentEntry && (
                    <Button className="me-auto mt-3" onClick={onEquipmentAdd}>
                        Add Equipment
                    </Button>
                )}
            </div>
        );
    }

    return (
        <>
            <ErrorSummary errorSummary={errorSummary} />
            <EquipmentDeleteModal
                setErrorSummary={setErrorSummary}
                showMessageModal={showMessageModal}
                setShowMessageModal={setShowMessageModal}
                applicationNumber={applicationNumber}
                onEquipmentDelete={onEquipmentDelete}
                equipment={equipment}
            />
            <DataGrid className="application-equipment-list align-middle">
                <DataGridHead>
                    <tr>
                        <th>Equipment</th>
                        <th>Quantity</th>
                        <th>Action</th>
                    </tr>
                </DataGridHead>
                <tbody>
                    {equipmentList.map((equipment, index) => {
                        return (
                            <tr key={index}>
                                <td data-label="Equipment" className="pt-3 pb-3 equipment-cell">
                                    <ul className="mb-0 list-unstyled mt-2 mt-md-0">
                                        <li className="mb-2 d-flex justify-content-between align-items-center">{equipment.name}</li>
                                        <li>
                                            <strong>Product Category: </strong>
                                            {equipment.category}
                                        </li>
                                    </ul>
                                </td>
                                <td data-label="Quantity">{equipment.quantity}</td>
                                <td className="actions-cell">
                                    <div
                                        className={cn("d-flex align-items-center gap-3", {
                                            "w-100 justify-content-between px-4": isMobile,
                                        })}
                                    >
                                        <Button
                                            className="d-flex text-nowrap align-items-center gap-2"
                                            variant="link"
                                            onClick={() => onEquipmentEdit && onEquipmentEdit(equipment)}
                                            title={allowEquipmentEntry ? "Edit Equipment" : "View Equipment"}
                                        >
                                            <FontAwesomeIcon icon={["fal", allowEquipmentEntry ? "pen-to-square" : "circle-arrow-right"]} />
                                            {!isMobile && (allowEquipmentEntry ? "Edit" : "View")}
                                        </Button>
                                        {allowEquipmentCopy && (
                                            <>
                                                {isMobile && <div className="vr h-50 align-self-center" />}
                                                <Button
                                                    className="d-flex text-nowrap align-items-center gap-2"
                                                    variant="link"
                                                    onClick={() => onEquipmentCopy && onEquipmentCopy(equipment)}
                                                    title="Copy Equipment"
                                                >
                                                    <FontAwesomeIcon icon={["fal", "clone"]} />
                                                    {!isMobile && "Copy"}
                                                </Button>
                                            </>
                                        )}

                                        {allowEquipmentEntry && (
                                            <>
                                                {isMobile && <div className="vr h-50 align-self-center" />}
                                                <Button
                                                    className="d-flex text-nowrap align-items-center gap-2 text-danger"
                                                    variant="link"
                                                    onClick={() => {
                                                        setShowMessageModal(true);
                                                        setEquipment(equipment);
                                                    }}
                                                    title="Delete Equipment"
                                                >
                                                    <FontAwesomeIcon icon={["fal", "trash-can"]} />
                                                    {!isMobile && "Delete"}
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </DataGrid>
            {allowEquipmentEntry && (
                <Button className="mt-3" onClick={onEquipmentAdd}>
                    Add Equipment
                </Button>
            )}
        </>
    );
};

const EquipmentDeleteModal = ({
    setShowMessageModal,
    setErrorSummary,
    showMessageModal,
    applicationNumber,
    onEquipmentDelete,
    equipment,
}: {
    setShowMessageModal: React.Dispatch<any>;
    setErrorSummary: React.Dispatch<any>;
    showMessageModal: boolean;
    applicationNumber: string | undefined;
    onEquipmentDelete: ((equipmentNumber: string, hasApprovedMeasure?: boolean | undefined) => void) | undefined;
    equipment: any;
}) => {
    const [isSubmitting, setIsSubmitting] = useState<any>(false);
    const [equipmentDetails] = useEquipmentDetails(applicationNumber, equipment?.equipid);

    const deleteEquipment = async (equipmentNumber?: string) => {
        const hasApprovedMeasure = equipmentDetails?.attributes?.some((attr: any) => attr.hasApproveMeasure);
        try {
            setIsSubmitting(true);
            if (equipmentNumber && onEquipmentDelete) {
                await onEquipmentDelete(equipmentNumber, hasApprovedMeasure);
            }
        } catch (error) {
            setErrorSummary(error);
        } finally {
            setShowMessageModal(false);
            setIsSubmitting(false);
        }
    };

    return (
        <Modal show={showMessageModal} onHide={() => setShowMessageModal(false)} aria-labelledby="delete-equipment-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-equipment-title">Delete equipment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to delete <strong>{equipment?.name}</strong> equipment?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <SubmitButton
                    onClick={() => deleteEquipment(equipment?.equipid)}
                    isSubmitting={isSubmitting}
                    variant="primary"
                    spinnerText="Is Deleting..."
                >
                    Yes
                </SubmitButton>
                <Button onClick={() => setShowMessageModal(false)} variant="secondary">
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
