import { getUrl, httpGetAuthorized } from "components/utils/http";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { ContractorsResponse } from "./types";

export const useApplicationContractors = (
    applicationNumber: string | undefined,
    pageNumber: number,
    pageSize: number,
    searchAttr: string = "",
    sort: string = ""
): [contractors: ContractorsResponse | undefined, isLoading: boolean, error: any] => {
    const [contractors, setContractors] = useState<ContractorsResponse>();
    const baseUrl = applicationNumber ? getUrl(process.env.REACT_APP_APPLICATION_CONTRACTORS_ENDPOINT, { applicationNumber }) : null;
    const query = new URLSearchParams();
    query.append("pageNum", String(pageNumber));
    query.append("recsPerPage", String(pageSize));

    if (!isEmpty(searchAttr)) {
        query.append("searchAttr", searchAttr);
    }

    if (!isEmpty(sort)) {
        query.append("sort", sort);
    }

    const url = applicationNumber ? baseUrl + "?" + query.toString() : null;

    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    // Update data only when not loading anymore. Prevents grid flicker on sort.
    useEffect(() => {
        if (!isLoading) {
            setContractors(data);
        }
    }, [data, isLoading]);

    return [contractors, isLoading, error];
};
