import { AppContext } from "components/App/AppContext";
import { useContext } from "react";
import Color from "color";
import { isString, isNaN, isNil } from "lodash";

/**
 * Get color value from portal colors configuration.
 * colorId is returned if color is not found in configuration.
 * undefined is returned if colorId is not a color.
 */
export const useColor = (colorId?: string, colorOpacity?: string) => {
    const { colors = {} } = useContext(AppContext);
    let color = isNil(colorId) ? undefined : colors[colorId] ?? colorId;
    let opacity = 1;

    if (isString(colorOpacity)) {
        if (colorOpacity === "") {
            opacity = 0;
        } else {
            opacity = parseInt(colorOpacity, 10);
            if (!isNaN(opacity)) {
                opacity = opacity / 100;
            } else {
                opacity = 1;
            }
        }
    }

    if (isColor(color)) {
        return Color(color).alpha(opacity).string();
    }

    return undefined;
};

const isColor = (strColor?: string): boolean => {
    if (isNil(strColor)) {
        return false;
    }

    const s = new Option().style;
    s.color = strColor;
    return s.color !== "";
};

export const getColor = (colorId: string | undefined, opacity: string | undefined, colorMap: { [key: string]: string } | undefined) => {
    const color = isNil(colorId) ? undefined : colorMap?.[colorId] ?? colorId;

    if (isColor(color)) {
        return Color(color).alpha(getOpacity(opacity)).string();
    }

    return undefined;
};

export const getOpacity = (opacity: string | undefined): number => {
    let opacityValue = 1;

    if (isString(opacity)) {
        if (opacity === "") {
            opacityValue = 0;
        } else {
            opacityValue = parseInt(opacity, 10);
            if (!isNaN(opacityValue)) {
                opacityValue = opacityValue / 100;
            } else {
                opacityValue = 1;
            }
        }
    }

    return opacityValue;
};

export const useGradient = (gradientColor: string | undefined, gradientValue: number | undefined, opacity: string | undefined) => {
    let gradient: string | undefined;
    let value = "";

    if (gradientValue) {
        value = gradientValue > 0 ? gradientValue + "%" : "";
    }

    gradient = `${useColor(gradientColor, opacity)} ${value}`;

    return gradient;
};

export const adjustBackgroundContrast = (foregroundColorStr: string, backgroundColorStr: string, minContrast: number = 4.5) => {
    if (!isColor(foregroundColorStr) || !isColor(backgroundColorStr)) {
        return backgroundColorStr;
    }

    const foregroundColor = Color(foregroundColorStr);
    let backgroundColor = Color(backgroundColorStr);
    let isContrastOk = false;
    let remainingAdjustTimes = 40; // Max adjust times to prevent infinite loops

    while (!isContrastOk && remainingAdjustTimes > 0) {
        remainingAdjustTimes--;
        const contrast = foregroundColor.contrast(backgroundColor);
        isContrastOk = contrast >= minContrast;

        if (!isContrastOk) {
            if (foregroundColor.isLight()) {
                backgroundColor = backgroundColor.darken(0.1);
            } else {
                backgroundColor = backgroundColor.lighten(0.1);
            }
        }
    }

    return backgroundColor.string();
};

const rgbaToRgb = (foreground: Color, background: Color) => {
    const [fgR, fgG, fgB] = foreground.rgb().array();
    const [bgR, bgG, bgB] = background.rgb().array();
    const fgA = foreground.alpha();
    const bgA = background.alpha();

    const mixA = fgA + bgA * (1 - fgA);

    const mixR = Math.round((fgR * fgA + bgR * bgA * (1 - fgA)) / mixA);
    const mixG = Math.round((fgG * fgA + bgG * bgA * (1 - fgA)) / mixA);
    const mixB = Math.round((fgB * fgA + bgB * bgA * (1 - fgA)) / mixA);

    return Color({ r: mixR, g: mixG, b: mixB }).alpha(mixA);
};

const calculateContrast = (luminance1: number, luminance2: number): number => {
    const L1 = Math.max(luminance1, luminance2);
    const L2 = Math.min(luminance1, luminance2);

    return (L1 + 0.05) / (L2 + 0.05);
};

const calculateIdialContrast = (baseLuminance: number, isColorLighter: boolean, minContrast: number): number => {
    // rearenged W3C formula (L1 + 0.05) / (L2 + 0.05) to calculate contrast ratio
    if (!isColorLighter) {
        return (baseLuminance + 0.05) / minContrast - 0.05;
    }

    return minContrast * (baseLuminance + 0.05) - 0.05;
};

const toLinearRGB = (channel: number): number => {
    const srgb = channel / 255;

    if (srgb <= 0.04045) {
        return srgb / 12.92;
    }

    return Math.pow((srgb + 0.055) / 1.055, 2.4);
};

const toSRGB = (channel: number): number => {
    if (channel <= 0.0031308) {
        return channel * 12.92;
    }

    return 1.055 * channel ** (1 / 2.4) - 0.055;
};

const adjustLuminance = (color: Color, targetLuminance: number) => {
    const [r, g, b] = color.rgb().array();
    const luminanceRatio = targetLuminance / color.luminosity();

    const newR = Math.min(255, Math.max(0, Math.round(toSRGB(toLinearRGB(r) * luminanceRatio) * 255)));
    const newG = Math.min(255, Math.max(0, Math.round(toSRGB(toLinearRGB(g) * luminanceRatio) * 255)));
    const newB = Math.min(255, Math.max(0, Math.round(toSRGB(toLinearRGB(b) * luminanceRatio) * 255)));

    return Color.rgb([newR, newG, newB]);
};

export const adjustColorContrast = (
    initialTargeColorValue: string | undefined,
    initialBaseColorValue: string | undefined,
    minContrast: number = 4.6
) => {
    if (!isColor(initialTargeColorValue) || !isColor(initialBaseColorValue)) {
        return Color();
    }

    const initialTargetColor = Color(initialTargeColorValue);
    const baseColor = Color(initialBaseColorValue);

    const targetColor = rgbaToRgb(initialTargetColor, baseColor);

    const targetLuminance = targetColor?.luminosity() ?? 0;
    const baseLuminance = baseColor?.luminosity() ?? 0;

    const initialContrast = calculateContrast(targetLuminance, baseLuminance);
    if (initialContrast >= minContrast) {
        return targetColor;
    }

    const isColorLighter = targetLuminance > baseLuminance;
    const idealLuminance = calculateIdialContrast(baseLuminance, isColorLighter, minContrast);
    return adjustLuminance(targetColor, idealLuminance);
};

export const getCssColor = (variable: string) => {
    return getComputedStyle(document.documentElement).getPropertyValue(variable)?.trim();
};
