import cn from "classnames";
import { AppContext } from "components/App/AppContext";
import { SubmitButton } from "components/Button/SubmitButton";
import { useContext } from "react";
import { Button } from "react-bootstrap";

const DEFAULT_BACK_BUTTON_TEXT = "Back";
const DEFAULT_CONTINUE_BUTTON_TEXT = "Continue";
const DEFAULT_SAVE_DRAFT_BUTTON_TEXT = "Save & Complete Later";
const DEFAULT_COMPLETE_APPLICATION_BUTTON_TEXT = "Complete Application";

const BACK_BUTTON_METADATA = JSON.stringify({ name: DEFAULT_BACK_BUTTON_TEXT });
const CONTINUE_BUTTON_METADATA = JSON.stringify({ name: DEFAULT_CONTINUE_BUTTON_TEXT });
const SAVE_DRAFT_BUTTON_METADATA = JSON.stringify({ name: DEFAULT_SAVE_DRAFT_BUTTON_TEXT });
const COMPLETE_APPLICATION_BUTTON_METADATA = JSON.stringify({ name: DEFAULT_COMPLETE_APPLICATION_BUTTON_TEXT });

export const ApplicationControls: React.FC<ApplicationControlsProps> = (props) => {
    const { settings } = useContext(AppContext);

    const wizardButtonBackText = (settings.wizardButtonBackText ?? "").trim() || DEFAULT_BACK_BUTTON_TEXT;
    const wizardButtonContinueText = (settings.wizardButtonContinueText ?? "").trim() || DEFAULT_CONTINUE_BUTTON_TEXT;
    const wizardButtonSaveDraftText = (settings.wizardButtonSaveDraftText ?? "").trim() || DEFAULT_SAVE_DRAFT_BUTTON_TEXT;
    const wizardButtonCompleteApplicationText =
        (settings.wizardButtonCompleteApplicationText ?? "").trim() || DEFAULT_COMPLETE_APPLICATION_BUTTON_TEXT;

    return (
        <div className="application-page-controls d-flex flex-row align-items-center justify-content-center mb-4">
            {!props.isFirstPage && (
                <Button onClick={props.onBack} data-metadata={BACK_BUTTON_METADATA}>
                    {wizardButtonBackText}
                </Button>
            )}
            {!props.isLastPage && !props.isDisqualificationPage && (
                <SubmitButton
                    className={cn({ "ms-2": !props.isFirstPage })}
                    isSubmitting={props.isProcessing}
                    onClick={props.onContinue}
                    spinnerText={"Submitting..."}
                    data-metadata={CONTINUE_BUTTON_METADATA}
                >
                    {wizardButtonContinueText}
                </SubmitButton>
            )}
            {props.isLastPage && !props.isDisqualificationPage && (
                <SubmitButton
                    className={cn({ "ms-2": !props.isFirstPage })}
                    isSubmitting={props.isProcessing}
                    onClick={props.onContinue}
                    spinnerText={"Submitting..."}
                    data-metadata={COMPLETE_APPLICATION_BUTTON_METADATA}
                >
                    {wizardButtonCompleteApplicationText}
                </SubmitButton>
            )}
            {props.onSaveDraft && !props.isDisqualificationPage && (
                <>
                    <div className="flex-shrink-0 me-2 ms-2">- OR -</div>
                    <SubmitButton
                        variant="secondary"
                        isSubmitting={props.isProcessing}
                        onClick={props.onSaveDraft}
                        spinnerText={"Submitting..."}
                        disabled={props.isDisqualificationPage}
                        data-metadata={SAVE_DRAFT_BUTTON_METADATA}
                    >
                        {wizardButtonSaveDraftText}
                    </SubmitButton>
                </>
            )}
        </div>
    );
};

interface ApplicationControlsProps {
    isFirstPage: boolean;
    isLastPage: boolean;
    onBack?: () => void;
    onContinue: () => void;
    onSaveDraft?: () => void;
    isProcessing?: boolean;
    isDisqualificationPage: boolean;
}
