export const MAX_FILE_SIZE = 1024 * 1024 * 30; // 30MB
export const MAX_FILE_SIZE_TEXT = "30MB";

export function processDataURL(dataURL: string, name: string) {
    const encodedName = encodeURIComponent(name);
    let url = dataURL;

    // Form validation fails if file type has upper case chars
    if (url.startsWith("data:application/vnd.ms-excel.sheet.macroEnabled.12;")) {
        url = url.replace("vnd.ms-excel.sheet.macroEnabled.12", "vnd.ms-excel.sheet.macroenabled.12");
    }

    // Allow to submit empty File
    if (url === "data:") {
        return `${url};name=${encodedName};base64,`;
    }

    return url.replace(";base64", `;name=${encodedName};base64`);
}

export function processFile(file: File) {
    const { name, size, type } = file;
    return new Promise<FileInfo>((resolve, reject) => {
        const reader = new window.FileReader();
        reader.onerror = reject;
        reader.onload = (event) => {
            const dataURL = event.target?.result as string;
            resolve({
                dataURL: processDataURL(dataURL, name),
                name,
                size,
                type,
            });
        };
        reader.readAsDataURL(file);
    });
}

export function processFiles(files: File[]) {
    return Promise.all([].map.call(files, processFile)) as Promise<FileInfo[]>;
}

export function dataURItoBlob(dataURI: string) {
    // Split metadata from data
    var splitted = dataURI.split(",");
    // Split params
    var params = splitted[0].split(";");
    // Get mime-type from params
    var type = params[0].replace("data:", "");
    // Filter the name property from params
    var properties = params.filter(function (param) {
        return param.split("=")[0] === "name";
    });
    // Look for the name and use unknown if no name property.
    var name = "";
    if (properties.length !== 1) {
        name = "unknown";
    } else {
        // Because we filtered out the other property,
        // we only have the name case here.
        name = decodeURIComponent(properties[0].split("=")[1]);
    }

    // Built the Uint8Array Blob parameter from the base64 string.
    var binary = window.atob(splitted[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    // Create the blob object
    var blob = new window.Blob([new Uint8Array(array)], { type: type });

    return { blob: blob, name: name };
}

export function extractFileInfo(dataURLs: string[]) {
    return dataURLs
        .filter((dataURL) => typeof dataURL !== "undefined")
        .map((dataURL) => {
            try {
                const { blob, name } = dataURItoBlob(dataURL);

                return { dataURL, blob, name };
            } catch {
                return null;
            }
        })
        .filter((fileData) => fileData !== null)
        .map(({ dataURL, blob, name }: any) => {
            const decodedName = decodeURIComponent(name);

            return {
                name: decodedName,
                dataURL,
                size: blob.size,
                type: blob.type,
            };
        });
}

const convertToCSV = (data: any) => {
    let result = "";

    if (!data || data === null || !data.length) {
        return result;
    }

    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    let keys = Object.keys(data[0]);
    let ctr = 0;

    data.forEach((item: any) => {
        ctr = 0;

        keys.forEach((key) => {
            if (ctr > 0) {
                result += columnDelimiter;
            }

            // Escape quotes in value and wrap the value in quotes to support commas
            result += item[key] ? '"' + String(item[key]).replace(/"/g, '""') + '"' : '""';
            ctr++;
        });

        result += lineDelimiter;
    });

    return result;
};

export const download = (blob: Blob, fileName: string) => {
    if (blob && fileName) {
        const link = document.createElement("a");
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.setAttribute("download", fileName);
        link.click();
    }
};

export const print = (blob: Blob, fileName: string) => {
    if (!blob || !fileName) {
        return;
    }

    const blobURL = URL.createObjectURL(blob);
    const frame = document.createElement("iframe");

    const closePrint = () => {
        document.body.removeChild(frame);
        URL.revokeObjectURL(blobURL);
    };

    frame.onload = () => {
        if (frame.contentWindow) {
            frame.contentWindow.onbeforeunload = closePrint;
            frame.contentWindow.onafterprint = closePrint;
            frame.contentWindow.focus(); // Required for IE
            frame.contentWindow.print();
        }
    };

    frame.style.position = "fixed";
    frame.style.right = "0";
    frame.style.bottom = "0";
    frame.style.width = "0";
    frame.style.height = "0";
    frame.style.border = "0";
    frame.src = blobURL;
    frame.title = fileName;
    document.body.appendChild(frame);
};

export const downloadCSV = (data: any, fileName: string) => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

    download(blob, fileName);
};

export interface FileInfo {
    name: string;
    dataURL: string;
    size: number;
    type: string;
}

export interface RejectedFile {
    name: string;
    size: number;
}
