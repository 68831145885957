import { useState } from "react";
import { Accordion, Form, Offcanvas } from "react-bootstrap";
import { CustomerDashboardModel, DateRanges, SortOptions } from "./types";
import { Button } from "components/Button";
import { useHistory, useLocation } from "react-router";
import { isEmpty } from "lodash";
import { getDateRange } from "./useCustomerDashboardModel";
import { DatePickerWithRange } from "components/Input/DateRange";
import { dateToJson } from "components/utils/date";

export const MobileFiltersSlideOut = ({ show, model }: { show: boolean; model: CustomerDashboardModel }) => {
    const { activeSortOption, onResetFilters } = model;

    const location = useLocation();
    const history = useHistory();

    const [withUnreadMessages, setWithUnreadMessages] = useState(model.withUnreadMessages);

    const [startDate, setStartDate] = useState(model.startDate);
    const [endDate, setEndDate] = useState(model.endDate);
    const [selectedCreatedDate, setSelectedCreatedDate] = useState(model.selectedCreatedDate);

    const [statuses, setStatuses] = useState(model.statuses);

    const onDateRangeChange = (dateRange: string) => {
        setSelectedCreatedDate(dateRange);
        const range = getDateRange(dateRange);
        setStartDate(range.startDate);
        setEndDate(range.endDate);
    };

    const onStartDateChange = (startDate: string) => {
        setStartDate(startDate);
        setSelectedCreatedDate(DateRanges.Custom);
    };

    const onEndDateChange = (endDate: string) => {
        setEndDate(endDate);
        setSelectedCreatedDate(DateRanges.Custom);
    };

    const onStatusChange = (status: string) => {
        if (statuses.includes(status)) {
            setStatuses((statuses) => statuses.filter((s) => s !== status));
        } else {
            setStatuses((statuses) => [...statuses, status]);
        }
    };

    const onClose = () => {
        const params = new URLSearchParams(location.search);
        params.set("filter", "0");
        history.push({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const sortOption = formData.get("sort") as string;
        if (!isEmpty(sortOption)) {
            model.sort(sortOption);
        }

        model.applyFilters({
            withUnreadMessages,
            statuses,
            startDate: startDate ?? "",
            endDate: endDate ?? "",
            selectedCreatedDate,
        });

        onClose();
    };

    const onReset = () => {
        onResetFilters();

        // reset unread messages
        setWithUnreadMessages(false);

        // reset dates
        onDateRangeChange(DateRanges.Created);

        // reset statuses
        setStatuses([]);
    };

    return (
        <Offcanvas show={show} placement="end" onHide={onClose} aria-labelledby="filters-sidebar-title">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="filters-sidebar-title" as="h5">
                    Sorting and filters
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column p-0 gap-3 p-3 app-list-controls">
                <Button variant="link" className="flex-shrink-0 align-self-start" onClick={onReset}>
                    Reset all filters
                </Button>
                <Form onSubmit={onSubmit}>
                    <Accordion
                        className="apps-filter-accordion d-flex flex-column gap-2"
                        defaultActiveKey={["0", "1", "2", "3"]}
                        alwaysOpen
                        style={
                            {
                                "--bs-accordion-border-radius": "0",
                                "--bs-accordion-border-width": "0",
                                "--bs-accordion-inner-border-radius": "0",
                                "--bs-accordion-bg": "var(--bs-gray-100)",
                                "--bs-accordion-btn-bg": "var(--bs-white)",
                                "--bs-accordion-active-bg": "var(--bs-gray-200)",
                                "--bs-accordion-active-color": "var(--bs-body-color)",
                            } as React.CSSProperties
                        }
                    >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="light-bg border-bottom" as="h6">
                                Sort by
                            </Accordion.Header>
                            <Accordion.Body className="border-bottom">
                                <Form.Check
                                    type="radio"
                                    id="sort-newest"
                                    label={SortOptions.Newest}
                                    name="sort"
                                    value={SortOptions.Newest}
                                    defaultChecked={activeSortOption === SortOptions.Newest}
                                />
                                <Form.Check
                                    type="radio"
                                    id="sort-oldest"
                                    label={SortOptions.Oldest}
                                    name="sort"
                                    value={SortOptions.Oldest}
                                    defaultChecked={activeSortOption === SortOptions.Oldest}
                                />
                                <Form.Check
                                    type="radio"
                                    id="sort-bookmarked"
                                    label={SortOptions.BookmarkedFirst}
                                    name="sort"
                                    value={SortOptions.BookmarkedFirst}
                                    defaultChecked={activeSortOption === SortOptions.BookmarkedFirst}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="light-bg border-bottom" as="h6">
                                Inbox
                            </Accordion.Header>
                            <Accordion.Body className="border-bottom">
                                <Form.Check
                                    type="radio"
                                    id="inbox-all"
                                    label="All"
                                    name="inbox"
                                    value="All"
                                    checked={!withUnreadMessages}
                                    onChange={() => setWithUnreadMessages(false)}
                                />
                                <Form.Check
                                    type="radio"
                                    id="inbox-unread"
                                    label="Unread only"
                                    name="inbox"
                                    value="Unread only"
                                    checked={withUnreadMessages}
                                    onChange={() => setWithUnreadMessages(true)}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header className="light-bg border-bottom" as="h6">
                                Created
                            </Accordion.Header>
                            <Accordion.Body className="border-bottom">
                                <Form.Check
                                    type="radio"
                                    id="created-any-time"
                                    label="Any time"
                                    name="created"
                                    value={DateRanges.Created}
                                    checked={selectedCreatedDate === DateRanges.Created}
                                    onChange={() => onDateRangeChange(DateRanges.Created)}
                                />
                                <Form.Check
                                    type="radio"
                                    id="created-last-week"
                                    label={DateRanges.LastWeek}
                                    name="created"
                                    value={DateRanges.LastWeek}
                                    checked={selectedCreatedDate === DateRanges.LastWeek}
                                    onChange={() => onDateRangeChange(DateRanges.LastWeek)}
                                />
                                <Form.Check
                                    type="radio"
                                    id="created-last-month"
                                    label={DateRanges.LastMonth}
                                    name="created"
                                    value={DateRanges.LastMonth}
                                    checked={selectedCreatedDate === DateRanges.LastMonth}
                                    onChange={() => onDateRangeChange(DateRanges.LastMonth)}
                                />
                                <Form.Check
                                    className="mb-3"
                                    type="radio"
                                    id="created-custom"
                                    label={DateRanges.Custom}
                                    name="created"
                                    value={DateRanges.Custom}
                                    checked={selectedCreatedDate === DateRanges.Custom}
                                    onChange={() => onDateRangeChange(DateRanges.Custom)}
                                />
                                <DatePickerWithRange
                                    id="created-date-picker"
                                    className="border"
                                    variant="outline-secondary"
                                    vertical
                                    value={{
                                        from: startDate ? new Date(startDate) : undefined,
                                        to: endDate ? new Date(endDate) : undefined,
                                    }}
                                    onSelect={(range) => {
                                        onStartDateChange(dateToJson(range?.from) ?? "");
                                        onEndDateChange(dateToJson(range?.to) ?? "");
                                    }}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header className="light-bg border-bottom" as="h6">
                                Status
                            </Accordion.Header>
                            <Accordion.Body className="border-bottom">
                                {model.applicationStatuses.map((status, index) => (
                                    <Form.Check
                                        key={index}
                                        id={`status-${index}`}
                                        label={status.Status}
                                        name="status"
                                        value={status.Status}
                                        checked={statuses.some((s) => s === status.Status)}
                                        onChange={() => onStatusChange(status.Status)}
                                    />
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="d-flex flex-row gap-2 align-items-start pt-2">
                        <Button variant="primary" type="submit">
                            Apply filters
                        </Button>
                        <Button variant="secondary" onClick={() => onClose()}>
                            Close
                        </Button>
                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};
