import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { isDate, isEmpty, isString, padStart } from "lodash";
import { isIEBrowser } from "./dom";
import { removeNonASCIICharacters } from "./string";
import { isValid, parse } from "date-fns";
import { getSessionStorageItem } from "components/utils";

const DEFAULT_LOCALE = process.env.REACT_APP_DATE_FORMAT_LOCALE;

const DEFAULT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
};

export const DATEPICKER_DATE_FORMAT = process.env.REACT_APP_DATEPICKER_DATE_FORMAT as string;

export const dateToJson = (date?: Date) => {
    if (date instanceof Date && !isNaN(date as any)) {
        return formatISO(date, { representation: "date" });
    }

    return null;
};

export const jsonDateToDate = (dateStr: string) => {
    if (!isString(dateStr)) {
        return dateStr;
    }

    const date = parseISO(dateStr);

    return isNaN(date as any) ? NaN : date;
};

export const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
};

export const serverDateToLocal = (date: Date) => {
    const storedUser = getSessionStorageItem("user");
    const serverTimezoneOffset = storedUser ? JSON.parse(storedUser).serverTimezoneOffset : 0;

    const localOffset = getBrowserTimezoneOffset();
    const serverOffset = serverTimezoneOffset;

    let adjustedDate = addMinutes(date, -serverOffset);
    adjustedDate = addMinutes(adjustedDate, -localOffset);

    return adjustedDate;
};

export const formatJsonDate = (dateStr?: string | null, defaultValue = "") => {
    if (!isString(dateStr)) {
        return defaultValue;
    }

    const date = jsonDateToDate(dateStr);

    if (!isDate(date)) {
        return dateStr;
    }

    const convertedDateTimeStr = date.toLocaleString(DEFAULT_LOCALE, DEFAULT_DATE_OPTIONS);

    return isIEBrowser() ? removeNonASCIICharacters(convertedDateTimeStr) : convertedDateTimeStr;
};

export const formatJsonDateTime = (dateStr: string | null | undefined, defaultValue = "", hideSeconds?: boolean, separator?: string) => {
    if (!isString(dateStr)) {
        return defaultValue;
    }

    let date = jsonDateToDate(dateStr);

    if (!isDate(date)) {
        return dateStr;
    }

    date = serverDateToLocal(date);

    const newDate = format(date, hideSeconds ? "PPp" : "PPpp");

    if (newDate && separator) {
        return newDate.replace(/,([^,]*)$/, ` ${separator}$1`);
    }
    return newDate;
};

export const formatDateAndTime: any = (dateStr: string | undefined, defaultValue = "", separateDateAndTime = false) => {
    if (!isString(dateStr)) {
        return defaultValue;
    }

    let date = jsonDateToDate(dateStr);

    if (!isDate(date)) {
        return dateStr;
    }

    date = serverDateToLocal(date);

    if (separateDateAndTime) {
        const formattedDate = date.toLocaleDateString("en-us", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });

        const formattedTime = date.toLocaleTimeString("en-us", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });

        return { formattedDate, formattedTime };
    }

    return format(date, "PP • hh:mm aaa");
};

/**
 * Returns beginning of day for a given date time.
 */
export const getDateWithoutTime = (date: Date) => {
    let datePart = new Date(date);
    datePart.setHours(0);
    datePart.setMinutes(0);
    datePart.setSeconds(0);
    datePart.setMilliseconds(0);
    return datePart;
};

/**
 * Convert input value to date used in datepicker
 */
export const getDateValueForInput = (value: string, format?: string) => {
    let stringValue = String(value);

    if (stringValue.indexOf("/") > -1) {
        stringValue = dateStringToJsonDate(stringValue, format) ?? "";
    }

    const jsonDate = datePartFromJsonDate(stringValue);
    const date = jsonDateToDate(jsonDate);

    const dateValue = typeof value === "undefined" ? null : isDate(date) ? date : null;

    return dateValue;
};

export const isValidDateFormatFromInput = (fieldVal: string | undefined) => {
    const formats = ["yyyy-MM-dd", "yyyy-MM-dd'T'HH:mm:ss", "MM/dd/yyyy"];
    for (let format of formats) {
        const date = parse(fieldVal ?? "", format, new Date());
        if (isValid(date)) {
            return true;
        }
    }
    return false;
};

export const dateStringToJsonDate = (value: string, format?: string) => {
    if (!isString(value)) {
        return value;
    }

    if (format === "date-time") {
        const date = parse(value, `${DATEPICKER_DATE_FORMAT} h:mm a`, new Date());

        if (isValidDate(date)) {
            // Convert to json date and remove the seconds and timezone part
            return formatISO(date, { representation: "complete" }).substring(0, 16);
        } else {
            return value;
        }
    }

    if (format === "time") {
        const date = parse(value, "h:mm a", new Date());

        if (isValidDate(date)) {
            // Convert to json date and take only time part
            return formatISO(date, { representation: "complete" }).substring(11, 16);
        } else {
            return value;
        }
    }

    if (value.indexOf("/") > -1) {
        const formatParts = DATEPICKER_DATE_FORMAT.split("/");
        const valueParts = value.split("/");

        if (formatParts.length === valueParts.length) {
            let yyyy = "";
            let MM = "";
            let dd = "";

            // If first part is a year then consider the format provided is "YYYY/MM/DD"
            if (valueParts[0].length === 4) {
                yyyy = valueParts[0];
                MM = valueParts[1];
                dd = valueParts[2];
            } else {
                yyyy = valueParts[formatParts.indexOf("yyyy")];
                MM = valueParts[formatParts.indexOf("MM")];
                dd = valueParts[formatParts.indexOf("dd")];
            }

            return `${yyyy}-${MM}-${dd}`;
        }
    }

    return dateToJson(parseISO(value));
};

export const datePartFromJsonDate = (dateStr: string) => {
    if (isString(dateStr) && !isEmpty(dateStr)) {
        return dateStr.slice(0, 10);
    }

    return "";
};

/**
 * Change date string format: 2020-05-10 => 05/10/2020
 * Time part is removed.
 *
 * @param {string} value Json date value like 2020-05-10
 */
export const localizeJsonDate = (value: string) => {
    if (!isString(value)) {
        return value;
    }

    let result = value;
    const datePart = (result || "").split("T")[0];

    if (datePart) {
        // Parts of json date
        const valueParts = datePart.split("-");

        if (valueParts.length === 3) {
            result = DATEPICKER_DATE_FORMAT.replace("dd", padStart(valueParts[2], 2, "0"))
                .replace("MM", padStart(valueParts[1], 2, "0"))
                .replace("yyyy", valueParts[0]);
        }
    }

    return result;
};

/**
 * Change date time string format: 2020-05-10T08:16:00 => 05/10/2020 8:16 AM
 *
 * @param {string} value Json date value like 2020-05-10T08:16:00
 * @returns {string}
 */
export const localizeJsonDateTime = (value: string) => {
    if (!isString(value)) {
        return value;
    }

    const date = jsonDateToDate(value);

    if (!isDate(date)) {
        return value;
    }

    const datePart = localizeJsonDate(value);
    const timePart = dateToTimeString(date);

    return `${datePart} ${timePart}`;
};

/**
 * Change date time string format: 08:16 => 8:16 AM
 *
 * @param {string} value Json date value like 2020-05-10T08:16:00
 * @returns
 */
export const localizeJsonTime = (value: string) => {
    if (!isString(value)) {
        return value;
    }

    const currentJsonDate = formatISO(new Date(), { representation: "date" });
    const date = jsonDateToDate(currentJsonDate + "T" + value);

    if (!isDate(date)) {
        return value;
    }

    return dateToTimeString(date);
};

/**
 * Format the date to time string like "8:16 AM"
 * @param {Date} date
 * @returns string
 */
export const dateToTimeString = (date: Date) => {
    return format(date, "h:mm a");
};

export const getBrowserTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
};

export const isValidDate = (obj: unknown) => {
    return isDate(obj) && obj.toString() !== "Invalid Date";
};

export const validateDate = (date: string | null) => {
    if (!date || date.length > 10) {
        return false;
    }

    const valueParts = date.split("-");
    let year = Number(valueParts[0]);

    if (!(year > 1900 && year < 2100)) {
        return false;
    }

    const dateObj = new Date(date);
    return isValidDate(dateObj);
};
