import { ColorType } from "./ColorType";

export interface BackgroundProperties {
    backgroundOff?: boolean;
    backgroundWidth?: string;
    colorType?: ColorType;

    // Solid color props
    backgroundColor?: string;
    backgroundColorOpacity?: string;

    // Gradient color props
    firstGradientValue?: number;
    firstGradientColor?: string;
    secondGradientValue?: number;
    secondGradientColor?: string;
    gradientDirection?: string;

    // Image props
    backgroundImage?: string;
    backgroundRepeat?: string;
    backgroundPosition?: string;
    backgroundSize?: string;
}

export const BACKGROUND_PROPERTY_KEYS: (keyof BackgroundProperties)[] = [
    "backgroundOff",
    "backgroundWidth",
    "colorType",

    // Solid color props
    "backgroundColor",
    "backgroundColorOpacity",

    // Gradient color props
    "firstGradientColor",
    "firstGradientValue",
    "secondGradientColor",
    "secondGradientValue",
    "gradientDirection",

    // Image props
    "backgroundImage",
    "backgroundRepeat",
    "backgroundPosition",
    "backgroundSize",
];
