import { formatDateAndTime } from "components/utils/date";
import { isInIframe } from "components/utils/dom";
import { formatMoney } from "components/utils/money";
import { useState } from "react";
import { Card, Offcanvas, Table } from "react-bootstrap";
import { Button } from "components/Button";
import { ApplicationRebate } from "types/SubmittedAppData";
import { ReactComponent as PaymentsPlaceholder } from "./assets/payments-placeholder.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getContactColor, getContactName } from "components/ContactCard/utils";
import { Avatar } from "components/Avatar";
import { isNil } from "lodash";
import useMediaQuery from "components/utils/useMediaQuery";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import cn from "classnames";
import { EmptyStatePlaceholder } from "./EmptyStatePlaceholder";

import "./style.scss";

export function ApplicationPayments({ rebates }: Readonly<ApplicationPaymentsProps>) {
    const [activeRebate, setActiveRebate] = useState<ApplicationRebate | null>();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const rebatesTotal = rebates?.reduce((acc, curr) => {
        const amountAsNumber = parseFloat(curr.amount);

        if (!isNil(amountAsNumber)) {
            return acc + amountAsNumber;
        } else {
            return acc;
        }
    }, 0);

    return (
        <div className="application-payments d-flex flex-column gap-3">
            <h2 className="m-0">Payments</h2>
            {rebates.length > 0 ? (
                <div className="d-flex flex-column gap-4 pb-3">
                    <Card className="total-amount-received rounded-3 border border-1 border-black border-opacity-10 col-4 bg-white">
                        <Card.Body className="p-0 d-flex">
                            <div className="payments-card-image col-4 d-flex justify-content-center align-items-center">
                                <FontAwesomeIcon className="img-fluid w-25 h-100 m-0" icon={["fal", "file-invoice-dollar"]} color="black" />
                            </div>
                            <div className="d-flex flex-column w-100 py-2 px-3">
                                <p className="text-body h6 pt-1 m-0 pb-2 text-break">Total received for this application</p>
                                <p className="text-body h1 fw-light pb-1">{formatMoney(rebatesTotal)}</p>
                            </div>
                        </Card.Body>
                    </Card>
                    {!isMobile ? (
                        <Table>
                            <caption className="visually-hidden">Payments list</caption>
                            <DataGridHead>
                                <tr className="fw-bold">
                                    <th className="px-2 py-2 col-4">Payee</th>
                                    <th className="px-2 py-2 col-4">Amount</th>
                                    <th className="px-2 py-2 col-4">Date</th>
                                    <th className="px-2 py-2 col-4">Actions</th>
                                </tr>
                            </DataGridHead>
                            <tbody>
                                {rebates?.map((r) => (
                                    <tr key={r.rebateNumber}>
                                        <td data-label="Payee">
                                            <div className="d-flex align-items-center gap-2">
                                                <Avatar color={getContactColor(r.payee)} text={getContactName(r.payee)} />
                                                <span className="small">{r.payee}</span>
                                            </div>
                                        </td>
                                        <td data-label="Amount">
                                            <span className="fw-semibold">{formatMoney(r.amount)} •</span>
                                            <span className="small"> {r.rebateType}</span>
                                        </td>
                                        <td data-label="Date">
                                            <div className="d-flex align-items-center small">{formatDateAndTime(r.dateEntered)}</div>
                                        </td>
                                        <td data-label="Actions">
                                            <Button
                                                className="text-nowrap d-flex align-items-center p-0"
                                                variant="link"
                                                disabled={isInIframe()}
                                                onClick={() => setActiveRebate(r)}
                                                aria-haspopup="dialog"
                                            >
                                                <FontAwesomeIcon icon={["fal", "circle-arrow-right"]}></FontAwesomeIcon>
                                                <p className="m-0 px-2 py-2">View</p>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="gap-3 d-flex flex-column">
                            {rebates.map((r) => (
                                <div key={r.rebateNumber} className="flex-column w-100">
                                    <div className="flex-column px-1 py-1 bg-white bg-opacity-10 row justify-content-center">
                                        <div className="d-flex align-items-center border-bottom">
                                            <p className="m-0 py-2 col-4 fw-bold">Payee</p>
                                            <div className="d-flex align-items-center gap-2 py-2 text-break">
                                                <Avatar badgeSize="sm" color={getContactColor(r.payee)} text={getContactName(r.payee)} />
                                                <span className="small">{r.payee}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center border-bottom">
                                            <p className="m-0 py-2 col-4 fw-bold">Amount</p>
                                            <span>
                                                <span className="fw-semibold">{formatMoney(r.amount)} •</span>
                                                <span className="text-normal small"> {r.rebateType}</span>
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center border-bottom">
                                            <p className="m-0 py-2 col-4 fw-bold">Date</p>
                                            <div className="d-flex align-items-center small">{formatDateAndTime(r.dateEntered)}</div>
                                        </div>
                                        <div className="d-flex align-items-center border-bottom">
                                            <p className="m-0 py-2 col-4 fw-bold">Actions</p>
                                            <div className="d-flex w-100 justify-content-end pe-4">
                                                <Button
                                                    className="text-nowrap d-flex align-items-center p-0"
                                                    variant="link"
                                                    disabled={isInIframe()}
                                                    onClick={() => setActiveRebate(r)}
                                                    aria-haspopup="dialog"
                                                    title="View Payment"
                                                >
                                                    <FontAwesomeIcon icon={["fal", "circle-arrow-right"]}></FontAwesomeIcon>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <EmptyStatePlaceholder
                    className="py-5"
                    placeholderImage={<PaymentsPlaceholder />}
                    title="No payments available for this application"
                    description="There are currently no payment records associated with this application."
                />
            )}

            <Offcanvas
                className="submitted-app-view-rebate-sidebar"
                show={!!activeRebate}
                placement="end"
                onHide={() => setActiveRebate(null)}
                aria-labelledby="view-payment-title"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="payment-title" className="fs-4">
                        Payment
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {activeRebate && (
                        <div className="d-flex flex-column h-100">
                            <dl className="mb-4 gap-3 d-flex flex-column text-body">
                                <DescriptionListItem
                                    labelClassName="col-sm-12 text-capitalize fw-semi-bold"
                                    fieldName="Amount"
                                    fieldValue={
                                        <p className="fw-light" style={{ fontSize: "2.5rem" }}>
                                            {formatMoney(activeRebate.amount, "—")}
                                        </p>
                                    }
                                />
                                <DescriptionListItem
                                    labelClassName="col-sm-12 fw-semi-bold text-capitalize"
                                    fieldName="Payment Type"
                                    fieldClassName="fw-normal"
                                    fieldValue={activeRebate.rebateType}
                                />
                                <DescriptionListItem
                                    fieldClassName="w-100 small"
                                    labelClassName="col-sm-12 fw-semi-bold text-capitalize"
                                    fieldName="Payee"
                                    fieldValue={
                                        <div className="d-flex align-items-center gap-2 text-break">
                                            <Avatar
                                                badgeSize="sm"
                                                color={getContactColor(activeRebate.payee)}
                                                text={getContactName(activeRebate.payee)}
                                            />
                                            {activeRebate.payee}
                                        </div>
                                    }
                                />
                                <DescriptionListItem
                                    labelClassName="col-sm-12 fw-semi-bold text-capitalize"
                                    fieldName="Check Date"
                                    fieldValue={
                                        activeRebate.checkDate ? (
                                            <p className="m-0 fw-normal">{formatDateAndTime(activeRebate.dateEntered)}</p>
                                        ) : undefined
                                    }
                                />
                                <div className="row align-items-center">
                                    <DescriptionListItem
                                        className="d-flex flex-column col-6"
                                        labelClassName="col-sm-12 fw-semi-bold text-capitalize"
                                        fieldName="Check Number"
                                        fieldClassName="fw-normal"
                                        fieldValue={activeRebate.checkNumber}
                                    />
                                    <DescriptionListItem
                                        className="d-flex flex-column col-6"
                                        labelClassName="col-sm-12 fw-semi-bold text-capitalize"
                                        fieldName="Check Amount"
                                        fieldClassName="fw-normal"
                                        fieldValue={formatMoney(activeRebate.checkAmount, "—")}
                                    />
                                </div>
                                <div className="row align-items-start">
                                    <DescriptionListItem
                                        className="d-flex flex-column col-6"
                                        labelClassName="col-sm-12 fw-semi-bold text-capitalize"
                                        fieldName="Resolution Status"
                                        fieldClassName="fw-normal"
                                        fieldValue={activeRebate.checkResolutionStatus}
                                    />
                                    <DescriptionListItem
                                        className="d-flex flex-column col-6"
                                        labelClassName="col-sm-12 fw-semi-bold text-body text-capitalize"
                                        fieldName="Resolution Date"
                                        fieldClassName="d-flex w-100 flex-row fw-normal"
                                        fieldValue={
                                            <div className={cn({ "d-flex flex-column": isMobile })}>
                                                <p className="m-0">{formatDateAndTime(activeRebate.dateEntered, "", true).formattedDate}</p>
                                                <p className="m-0 text-lowercase">
                                                    {formatDateAndTime(activeRebate.dateEntered, "", true).formattedTime}
                                                </p>
                                            </div>
                                        }
                                    />
                                </div>
                            </dl>
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

const DescriptionListItem = ({
    fieldName,
    fieldValue,
    labelClassName,
    fieldClassName,
    className,
}: {
    fieldName?: string;
    fieldValue?: string | JSX.Element | number;
    labelClassName?: string;
    fieldClassName?: string;
    className?: string;
}) => (
    <div className={className}>
        <dt className={cn("col-sm-4 text-uppercase font-weight-semi-bold", labelClassName)}>{fieldName}</dt>
        <dd className={cn("col-sm-8", fieldClassName)}>{fieldValue ?? "—"}</dd>
    </div>
);

interface ApplicationPaymentsProps {
    rebates: ApplicationRebate[];
}
