import "./style.scss";

export const ImagePlaceholder = ({ text = "image", textStyle }: ImagePlaceholderProps) => {
    return (
        <div className="image-placeholder d-flex w-100 h-100">
            <span className="image-placeholder-text m-auto" style={textStyle}>
                {text}
            </span>
        </div>
    );
};

interface ImagePlaceholderProps {
    /**
     * Placeholder text. Default value "image"
     */
    text?: string;

    /**
     * Placeholder text CSS styles.
     */
    textStyle?: React.CSSProperties;
}
