import { AppContext } from "components/App/AppContext";
import { PageLink } from "components/App/utils";
import { Heading } from "components/Heading";
import { JsonForm } from "components/JsonForm";
import { changeAccountDetails } from "components/utils";
import { httpGetAuthorized } from "components/utils/http";
import { isEqual } from "lodash";
import { useState, useCallback, useContext } from "react";
import { Container } from "components/Layout/Container";
import { useHistory } from "react-router-dom";
import useSWR, { Key } from "swr";
import { SuccessPage } from "./SuccessPage";

export function AccountDetailsPage() {
    const { data } = useSWR(process.env.REACT_APP_CUSTOMERS_ENDPOINT as Key, httpGetAuthorized);
    const [showSubmit, setShowSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        requirements: { requireAccountNumber },
    } = useContext(AppContext);

    const onChange = ({ formData }: { formData: any }) => {
        if (isEqual(formData, data)) {
            setShowSubmit(false);
        } else {
            setShowSubmit(true);
        }
    };

    const history = useHistory<{ submitted: boolean }>();
    const submitted = history?.location?.state?.submitted === true;

    const schema = {
        type: "object",
        required: ["firstName", "lastName"],
        properties: {
            firstName: {
                type: "string",
                title: "First Name",
            },
            lastName: {
                type: "string",
                title: "Last Name",
            },
            accountNumber: {
                type: "string",
                title: "Utility Account Number",
            },
        },
    };

    const uiSchema = {
        firstName: {
            "ui:widget": "text",
            "ui:autofocus": true,
        },
        lastName: {
            "ui:widget": "text",
        },
        accountNumber: {
            "ui:widget": requireAccountNumber ? "text" : "hidden",
        },
    };

    const onSubmit = useCallback(
        async ({ formData }: any) => {
            setIsSubmitting(true);
            changeAccountDetails(formData)
                .then(() => history.replace(PageLink.AccountDetails, { submitted: true }))
                .finally(() => {
                    setIsSubmitting(false);
                });
        },
        [history]
    );

    return submitted ? (
        <SuccessPage text="Your account details have been successfully changed!" />
    ) : (
        <Container className="p-0">
            <Heading>Account details</Heading>
            <JsonForm
                schema={schema}
                uiSchema={uiSchema}
                children={!showSubmit}
                onChange={onChange}
                formData={data}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                formName="Account details"
            />
        </Container>
    );
}
