import { ExternalLinkIcon } from "components/Icon/ExternalLinkIcon";

export const ExternalLink = ({ children, className, href, openInNewTab = true, ...props }: ExternalLinkProps) => {
    const target = openInNewTab ? "_blank" : "_self";

    return (
        <a rel="noopener noreferrer" className={className} target={target} href={href} {...props}>
            {children}
            {openInNewTab && <ExternalLinkIcon />}
        </a>
    );
};

interface ExternalLinkProps {
    children: React.ReactNode;
    className?: string;
    openInNewTab?: boolean;
    href: string;
    [key: string]: any;
}
