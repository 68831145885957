import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { useEffect } from "react";
import { BreadcrumbItem, EquipmentCatalog } from "./types";

export const useCatalogNavigationAnalytics = (items: BreadcrumbItem[], activeCatalog: EquipmentCatalog | undefined) => {
    const categoriesPath = items.map((item) => item.name).join("/");
    const breadcrumbPath = activeCatalog ? `${categoriesPath}/${activeCatalog.name}` : categoriesPath;

    useEffect(() => {
        sendAnalyticsEvent(AnalyticsEventType.CATALOG_NAVIGATE, {
            path: breadcrumbPath,
        });
    }, [breadcrumbPath]);
};
