import { WidgetProps } from "@rjsf/core";
import { DataGrid } from "components/DataGrid";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { useEquipmentList } from "components/EquipmentBlock/useEquipmentList";
import { formatMoney } from "components/utils/money";
import { Spinner } from "react-bootstrap";

import "./style.scss";

export const RebateTotalGridWidget = (props: WidgetProps) => {
    const { applicationNumber } = props.formContext ?? {};
    const [equipmentList = [], isLoadingEquipmentList] = useEquipmentList(applicationNumber);

    if (isLoadingEquipmentList) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading rebate total grid...</span>
                </Spinner>
            </div>
        );
    }

    const sum = equipmentList.reduce((acc, equipment) => acc + equipment.incentive * equipment.quantity, 0);

    return (
        <DataGrid className="rebate-total-grid-widget">
            <DataGridHead>
                <tr>
                    <th>Equipment Name</th>
                    <th className="text-end">Estimated Total Incentive</th>
                </tr>
            </DataGridHead>
            <tbody>
                {equipmentList.map((equipment) => (
                    <tr key={equipment.equipid}>
                        <td className="rounded-0">{equipment.name}</td>
                        <td className="text-lg-end rounded-0">{formatMoney(equipment.incentive * equipment.quantity)}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr className="rounded shadow-sm">
                    <th scope="row">Estimated Total Project Incentive</th>
                    <th className="text-lg-end">{formatMoney(sum)}</th>
                </tr>
            </tfoot>
        </DataGrid>
    );
};
