import { toArray } from "components/utils/array";
import { isInIframe } from "components/utils/dom";
import { getUrl, httpGetAuthorized } from "components/utils/http";
import useSWR from "swr";
import { ContactRequirements } from "types/ContactRequirements";
import { RequireContact } from "types/RequireContact";

export const useApplicationContactRequirements = (
    applicationNumber: string
): [requirements: ContactRequirements, isLoading: boolean, error: any] => {
    const url =
        applicationNumber && !isInIframe() ? getUrl(process.env.REACT_APP_APPLICATION_CONTACTS_REQ_ENDPOINT, { applicationNumber }) : null;
    const { data, error } = useSWR(url, httpGetAuthorized);
    const isLoading = !error && !data;

    if (isInIframe()) {
        return [mockedData, false, undefined];
    }

    const requirements: ContactRequirements = {
        requirePrimaryContact: data?.formConfiguration.requirePrimaryContact,
        requirePremise: data?.formConfiguration.requirePremise,
        requireContractor: data?.formConfiguration.requireContractor,

        primaryDescription: data?.formConfiguration.primaryDescription ?? "",
        premiseDescription: data?.formConfiguration.premiseDescription ?? "",
        contractorDescription: data?.formConfiguration.contractorDescription ?? "",

        requiredFields: toArray(data?.formConfiguration.requiredFields),
        hiddenFields: toArray(data?.formConfiguration.hiddenFields),

        disableAddlContactValidation: data?.formConfiguration.disableAddlContactValidation === "TRUE",
    };

    return [requirements, isLoading, error];
};

const mockedData: ContactRequirements = {
    requirePrimaryContact: RequireContact.NotRequired,
    requirePremise: RequireContact.Required,
    requireContractor: RequireContact.NotRequired,
    primaryDescription: "",
    premiseDescription: "",
    contractorDescription: "",
    requiredFields: [
        {
            field: "premise_firstname",
        },
        {
            field: "premise_lastname",
        },
        {
            field: "premise_company",
        },
        {
            field: "premise_address",
        },
        {
            field: "premise_city",
        },
        {
            field: "premise_state",
        },
        {
            field: "premise_zip",
        },
        {
            field: "premise_email",
        },
        {
            field: "premise_acct_number",
        },
        {
            field: "premise_taxid",
        },
        {
            field: "premise_premiseid",
        },
        {
            field: "premise_phone",
        },
        {
            field: "primary_firstname",
        },
        {
            field: "primary_lastname",
        },
        {
            field: "primary_company",
        },
        {
            field: "primary_address",
        },
        {
            field: "primary_city",
        },
        {
            field: "primary_state",
        },
        {
            field: "primary_zip",
        },
        {
            field: "primary_email",
        },
        {
            field: "primary_taxid",
        },
        {
            field: "primary_premiseid",
        },
        {
            field: "primary_phone",
        },
        {
            field: "contractor_firstname",
        },
        {
            field: "contractor_lastname",
        },
        {
            field: "contractor_company",
        },
        {
            field: "contractor_address",
        },
        {
            field: "contractor_city",
        },
        {
            field: "contractor_state",
        },
        {
            field: "contractor_zip",
        },
        {
            field: "contractor_email",
        },
        {
            field: "contractor_acct_number",
        },
        {
            field: "contractor_taxid",
        },
        {
            field: "contractor_premiseid",
        },
        {
            field: "contractor_meterid",
        },
        {
            field: "contractor_phone",
        },
    ],
    hiddenFields: [],
    disableAddlContactValidation: true,
};
