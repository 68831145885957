import { isNil } from "lodash";

export const CURRENCY = process.env.REACT_APP_CURRENCY;
export const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
export const CURRENCY_LOCALE = process.env.REACT_APP_CURRENCY_FORMAT_LOCALE;

// Number formatter.
const formatter = new Intl.NumberFormat(CURRENCY_LOCALE, {
    style: "currency",
    currency: CURRENCY,
});

export const formatMoney = (value, defaultValue = "") => {
    if (isNil(value)) {
        return defaultValue;
    }

    let formattedValue = formatter.format(value);

    if (formattedValue === CURRENCY_SYMBOL + "NaN") {
        formattedValue = `${CURRENCY_SYMBOL}${value}`;
    }

    return formattedValue;
};
