import useWidgetStyles from "components/utils/useWidgetStyles";
import { useCallback } from "react";
import ButtonComponent from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { ButtonProps } from "types/ButtonProps";
import cn from "classnames";
import { handleLinkClick } from "components/utils/links";

export function Button(props: ButtonProps) {
    const history = useHistory();
    const style = useWidgetStyles({
        ...(props ?? {}),
        borderColor: props?.backgroundColor,
        width: props?.buttonWidth === "fullWidth" ? "100%" : undefined,
    });

    let className = props.className;
    if (props?.variant === "secondary" && props?.secondaryButtonType) {
        className = cn(className, props.secondaryButtonType);
    }

    const onClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            if (props.onClick) {
                return props.onClick(event);
            }

            handleLinkClick(event, props.href, props.target, history);
        },
        [history, props]
    );

    const { buttonWidth, secondaryButtonType, ...commonProps } = props;

    return (
        <ButtonComponent {...commonProps} className={className} style={style} onClick={onClick}>
            {props.text ?? props.children}
        </ButtonComponent>
    );
}
