import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { DataGridColumnConfig } from "components/DataGrid/types";
import { capitalize, isEmpty } from "lodash";
import { getAriaSortAttribute } from "./utils";

export const DataGridHeader = (props: DataGridHeaderProps) => {
    const { columns, hasActionsColumn = true, onSortChange, stickyActionsColumn, canSelectRows } = props;

    if (isEmpty(columns)) {
        return null;
    }

    return (
        <tr className="data-grid-header">
            {canSelectRows && <th className="py-2 bg-white" />}
            {columns.map((column, index) => {
                const columnName = (column.name ?? "").split(" ").map(capitalize).join(" ");

                let sortTitle;
                switch (column.sort) {
                    case "ASC":
                        sortTitle = `Sort By ${columnName} Descending`;
                        break;
                    default:
                        sortTitle = `Sort By ${columnName} Ascending`;
                        break;
                }

                let sortIcon;
                switch (column.sort) {
                    case "ASC":
                        sortIcon = <FontAwesomeIcon icon="arrow-down-short-wide" size="sm" />;
                        break;
                    case "DESC":
                        sortIcon = <FontAwesomeIcon icon="arrow-up-short-wide" size="sm" />;
                        break;
                    default:
                        sortIcon = <FontAwesomeIcon icon="arrow-down-arrow-up" size="sm" />;
                        break;
                }

                return (
                    <th
                        key={index}
                        scope="col"
                        className={cn(column.className, { sorted: column.sort, hidden: column.hideHeader }, "py-1 bg-white")}
                        aria-sort={getAriaSortAttribute(column)}
                    >
                        {!column.hideHeader && (
                            <button
                                onClick={() => onSortChange(column.key)}
                                title={sortTitle}
                                aria-label={sortTitle}
                                className={cn("data-grid-column-sort w-100 d-flex flex-row align-items-center gap-2 p-2")}
                            >
                                <div className="my-auto text-capitalize text-nowrap">{column.name}</div>
                                {sortIcon}
                            </button>
                        )}
                    </th>
                );
            })}

            {hasActionsColumn && (
                <th scope="col" className={cn("py-1 bg-white action-column", { "sticky-column-right": stickyActionsColumn })}>
                    Actions
                </th>
            )}
        </tr>
    );
};

interface DataGridHeaderProps {
    columns: DataGridColumnConfig[];
    hasActionsColumn?: boolean;
    onSortChange: (key: string) => void;
    stickyActionsColumn?: boolean;
    canSelectRows?: boolean;
}
