import { Button } from "components/Button";
import { Form, Offcanvas, Stack } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { completeWorkflowTask } from "components/WorkflowTasks/utils";
import { TaskProps } from "components/WorkflowTasks/types";
import { SubmitButton } from "components/Button/SubmitButton";
import { RawHTML } from "components/RawHTML";

export const CheckboxTask = ({ applicationNumber, task, onComplete, onClose, isTaskCompleted }: TaskProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isError, setIsError] = useState<any>();

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setIsSubmitting(true);

            if (!e.currentTarget.perform.checked) {
                setIsError(true);
                return;
            }
            await completeWorkflowTask(applicationNumber, task);

            onComplete(task);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Offcanvas show placement="end" onHide={onClose} aria-labelledby="task-title">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-4" id="task-title">
                    {isTaskCompleted ? "Done - My completed task" : "My to-do task"}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                {!isTaskCompleted ? (
                    <Form className="d-flex flex-column" noValidate onSubmit={onSubmit}>
                        <Form.Group>
                            <Form.Check
                                className="bg-white perform-task"
                                type="checkbox"
                                name="perform"
                                aria-label="Perform task"
                                aria-invalid={isError}
                                aria-describedby={isError ? "message-error" : undefined}
                                isInvalid={isError}
                                disabled={isSubmitting}
                                label="Perform task"
                                required
                                feedback={
                                    <div className="perform-task-error position-relative" id="perform-error">
                                        Please check this form to submit the task
                                    </div>
                                }
                                onChange={() => setIsError(false)}
                                feedbackType="invalid"
                            />
                        </Form.Group>
                        <p className="m-0 py-2 fw-medium text-break">{task.step}</p>
                        {task.content && <RawHTML className="m-0 py-2 text-break">{task.content}</RawHTML>}
                        <Stack className="justify-content-start gap-2 pt-4" direction="horizontal">
                            <SubmitButton spinnerText="Submitting..." type="submit" variant="primary" isSubmitting={isSubmitting}>
                                Submit task
                            </SubmitButton>
                            <Button secondaryButtonType="filled" variant="secondary" onClick={onClose} disabled={isSubmitting}>
                                Cancel
                            </Button>
                        </Stack>
                    </Form>
                ) : (
                    <div className="d-flex flex-column px-2">
                        <p className="d-flex m-0 py-2 fw-medium align-items-center">
                            <FontAwesomeIcon aria-label="done" className="pe-2" icon={["fal", "circle-check"]} size="lg" color="green" />
                            Perform task
                        </p>
                        <div className="d-flex flex-column ps-4">
                            <p className="m-0 text-break fw-medium ps-1">{task.step}</p>
                            {task.content && <RawHTML className="py-2 text-break ps-1">{task.content}</RawHTML>}
                        </div>
                    </div>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};
