import { WidgetProps } from "@rjsf/core";
import { dateStringToJsonDate, dateToJson, localizeJsonDate, localizeJsonDateTime, localizeJsonTime } from "components/utils/date";
import { format } from "date-fns";

// For limiting year in the input to four digits
const MAX_DATE = "9999-12-31";

const getLimitDatetime = (limitDate: string, max: boolean, dateFormat?: string) => {
    if (limitDate && dateFormat === "date-time") {
        const date = new Date(limitDate);
        if (max) {
            date.setHours(23, 59);
        }
        return format(new Date(date), "yyyy-MM-dd'T'HH:mm");
    } else {
        return limitDate;
    }
};
const DateWidget = (props: WidgetProps) => {
    const { registry, formContext, schema, value, onChange } = props;
    const { TextWidget } = registry.widgets;

    const inputType = getInputType(schema.format);

    const localizeDateValues = formContext?.localizeDateValues ?? false;
    let dateValue = value;

    if (localizeDateValues) {
        dateValue = dateStringToJsonDate(value, schema.format);
    }

    const onDateChange = (value: any) => {
        if (localizeDateValues) {
            let formattedDate;

            switch (schema.format) {
                case "date-time":
                    formattedDate = localizeJsonDateTime(value);
                    break;
                case "time":
                    formattedDate = localizeJsonTime(value);
                    break;
                default:
                    formattedDate = localizeJsonDate(value);
                    break;
            }

            onChange(formattedDate);
        } else {
            onChange(value);
        }
    };

    //@ts-ignore "formatMinimum" is custom prop not compatible with json schema.
    const min = schema.formatMinimum === "today" ? dateToJson(new Date()) : schema.formatMinimum;

    //@ts-ignore
    const max = schema.formatMaximum === "today" ? dateToJson(new Date()) : schema.formatMaximum;

    return (
        <TextWidget
            {...props}
            min={getLimitDatetime(min, false, schema.format)}
            max={getLimitDatetime(max ?? MAX_DATE, true, schema.format)}
            value={dateValue}
            onChange={onDateChange}
            type={inputType}
        />
    );
};

const getInputType = (format?: string) => {
    let inputType;

    switch (format) {
        case "date-time":
            inputType = "datetime-local";
            break;
        case "time":
            inputType = "time";
            break;
        default:
            inputType = "date";
            break;
    }

    return inputType;
};

export default DateWidget;
