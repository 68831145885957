import { SubmittedAppData } from "types/SubmittedAppData";
import useMediaQuery from "components/utils/useMediaQuery";
import { MobileView } from "./MobileView";
import { DesktopView } from "./DesktopView";

import "./style.scss";

export const ApplicationMessages = (props: { applicationNumber: string; appData: SubmittedAppData; onChanged?: () => void }) => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    return isMobile ? <MobileView {...props} /> : <DesktopView {...props} />;
};
