import React from "react";
import { Form } from "react-bootstrap";
import { DataGridColumnConfig } from "./types";
import { getColumnFilterInputType } from "./utils";
import { DateInput } from "../Input/DateInput";

export const DataGridFilterInput = (props: DataGridFilterInputProps) => {
    const { column, value, onChange, onKeyDown } = props;
    const inputType = getColumnFilterInputType(column);

    const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(event, true);
    };

    if (column.filter !== "true") {
        return null;
    }

    if (inputType === "select") {
        return (
            <Form.Select aria-label={column.name} value={value} onChange={onSelectChange} id={`${column.key}-filter`}>
                <option value=""></option>
                {(column.selectValues ?? []).map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.title}
                    </option>
                ))}
            </Form.Select>
        );
    }

    if (inputType === "date") {
        return (
            <DateInput
                aria-label={column.name}
                type={inputType}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                id={`${column.key}-filter`}
            />
        );
    }

    return (
        <Form.Control
            aria-label={column.name}
            type={inputType}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            id={`${column.key}-filter`}
        />
    );
};

interface DataGridFilterInputProps {
    column: DataGridColumnConfig;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, submitFilter?: boolean) => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
