import { useLayoutEffect } from "react";

export const useCustomHtml = (html: string | undefined) => {
    useLayoutEffect(() => {
        // Remove if already found
        let customHtml = document.querySelector(".custom-html");
        if (customHtml) {
            customHtml.remove();
        }

        if (html) {
            try {
                inject(html);
            } catch (error) {
                console.error(error);
            }
        }
    }, [html]);
};

const inject = (html: string) => {
    // Create a node with decoded html. Do not add it to the body.
    const srcNode = document.createElement("div");
    srcNode.innerHTML = decodeURIComponent(html);

    // Create a node where to put custom html and append it to the body
    const targetNode = document.createElement("div");
    targetNode.classList.add("custom-html");
    document.body.appendChild(targetNode);

    // load scripts one by one
    injectScripts(srcNode, targetNode)
        // Trigger window load event when all scripts are loaded. Some scripts are waiting for this event.
        .then(() => {
            dispatchEvent(new Event("load"));
        })
        .catch((error) => {
            console.error(error);
        });
};

async function injectScripts(srcNode: Element, targetNode: Element) {
    if (srcNode.tagName === "SCRIPT") {
        const script = cloneScript(srcNode);

        // Wait for load event if script has src attribute
        if (script.hasAttribute("src")) {
            const loadPromise = new Promise((resolve) => {
                script.addEventListener("load", resolve);
            }).catch((error) => {
                console.error(error);
            });

            // Inject the script
            targetNode.appendChild(script);

            // Wait for load to finish
            await loadPromise;
        } else {
            // Inject the script
            targetNode.appendChild(script);
        }
    } else {
        let i = -1;
        while (++i < srcNode.children.length) {
            const child = srcNode.children[i];
            await injectScripts(child, targetNode);
        }
    }
}

function cloneScript(node: Element) {
    const script = document.createElement("script");
    script.text = node.innerHTML;

    let i = -1;
    while (++i < node.attributes.length) {
        script.setAttribute(node.attributes[i].name, node.attributes[i].value);
    }

    return script;
}
