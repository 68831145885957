import { Modal } from "react-bootstrap";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { ReactElement } from "react";

type Props = { content: ReactElement; title?: string; icon: IconName; show: boolean; onHide: () => void };

export function HelpModalWidget(props: Readonly<Props>) {
    return (
        <Modal centered scrollable animation={false} show={props.show} onHide={props.onHide} id="help-modal" aria-labelledby="help-modal">
            <Modal.Header closeButton className="align-items-start">
                <Modal.Title id="help-modal-title">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.content}</Modal.Body>
        </Modal>
    );
}
