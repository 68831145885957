import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "components/Icon";

export const IconWithText = ({ icon, title, children }: { icon: IconName; title?: string; children: React.ReactNode }) => {
    return (
        <div className="d-flex align-items-center gap-2">
            <Icon icon={["fal", icon]} title={title} />
            {children}
        </div>
    );
};
