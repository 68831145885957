import { DataGridColumnConfig, DataGridRowConfig } from "components/DataGrid/types";
import { getColumnSortState, getVisibleColumns } from "components/DataGrid/utils";
import { DataGrid } from "components/DataGrid";
import { DataGridBody } from "components/DataGrid/DataGridBody";
import { DataGridFilter } from "components/DataGrid/DataGridFilter";
import { DataGridFilterChips } from "components/DataGrid/DataGridFilterChips";
import { DataGridHead } from "components/DataGrid/DataGridHead";
import { DataGridHeader } from "components/DataGrid/DataGridHeader";
import { Button } from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RelationsManagementGridColumnKeys } from "../types";
import React, { useCallback, useMemo, useState } from "react";
import { SubmitButton } from "components/Button/SubmitButton";
import { Form, Modal } from "react-bootstrap";
import { getErrorMessage } from "components/utils/http";
import { useToast } from "components/Toast";
import { relateApplication } from "components/utils";
import cn from "classnames";

export const RelationManagementGrid = ({
    projectNumber,
    items,
    gridConfig,
    sortBy,
    sortAsc,
    onSortChange,
    onFilterChange,
    filter,
    isLoadingGrid,
    applicationNumber,
    onRelationsUpdated,
    selectedRows,
    setSelectedRows,
}: RelationManagementGridProps) => {
    const columns = getVisibleColumns(gridConfig);
    const [showModal, setShowModal] = useState(false);
    const [showBulkModal, setShowBulkModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTargetAppRelated, setIsTargetAppRelated] = useState(false);
    const [selectedApp, setSelectedApp] = useState<string | null>("");
    const toast = useToast();

    const onRelateApplication = useCallback(
        async (targetApplicationNumbers: Set<string> | string) => {
            const targetApplications = Array.from(
                typeof targetApplicationNumbers === "string" ? [targetApplicationNumbers] : targetApplicationNumbers
            );
            setIsSubmitting(true);
            let response;
            try {
                if (applicationNumber) {
                    response = await relateApplication(applicationNumber, targetApplications);
                    setSelectedApp("");
                    setSelectedRows(new Set([]));
                }
            } catch (error) {
                toast.error(getErrorMessage(error));
                setIsSubmitting(false);
            } finally {
                toast.success(response?.responseMessage);
                setIsSubmitting(false);
                setShowModal(false);
                setShowBulkModal(false);
                onRelationsUpdated();
            }
        },
        [applicationNumber, onRelationsUpdated, toast, setSelectedRows]
    );

    const rows: DataGridRowConfig[] = (items || []).map((item) => ({
        data: item,
        actions: [
            {
                content: () => (
                    <Button
                        className={cn("px-3", { "text-danger": item.isRelated })}
                        variant="link"
                        onClick={() => handleApplicationRelation(item[RelationsManagementGridColumnKeys.appId], item.isRelated)}
                        title={item.isRelated ? "Remove Relation" : "Add Relation"}
                        aria-haspopup="dialog"
                    >
                        <FontAwesomeIcon icon={item.isRelated ? ["fal", "xmark"] : ["fal", "sitemap"]} size="lg" />
                    </Button>
                ),
            },
        ],
    }));

    const isAllRowsSelected = useMemo(() => {
        if (rows && rows.length > 0 && selectedRows.size > 0) {
            return rows
                .filter((row) => !row.data.isRelated)
                .every((row) => selectedRows.has(row.data[RelationsManagementGridColumnKeys.appId] ?? ""));
        }
        return false;
    }, [rows, selectedRows]);

    const isAllRowsRelated = useMemo(() => {
        if (rows && rows.length > 0) {
            return rows.every((row) => row.data.isRelated);
        }
        return false;
    }, [rows]);

    const handleApplicationRelation = (appId: string, isRelated: boolean) => {
        setSelectedApp(appId);
        setIsTargetAppRelated(isRelated);
        setShowModal(true);
    };

    const formattedColumns: DataGridColumnConfig[] = columns.map((col) => ({
        ...col,
        sort: getColumnSortState(col, sortBy, sortAsc),
        name: col.name.split("_").join(" "),
    }));

    const handleSelectAllRows = () => {
        const updatedSelectedRows = new Set(selectedRows);
        if (!isAllRowsSelected) {
            rows.forEach((row) => {
                const appId = row.data[RelationsManagementGridColumnKeys.appId];
                if (!row.data.isRelated && appId) {
                    updatedSelectedRows.add(appId);
                }
            });
            setSelectedRows(updatedSelectedRows);
        } else {
            setSelectedRows(new Set([]));
        }
    };

    const handleSelectRow = (row: DataGridRowConfig) => {
        const appId = row.data[RelationsManagementGridColumnKeys.appId];
        const updatedSelectedRows = new Set(selectedRows);
        if (appId) {
            if (updatedSelectedRows.has(appId)) {
                updatedSelectedRows.delete(appId);
            } else {
                updatedSelectedRows.add(appId);
            }
            setSelectedRows(updatedSelectedRows);
        }
    };

    const isRowSelected = useCallback(
        (row: DataGridRowConfig) => {
            const appId = row.data[RelationsManagementGridColumnKeys.appId];
            return appId !== null && selectedRows.has(appId);
        },
        [selectedRows]
    );

    const isRowSelectable = (row: DataGridRowConfig) => {
        const appId = row.data[RelationsManagementGridColumnKeys.appId];
        return appId !== null && !row.data.isRelated;
    };

    return (
        <>
            <Button
                variant="primary"
                className="d-flex justify-self-start me-auto mb-2"
                disabled={!selectedRows.size}
                onClick={() => setShowBulkModal(true)}
                title="Relate Selected Applications"
                aria-haspopup="dialog"
            >
                Relate Selected Application{selectedRows.size > 1 && "s"}
            </Button>
            <DataGridFilterChips
                className="d-md-none pb-2 w-100"
                gridConfig={gridConfig}
                filter={filter}
                formTitle="Filter"
                onFilterChange={onFilterChange}
            >
                {!isAllRowsRelated && (
                    <div className="d-flex align-items-center gap-2">
                        <p className="pt-1 m-0">{isAllRowsSelected ? `${selectedRows.size} Selected` : "Select All"}</p>
                        <Form.Check className="select-all-checkbox" checked={isAllRowsSelected} onChange={() => handleSelectAllRows()} />
                    </div>
                )}
            </DataGridFilterChips>
            <DataGrid withWrapper={false}>
                <DataGridHead className="sticky-top">
                    <DataGridHeader columns={formattedColumns} onSortChange={onSortChange} stickyActionsColumn canSelectRows />
                    <DataGridFilter
                        columns={columns}
                        filter={filter}
                        onFilterChange={onFilterChange}
                        canSelectRows
                        handleSelectAllRows={handleSelectAllRows}
                        allRowsSelected={isAllRowsSelected}
                        stickyActionsColumn
                    />
                </DataGridHead>
                <DataGridBody
                    rows={rows}
                    columns={formattedColumns}
                    isLoadingData={isLoadingGrid}
                    nothingFoundMessage="No Results Found."
                    canSelectRows
                    handleSelectRow={handleSelectRow}
                    stickyActionsColumn
                    isRowSelected={isRowSelected}
                    isRowSelectable={isRowSelectable}
                />
            </DataGrid>
            {showModal && (
                <SingleApplicationRelationModal
                    projectNumber={projectNumber}
                    isTargetAppRelated={isTargetAppRelated}
                    onRelateApplication={onRelateApplication}
                    selectedApp={selectedApp}
                    isSubmitting={isSubmitting}
                    setShowModal={setShowModal}
                />
            )}
            {showBulkModal && (
                <BulkApplicationRelationModal
                    projectNumber={projectNumber}
                    onRelateApplication={onRelateApplication}
                    selectedRows={selectedRows}
                    isSubmitting={isSubmitting}
                    setShowBulkModal={setShowBulkModal}
                />
            )}
        </>
    );
};

const BulkApplicationRelationModal = ({
    projectNumber,
    setShowBulkModal,
    onRelateApplication,
    selectedRows,
    isSubmitting,
}: {
    projectNumber?: string;
    setShowBulkModal: React.Dispatch<React.SetStateAction<boolean>>;
    onRelateApplication: any;
    selectedRows: Set<string>;
    isSubmitting: boolean;
}) => {
    return (
        <Modal show onHide={() => setShowBulkModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Relate Applications</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to relate {selectedRows.size > 1 ? "these" : "this"} application{selectedRows.size > 1 && "s"} to{" "}
                    <b>#{projectNumber}</b>?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <SubmitButton
                    onClick={() => {
                        onRelateApplication(selectedRows);
                    }}
                    isSubmitting={isSubmitting}
                    variant="primary"
                    spinnerText="Is Relating..."
                >
                    Yes
                </SubmitButton>
                <Button onClick={() => setShowBulkModal(false)} variant="secondary">
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
const SingleApplicationRelationModal = ({
    projectNumber,
    isTargetAppRelated,
    onRelateApplication,
    selectedApp,
    isSubmitting,
    setShowModal,
}: {
    projectNumber?: string;
    isTargetAppRelated: boolean;
    onRelateApplication: any;
    selectedApp: string | null;
    isSubmitting: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    return (
        <Modal show onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{!isTargetAppRelated ? "Relate Application" : "Remove Relation"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {`Are you sure you want to ${!isTargetAppRelated ? "add this relation to" : "remove this relation from"}`}
                <b> #{projectNumber}</b>?
            </Modal.Body>
            <Modal.Footer>
                <SubmitButton
                    onClick={() => {
                        onRelateApplication(selectedApp!);
                    }}
                    isSubmitting={isSubmitting}
                    variant="primary"
                    spinnerText={isTargetAppRelated ? "Is Removing..." : "Is Relating..."}
                >
                    Yes
                </SubmitButton>
                <Button onClick={() => setShowModal(false)} variant="secondary">
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

interface RelationManagementGridProps {
    gridConfig: any;
    items: any[] | undefined;
    sortBy: string;
    sortAsc: boolean;
    onSortChange: (key: string) => void;
    onFilterChange: (filter: string) => void;
    filter?: string;
    isLoadingGrid: boolean;
    applicationNumber: string;
    onRelationsUpdated: () => void;
    selectedRows: Set<string>;
    setSelectedRows: React.Dispatch<React.SetStateAction<Set<string>>>;
    projectNumber?: string;
}
