export interface Margin {
    margin?: string;

    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
}

export const MARGIN_PROPERTY_KEYS: (keyof Margin)[] = ["margin", "marginBottom", "marginLeft", "marginRight", "marginTop"];
