import { useState, useContext } from "react";
import { AppContext } from "components/App/AppContext";
import { Container } from "components/Layout/Container";
import { NotificationText } from "./NotificationText";
import { NotificationCloseIcon } from "./NotificationCloseIcon";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { NotificationIcon } from "./NotificationIcon";
import { NotificationContainer } from "./NotificationContainer";

import "./Notification.scss";

export function Notification() {
    const [showNotification, setShowNotification] = useState(true);
    const { settings } = useContext(AppContext);

    const notificationType = settings?.notificationType;
    const notificationText = settings?.notificationText;
    const notificationIcon = settings?.notificationIcon;
    const notificationCloseIcon = settings?.notificationCloseIcon;

    const onClose = (event: React.MouseEvent) => {
        event.preventDefault();
        setShowNotification(false);
        sendAnalyticsEvent(AnalyticsEventType.NOTIFICATION_CLOSE, {
            level: "utility",
            type: notificationType ?? "Not set",
        });
    };

    if (!showNotification) {
        return null;
    }

    return (
        <NotificationContainer
            className="utility-notification position-relative"
            notificationType={notificationType}
            ariaLabel="Notification"
        >
            <Container className="d-flex flex-row">
                {notificationIcon && (
                    <NotificationIcon className="notification-icon flex-shrink-0" notificationType={notificationType} gradientId="g1" />
                )}
                {notificationText && <NotificationText notificationText={notificationText} />}
            </Container>
            {notificationCloseIcon && <NotificationCloseIcon onClick={onClose} />}
        </NotificationContainer>
    );
}

export function ProgramNotification() {
    const [showNotification, setShowNotification] = useState(true);
    const { settings } = useContext(AppContext);

    const notificationType = settings?.programNotificationType;
    const notificationText = settings?.programNotificationText;
    const notificationIcon = settings?.programNotificationIcon;
    const notificationCloseIcon = settings?.programNotificationCloseIcon;

    const onClose = (event: React.MouseEvent) => {
        event.preventDefault();
        setShowNotification(false);
        sendAnalyticsEvent(AnalyticsEventType.NOTIFICATION_CLOSE, {
            level: "program",
            type: notificationType ?? "Not set",
        });
    };

    if (!showNotification) {
        return null;
    }

    return (
        <NotificationContainer
            className="program-notification position-relative"
            notificationType={notificationType}
            ariaLabel="Program notification"
        >
            <Container className="d-flex flex-row">
                {notificationIcon && (
                    <NotificationIcon
                        className="notification-icon notification-icon--program flex-shrink-0"
                        notificationType={notificationType}
                        gradientId="g2"
                    />
                )}
                {notificationText && <NotificationText notificationText={notificationText} />}
            </Container>
            {notificationCloseIcon && <NotificationCloseIcon onClick={onClose} />}
        </NotificationContainer>
    );
}
