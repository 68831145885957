import React from "react";

import { WidgetProps } from "@rjsf/core";
import Form from "react-bootstrap/Form";
import { datePartFromJsonDate, dateToJson, formatJsonDate } from "components/utils/date";
import { ListGroup } from "react-bootstrap";
import { escapePipeSign, unescapePipeSign } from "components/utils/string";

const SignatureWidget = (props: WidgetProps) => {
    const { id, value, onChange, options, registry, rawErrors, uiSchema, ...restProps } = props;
    const { TextWidget, CheckboxWidget } = registry.widgets;

    const errors = parseErrors(rawErrors);
    const signatureError = errors.signature;
    const confirmationError = errors.confirmation;
    const signatureRawErrors = signatureError ? [signatureError] : undefined;
    const confirmationRawErrors = confirmationError ? [confirmationError] : undefined;

    const signatureId = `${id}-signature`;
    const confirmationId = `${id}-confirm`;

    const currentDateTime = datePartFromJsonDate(dateToJson(new Date())!);

    const [signature, checked, , signatureDate] = (value || "").split("|");
    const isChecked = checked === "true";
    const displayDate = signatureDate ? formatJsonDate(signatureDate) : formatJsonDate(currentDateTime);

    const onSignatureChange = (value: string | undefined) => {
        onChange(`${escapePipeSign(value ?? "")}|${isChecked}|${escapePipeSign(options.confirmCheckboxTitle)}|${currentDateTime}`);
    };

    const onCheckboxChange = (value: boolean) => {
        onChange(`${escapePipeSign(signature ?? "")}|${value}|${escapePipeSign(options.confirmCheckboxTitle)}|${currentDateTime}`);
    };

    return (
        <div className="signature-block-widget d-flex flex-column gap-3">
            <Form.Group>
                <TextWidget
                    {...props}
                    id={signatureId}
                    value={unescapePipeSign(signature)}
                    onChange={onSignatureChange}
                    rawErrors={signatureRawErrors}
                />
                <ErrorList id={signatureId} rawErrors={signatureRawErrors} />
            </Form.Group>
            {signature && (
                <div className="d-flex flex-column w-100">
                    <span className="font-signature fs-2 border-bottom border-2 border-dark text-break lh-0p5 pt-1">
                        {unescapePipeSign(signature ?? "") as string}
                    </span>
                    <span className="align-self-end fs-7">Date: {displayDate}</span>
                </div>
            )}
            <Form.Group>
                <CheckboxWidget
                    {...restProps}
                    id={confirmationId}
                    label={options.confirmCheckboxTitle}
                    value={isChecked}
                    onChange={onCheckboxChange}
                    options={options}
                    registry={registry}
                    rawErrors={confirmationRawErrors}
                />
                <ErrorList id={confirmationId} rawErrors={confirmationRawErrors} />
            </Form.Group>
        </div>
    );
};

const ErrorList: React.FC<{
    id: string;
    rawErrors: string[] | undefined;
}> = ({ id, rawErrors }) => {
    if (!rawErrors || rawErrors.length === 0) {
        return null;
    }

    return (
        <ListGroup id={`${id}-error`} as="ul" className="invalid-feedback">
            {rawErrors.map((error) => {
                return (
                    <ListGroup.Item as="li" key={error} className="border-0 m-0 p-0">
                        <span className="m-0 text-danger">{error}</span>
                    </ListGroup.Item>
                );
            })}
        </ListGroup>
    );
};

const parseErrors = (rawErrors: string[] | undefined) => {
    if (!rawErrors) {
        return {};
    }

    let errors: { [key: string]: string } = {};

    try {
        errors = JSON.parse(rawErrors[0] ?? "{}");
    } catch (e) {
        errors = {};
    }

    return errors;
};

export default SignatureWidget;
