import { getUrl, httpDeleteAuthorized, httpPostAuthorized, httpPutAuthorized } from "./http";

export async function updateCustomerContact(body: ContactData, contactNumber: string) {
    const url = getUrl(process.env.REACT_APP_UPDATE_CUSTOMER_CONTACTS_ENDPOINT, { contactNumber });
    if (!url) throw new Error("Update customer contact url is not defined");
    return httpPutAuthorized(url, body);
}

export async function createCustomerContact(body: ContactData) {
    return httpPostAuthorized(process.env.REACT_APP_CREATE_CUSTOMER_CONTACTS_ENDPOINT ?? "", body);
}

export async function deleteCustomerContact(contactNumber: string) {
    const url = getUrl(process.env.REACT_APP_UPDATE_CUSTOMER_CONTACTS_ENDPOINT, { contactNumber });
    if (!url) throw new Error("Delete customer contact url is not defined");
    return httpDeleteAuthorized(url);
}

export const getRequiredFieldsByContactType = (allRequiredFields: RequiredField[], contactType: ContactType) => {
    return allRequiredFields
        .filter((f) => f.field.startsWith(contactType) && !f.field.endsWith("fax"))
        .map((f) => f.field.substring(contactType.length + 1));
};

export const getHiddenFieldsByContactType = (allHiddenFields: HiddenField[], contactType: ContactType) => {
    return allHiddenFields
        .filter((f) => f.field && f.field.startsWith(contactType))
        .map((f) => f.field && f.field.substring(contactType.length + 1));
};

export interface ContactData {
    contactType: number;
    contactTitle: string;
    firstName?: string;
    lastName?: string;
    acct_number?: string;
    address?: string;
    address_cont?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone?: string;
    cell?: string;
    fax?: string;
    company?: string;
    email?: string;
    taxId?: string;
    premiseId?: string;
    meterId?: string;
    workflowTargetGroupId?: number;
    saveContact: boolean;
    customerNumber?: string;
    refappid?: string;
    refid?: string;
    contactNumber?: string;
    storedContactNumber?: string;
    applicationContactType: string;
}
export interface ApplicationContact {
    contactNumber: string;
    contactType: string;
    contactTitle?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    address?: string;
    addressCont?: string;
    city?: string;
    state?: string;
    zip?: string;
    accountNumber?: string;
    phone?: string;
    cell?: string;
    fax?: string;
    email?: string;
    premiseId?: string;
    taxId?: string;
    meterId?: string;
    origId?: string;
    storedContactNumber?: string;
}

export enum ContactType {
    Premise = "premise",
    Primary = "primary",
    Contractor = "contractor",
    Other = "other",
}

export type RequiredField = {
    field: string;
};

export type HiddenField = RequiredField;
export function isContactDataEmpty(contactData: any) {
    for (const key in contactData) {
        if (key !== "_contactType" && contactData[key]) {
            return false;
        }
    }
    return true;
}
export interface ContractorData {
    address: string | null;
    fullAddress?: string | null;
    city: string | null;
    company: string | null;
    contactNumber: string | null;
    contactTitle: string;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    services: string | null;
    state: string | null;
    technologies: string | null;
    zip: string | null;
    premiseId?: string;
    meterId?: string;
    acct_number?: string;
    address_cont?: string;
    cell?: string;
    fax?: string;
    taxId?: string;
    url?: string;
    description?: string;
    origId?: string;
}
