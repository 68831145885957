import { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ErrorSummary } from "components/ErrorSummary";
import { ApplicationContacts } from "./ApplicationContacts";
import { AppContext } from "components/App/AppContext";
import { WorkflowTasks } from "./WorkflowTasks";
import { useApplicationViewActivity } from "components/Page/SubmittedApplicationV2/useApplicationViewActivity";
import { isEmpty } from "lodash";
import { PowerBiReport } from "components/PowerBiReport";
import { PowerBiReportEntityType } from "types/PowerBiReportEntityType";
import { ApplicationPayments } from "./ApplicationPayments";

import { isInIframe } from "components/utils/dom";
import { useApplicationContactRequirements } from "components/JsonForm/theme/widgets/ApplicationContactsWidget/useApplicationContactRequirements";
import { useApplicationDetails } from "./useApplicationDetails";
import { ApplicationHeader } from "./ApplicationHeader";
import { ApplicationTab } from "components/App/utils";
import { ApplicationForms } from "./ApplicationForms";
import { ApplicationMessages } from "./ApplicationMessages";
import { ApplicationEquipment } from "./ApplicationEquipment";
import { ApplicationFiles } from "./ApplicationFiles";
import { RelatedApplications } from "./RelatedApplications";

import "./style.scss";

export function SubmittedApplicationPage() {
    const location = useLocation();

    const applicationNumber = new URLSearchParams(location.search).get("applicationNumber") ?? (isInIframe() ? "???" : "");

    const activeTab = useMemo(() => {
        const tab = new URLSearchParams(location.search).get("activeTab");
        if (tab && Object.values(ApplicationTab).includes(tab as ApplicationTab)) {
            return tab as ApplicationTab;
        } else {
            return ApplicationTab.Tasks;
        }
    }, [location.search]);

    const { requirements, settings } = useContext(AppContext);

    const [appData, isLoadingApplicationDetails, appDetailsLoadingError, refreshAppDetails] = useApplicationDetails(applicationNumber);
    const [contactRequirements, isLoadingContactRequirements, contactRequirementsLoadingError] =
        useApplicationContactRequirements(applicationNumber);

    const isLoading = (isLoadingApplicationDetails || isLoadingContactRequirements) && !isInIframe();
    const loadingError = appDetailsLoadingError ?? contactRequirementsLoadingError;

    const [errorSummary, setErrorSummary] = useState<any>();
    useApplicationViewActivity(applicationNumber, refreshAppDetails);

    if (isLoading) {
        return (
            <div
                className="submitted-application-v2 py-5 d-flex justify-content-center container-xxl"
                style={{ maxWidth: settings.maxWidth }}
            >
                <Spinner className="flex-shrink-0" animation="border" role="status">
                    <span className="visually-hidden">Loading application</span>
                </Spinner>
            </div>
        );
    }

    if (loadingError) {
        return (
            <div className="submitted-application-v2 container-xxl pt-3" style={{ maxWidth: settings.maxWidth }}>
                <ErrorSummary errorSummary={loadingError} />
            </div>
        );
    }

    return (
        <>
            <ApplicationHeader
                activeTab={activeTab}
                setErrorSummary={setErrorSummary}
                contactRequirements={contactRequirements}
                refreshAppDetails={refreshAppDetails}
                appData={appData}
            />
            <div className="submitted-application-v2 mx-auto w-100" style={{ maxWidth: settings.maxWidth }}>
                <div className="d-flex flex-column container-xxl px-md-4 py-5 mt-0">
                    <ErrorSummary errorSummary={errorSummary} />

                    {activeTab === ApplicationTab.Tasks && (
                        <WorkflowTasks applicationNumber={applicationNumber} onAfterTaskComplete={refreshAppDetails} />
                    )}
                    {activeTab === ApplicationTab.Inbox && appData && (
                        <ApplicationMessages applicationNumber={applicationNumber} appData={appData} onChanged={refreshAppDetails} />
                    )}
                    {activeTab === ApplicationTab.Files && appData?.applicationFiles && (
                        <ApplicationFiles
                            applicationNumber={applicationNumber}
                            files={appData.applicationFiles}
                            setErrorSummary={setErrorSummary}
                        />
                    )}
                    {activeTab === ApplicationTab.RelatedApplications && (
                        <RelatedApplications
                            applicationNumber={applicationNumber}
                            projectNumber={appData?.projectNumber}
                            refreshAppDetails={refreshAppDetails}
                            hasRelatedApplications={!appData?.applicationFlags.hideRelatedApplications}
                        />
                    )}
                    {activeTab === ApplicationTab.Payments && appData && <ApplicationPayments rebates={appData.applicationRebates} />}
                    {activeTab === ApplicationTab.Equipment && appData && (
                        <ApplicationEquipment
                            applicationNumber={applicationNumber}
                            readOnly={appData?.applicationFlags.isEditableEquipment}
                        />
                    )}

                    {activeTab === ApplicationTab.Forms && appData && (
                        <ApplicationForms applicationNumber={applicationNumber} appData={appData} onFormChanged={refreshAppDetails} />
                    )}

                    {activeTab === ApplicationTab.Contacts && appData && (
                        <ApplicationContacts
                            applicationNumber={applicationNumber}
                            appData={appData}
                            contactRequirements={contactRequirements}
                            onContactChanged={refreshAppDetails}
                        />
                    )}

                    {activeTab === ApplicationTab.Report && !isEmpty(requirements.portalDashboardName) && (
                        <PowerBiReport
                            reportId={requirements.portalDashboardName}
                            entityNumber={applicationNumber}
                            entityTypeId={PowerBiReportEntityType.Application}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
