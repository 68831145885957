import cn from "classnames";
import { AppContext } from "components/App/AppContext";
import { EquipmentBlockContext } from "components/utils/contexts";
import { useCallback, useContext, useMemo, useState } from "react";
import { EquipmentAdd } from "./EquipmentAdd";
import { EquipmentEdit } from "./EquipmentEdit";
import { EquipmentList } from "./EquipmentList";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";
import { EquipmentListItem } from "types/EquipmentListItem";
import { EquipmentEditSubmitItem } from "types/EquipmentEditSubmitItem";
import { EquipmentBlockContextValue } from "types/EquipmentBlockContextValue";
import { refreshEquipmentList } from "./useEquipmentList";
import { createEquipment, deleteEquipment, updateEquipment } from "./utils";
import { EquipmentCopy } from "./EquipmentCopy";
import { useToast } from "components/Toast";

export const EquipmentBlock = ({ className, applicationNumber, readonly, allowEquipmentCopy }: EquipmentBlockProps) => {
    const [activeEquipment, setActiveEquipment] = useState<EquipmentListItem>();
    const [activeCopyEquipment, setActiveCopyEquipment] = useState<EquipmentListItem>();
    const [showEquipmentAdd, setShowEquipmentAdd] = useState<boolean>(false);

    const { requirements } = useContext(AppContext);
    const toast = useToast();

    const allowEquipmentEntry = readonly ?? requirements.allowEquipmentEntry;

    const onEquipmentAddSubmit = useCallback(
        async (equipmentItem: EquipmentAddSubmitItem) => {
            const response = await createEquipment(applicationNumber, equipmentItem);
            setShowEquipmentAdd(false);
            toast.success(response.responseMessage);
            refreshEquipmentList(applicationNumber);
        },
        [applicationNumber, toast]
    );

    const onEquipmentCopySubmit = useCallback(
        async (equipmentItem: EquipmentEditSubmitItem, hasApprovedMeasure?: boolean) => {
            const response = await createEquipment(applicationNumber, equipmentItem);
            setActiveCopyEquipment(undefined);
            toast.success(hasApprovedMeasure ? "Approved measure successfully created" : response.responseMessage);
            refreshEquipmentList(applicationNumber);
        },
        [applicationNumber, toast]
    );

    const onEquipmentEditSubmit = useCallback(
        async (equipmentNumber: string, equipmentItem: EquipmentEditSubmitItem, hasApprovedMeasure?: boolean) => {
            const response = await updateEquipment(applicationNumber, equipmentNumber, equipmentItem);
            setActiveEquipment(undefined);
            toast.success(hasApprovedMeasure ? "Approved measure successfully updated" : response.responseMessage);
            refreshEquipmentList(applicationNumber);
        },
        [applicationNumber, toast]
    );

    const onEquipmentAddClose = useCallback(() => {
        setShowEquipmentAdd(false);
        refreshEquipmentList(applicationNumber);
    }, [applicationNumber]);

    const onEquipmentDelete = useCallback(
        async (equipmentNumber: string, hasApprovedMeasure?: boolean) => {
            const response = await deleteEquipment(applicationNumber, equipmentNumber);
            toast.success(hasApprovedMeasure ? "Approved measure successfully deleted" : response.responseMessage);
            refreshEquipmentList(applicationNumber);
        },
        [applicationNumber, toast]
    );

    const contextValue: EquipmentBlockContextValue = useMemo(
        () => ({
            applicationNumber,
            allowEquipmentEntry,
            allowEquipmentCopy: readonly ?? allowEquipmentCopy,
            onEquipmentAdd: () => setShowEquipmentAdd(true),
            onEquipmentAddSubmit,
            onEquipmentAddClose,
            onEquipmentEdit: setActiveEquipment,
            onEquipmentEditSubmit,
            onEquipmentEditClose: () => setActiveEquipment(undefined),
            onEquipmentCopy: setActiveCopyEquipment,
            onEquipmentCopySubmit,
            onEquipmentCopyClose: () => setActiveCopyEquipment(undefined),
            onEquipmentDelete,
        }),
        [
            applicationNumber,
            allowEquipmentEntry,
            allowEquipmentCopy,
            onEquipmentAddSubmit,
            onEquipmentAddClose,
            onEquipmentEditSubmit,
            onEquipmentCopySubmit,
            onEquipmentDelete,
            readonly,
        ]
    );

    return (
        <EquipmentBlockContext.Provider value={contextValue}>
            <div className={cn("equipment-block", className)}>
                <EquipmentList />
                {showEquipmentAdd && <EquipmentAdd />}
                {activeEquipment && <EquipmentEdit item={activeEquipment} readonly={!allowEquipmentEntry} />}
                {activeCopyEquipment && <EquipmentCopy item={activeCopyEquipment} />}
            </div>
        </EquipmentBlockContext.Provider>
    );
};

interface EquipmentBlockProps {
    className?: string;
    applicationNumber: string;
    readonly?: boolean;
    allowEquipmentCopy?: boolean;
}
