export enum RequireContact {
    ReadOnly = "r",
    /** show only form with required fields */
    Required = "TRUE",
    /** show only form with not required fields */
    NotRequired = "FALSE",
    /** can select from list and check if readonly form is filled */
    RequiredFromList = "l",
    /** can select from list without check if readonly form is filled */
    NotRequiredFromList = "lFALS",
    /** do not show contractor section */
    Hidden = "h",
}
