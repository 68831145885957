import { updateAlignClasses, updateDisplayClasses, updateFontSizeClasses, updateFontWeightClasses } from "components/Layout/utils";
import { useMemo } from "react";
import { WidgetClassesProps } from "types/WidgetClassesProps";

const useWidgetClasses = (props: WidgetClassesProps) => {
    const {
        className,
        alignX,
        alignY,
        fontSize,
        fontWeight,
        alignXClasses,
        alignYClasses,
        includeVisibilityClasses = false,
        hidden,
        showOnDesktop = true,
        showOnTablet = true,
        showOnMobile = true,
    } = props;

    return useMemo(() => {
        // Get list of classNames
        let result = (className ?? "").split(" ").filter((i) => i);

        // alignX
        result = updateAlignClasses(result, alignX, alignXClasses);

        // alignY
        result = updateAlignClasses(result, alignY, alignYClasses);

        // display
        if (!hidden && includeVisibilityClasses) {
            result = updateDisplayClasses(result, showOnDesktop, showOnTablet, showOnMobile);
        }

        // font size
        result = updateFontSizeClasses(result, fontSize);

        // font weight
        result = updateFontWeightClasses(result, fontWeight);

        return result.join(" ");
    }, [
        className,
        alignX,
        alignXClasses,
        alignY,
        alignYClasses,
        includeVisibilityClasses,
        hidden,
        fontSize,
        fontWeight,
        showOnDesktop,
        showOnTablet,
        showOnMobile,
    ]);
};

export default useWidgetClasses;
