import { SubmitButton } from "components/Button/SubmitButton";
import { ApplicationForm } from "../../ApplicationPage/ApplicationForm";
import { useApplicationFormPage } from "components/utils/useApplicationFormPage";
import { useMemo, useRef, useState } from "react";
import {
    getApplicationFormData,
    submitApplicationWizard,
    validateContactsWidget,
    waitForEncryptedFields,
} from "components/Page/ApplicationPage/utils";
import { SUBMITTED_APP_FORM_WIDGETS_TO_REMOVE, submitByRefPromise } from "components/JsonForm/utils";
import { Alert, Card, Spinner } from "react-bootstrap";
import { getErrorMessage } from "components/utils/http";
import { useToast } from "components/Toast";
import { Icon } from "components/Icon";

export const AppForm = ({
    applicationNumber,
    appFormData,
    onFormChanged,
}: {
    applicationNumber: string;
    appFormData: SubmittedAppFormPage;
    onFormChanged?: () => void;
}) => {
    const pageNumber = appFormData.pageNumber;

    const formRef = useRef();
    const contactsWidgetRef = useRef();
    const workflowWidgetRef = useRef();
    const additionalContactsWidgetRef = useRef();
    const encryptState = useRef(new Map()); // Keys of encrypted fields with editing in progress
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toast = useToast();

    const [applicationForm, isLoadingApplicationForm, applicationFormError] = useApplicationFormPage(applicationNumber, pageNumber);
    const allowFormEdit = applicationForm?.formAllowance.allowFormEdit;

    const formConfig = useMemo(() => {
        if (!applicationForm?.formConfiguration) {
            return applicationForm?.formConfiguration;
        }

        const uiSchema = applicationForm?.formConfiguration?.uiSchema;
        if (uiSchema) {
            // Do not render the page title in the form
            uiSchema["ui:title"] = "";
        }

        return {
            ...applicationForm?.formConfiguration,
            uiSchema,
        };
    }, [applicationForm?.formConfiguration]);

    const formName = formConfig?.schema?.title;

    const onSubmit = async () => {
        try {
            if (!formConfig || !allowFormEdit) {
                throw new Error("Form is not editable");
            }

            setIsSubmitting(true);

            try {
                // Wait for all encrypted fields to finish editing.
                await waitForEncryptedFields(encryptState.current);

                // validate contacts widget
                await validateContactsWidget(contactsWidgetRef, formName);

                // validate additional contacts widget
                await validateContactsWidget(additionalContactsWidgetRef, formName);

                // Validate application form
                await submitByRefPromise(formRef);
            } catch (error) {
                // Do nothing if form has validation errors
                setIsSubmitting(false);
                return;
            }

            const { applicationItems, applicationContacts } = getApplicationFormData(
                formRef,
                contactsWidgetRef,
                additionalContactsWidgetRef,
                formConfig,
                applicationForm?.formDetails?.fields
            );

            const response = await submitApplicationWizard(applicationNumber, pageNumber, false, {
                applicationItems,
                applicationContacts,
            });

            toast.success(response.responseMessage);
            setIsSubmitting(false);
            onFormChanged?.();
        } catch (error) {
            // Show error message if form submission failed
            toast.error(getErrorMessage(error));
            setIsSubmitting(false);
        }
    };

    if (isLoadingApplicationForm) {
        return (
            <Spinner className="align-self-center flex-shrink-0" animation="border" role="status">
                <span className="visually-hidden">Loading form {formName}</span>
            </Spinner>
        );
    }

    if (applicationFormError) {
        return <Alert variant="danger">Error loading form. {applicationFormError}</Alert>;
    }

    return (
        <div id={pageNumber} className="w-100 d-flex flex-column gap-3">
            <h3 className="m-0">{formName}</h3>
            <div className="d-flex flex-column gap-1">
                {!appFormData.allowFormEdit && (
                    <Alert role="presentation" variant="secondary" className="d-flex gap-2 align-items-center m-0">
                        <Icon icon={["fal", "pen-slash"]} />
                        <span>This form is read only and cannot be edited</span>
                    </Alert>
                )}
                <Card>
                    <Card.Body className="p-4p5 d-flex flex-column gap-3">
                        <ApplicationForm
                            className="m-0"
                            formRef={formRef}
                            contactsWidgetRef={contactsWidgetRef}
                            additionalContactsWidgetRef={additionalContactsWidgetRef}
                            workflowWidgetRef={workflowWidgetRef}
                            encryptState={encryptState}
                            applicationNumber={applicationNumber}
                            configuration={formConfig}
                            formData={applicationForm?.formDetails?.fields}
                            readonly={!allowFormEdit}
                            appSubmitted={true}
                            buttonClassName="d-none"
                            removeWidgets={SUBMITTED_APP_FORM_WIDGETS_TO_REMOVE}
                        />
                        {allowFormEdit && (
                            <SubmitButton
                                className="align-self-start"
                                onClick={onSubmit}
                                isSubmitting={isSubmitting}
                                spinnerText="Saving form..."
                            >
                                Save changes
                            </SubmitButton>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

interface SubmittedAppFormPage {
    pageNumber: string;
    pageName: string;
    allowFormEdit: boolean;
}
