import { Form, ListGroup } from "react-bootstrap";

export const FieldTemplate = (props) => {
    const { id, children, displayLabel, rawErrors = [], rawHelp, rawDescription, classNames, uiSchema } = props;
    const hasErrors = ["signature"].includes(uiSchema["ui:widget"]) ? false : rawErrors.length > 0;

    return (
        <Form.Group className={classNames}>
            {displayLabel && rawDescription ? (
                <Form.Text className={rawErrors.length > 0 ? "text-danger" : "text-muted"}>{rawDescription}</Form.Text>
            ) : null}
            {children}
            {hasErrors && (
                <ListGroup id={`${id}-error`} as="ul" className="invalid-feedback">
                    {rawErrors.map((error) => {
                        return (
                            <ListGroup.Item as="li" key={error} className="border-0 m-0 p-0">
                                <span className="m-0 text-danger">{error}</span>
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            )}
            {/* TODO: add the help modal button in the same row as help text */}
            {rawHelp && (
                <Form.Text className={rawErrors.length > 0 ? "text-danger" : "text-muted"} id={`help-${id}`}>
                    {rawHelp}
                </Form.Text>
            )}
        </Form.Group>
    );
};
