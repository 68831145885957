import { Button } from "components/Button";
import { useRef, useState } from "react";
import { ErrorSummary } from "components/ErrorSummary";
import { ApprovedEquipmentSearchFilter, ApprovedEquipmentType, EquipmentCatalog, EquipmentDetailsFromCatalog } from "./types";
import { listToAnyOf, submitByRefPromise } from "components/JsonForm/utils";
import { HiddenSubmitButton, JsonForm } from "components/JsonForm";
import Form, { ErrorSchema, IChangeEvent } from "@rjsf/core";
import { Offcanvas, Stack } from "react-bootstrap";
import { Icon } from "components/Icon";
import { ApprovedEquipmentSearchResults } from "./ApprovedEquipmentSearchResults";
import { SubmitButton } from "components/Button/SubmitButton";

export const ApprovedEquipmentSearch = ({
    equipmentTypes,
    equipmentDetails,
    catalogNumber,
    onClose,
    activeCatalog,
}: ApprovedEquipmentSearchProps) => {
    const formRef = useRef<Form<FormData>>();
    const [errorSummary, setErrorSummary] = useState<any>();
    const [activeFilter, setActiveFilter] = useState<ApprovedEquipmentSearchFilter>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [initialItemsFound, setInitialItemsFound] = useState<boolean>(false);

    const equipmentTypeCount = equipmentTypes.length;

    const [searchFields, setSearchFields] = useState<SearchFieldsSchema>(
        equipmentTypeCount === 1 ? getSearchFieldsForEquipmentType(equipmentTypes[0]) : {}
    );

    const schema = {
        type: "object",
        required: ["industryMeasureNumber"],
        properties: {
            industryMeasureNumber: {
                type: "string",
                title: "Measure Type",
                anyOf: listToAnyOf({
                    list: equipmentTypes,
                    map: (i: ApprovedEquipmentType) => ({ title: i.category, enum: [i.industryMeasureNumber] }),
                }),
                default: equipmentTypeCount === 1 ? equipmentTypes[0].industryMeasureNumber : undefined,
            },
            ...searchFields,
        },
    };

    const uiSchema = {
        industryMeasureNumber: {
            "ui:widget": equipmentTypeCount === 1 ? "hidden" : "select",
        },
    };

    const onChange = (event: IChangeEvent<FormData>, errorSchema?: ErrorSchema) => {
        const selectedEquipmentType = equipmentTypes.find((i) => i.industryMeasureNumber === event.formData.industryMeasureNumber);
        setSearchFields(getSearchFieldsForEquipmentType(selectedEquipmentType));
    };

    const onSearch = async () => {
        try {
            setIsSubmitting(true);
            const formData = await submitByRefPromise(formRef);
            setActiveFilter(formData);
        } catch (error) {
            setErrorSummary(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const onCloseSearchResults = () => {
        setActiveFilter(undefined);
        setInitialItemsFound(false);
        setSearchFields(equipmentTypeCount === 1 ? getSearchFieldsForEquipmentType(equipmentTypes[0]) : {});
    };

    if (activeFilter) {
        return (
            <ApprovedEquipmentSearchResults
                searchFilter={activeFilter}
                equipmentDetails={equipmentDetails}
                catalogNumber={catalogNumber}
                onClose={onCloseSearchResults}
                activeCatalog={activeCatalog}
                initialItemsFound={initialItemsFound}
                setInitialItemsFound={setInitialItemsFound}
            />
        );
    }

    return (
        <Offcanvas.Body className="approved-equipment-search d-flex flex-column">
            <p>Fill in the details below based on the information found on the item's packaging.</p>
            <p>If you cannot find something you can skip it. But including as much as you can helps us find the right item for you.</p>
            <p>
                <b>Tip:</b> Using an * as a wildcard in your search value can help locate potential matches to choose from.
            </p>
            <p>For example: ABC123*</p>
            <ErrorSummary errorSummary={errorSummary} />
            <JsonForm formRef={formRef} schema={schema} uiSchema={uiSchema} onChange={onChange}>
                <HiddenSubmitButton />
            </JsonForm>
            <Stack className="equipment-controls flex-shrink-0 mt-auto" direction="horizontal" gap={3}>
                <Button variant="secondary" className="text-nowrap" onClick={onClose}>
                    <Icon icon="arrow-left" className="me-1" />
                    Start Over
                </Button>
                <SubmitButton className="ms-auto" onClick={onSearch} spinnerText={"Submitting..."} isSubmitting={isSubmitting}>
                    Submit
                </SubmitButton>
            </Stack>
        </Offcanvas.Body>
    );
};

const getSearchFieldsForEquipmentType = (equipmentType: ApprovedEquipmentType | undefined) => {
    if (!equipmentType) {
        return {};
    }

    const equipmentTypeSearchFields = (equipmentType.searchFields ?? "").split(",");
    const searchFields: SearchFieldsSchema = {};

    if (equipmentTypeSearchFields.length > 0) {
        equipmentTypeSearchFields.forEach((i) => {
            const parsedField = new DOMParser().parseFromString(i, "text/xml"); // parse xml string to dom
            const title = parsedField.querySelector("displayName")?.textContent;
            const key = parsedField.querySelector("fieldName")?.textContent;

            if (title && key) {
                searchFields[key] = {
                    type: "string",
                    title: title,
                };
            }
        });
    }

    return searchFields;
};

interface ApprovedEquipmentSearchProps {
    equipmentDetails: EquipmentDetailsFromCatalog;
    catalogNumber: string;
    equipmentTypes: ApprovedEquipmentType[];
    onClose: () => void;
    activeCatalog?: EquipmentCatalog;
}

interface SearchFieldsSchema {
    [key: string]: {
        type: string;
        title: string;
    };
}

interface FormData {
    industryMeasureNumber: string;
    [key: string]: string;
}
