import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }
    componentDidCatch(error, info) {
        this.setState({ hasError: true, error });
    }
    render() {
        return this.state.hasError ? <div style={{ color: "red" }}>{"Error: " + this.state.error.message}</div> : this.props.children;
    }
}

export default ErrorBoundary;
