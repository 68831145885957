import { getUrl, httpPutAuthorized } from "components/utils/http";
import { isNil } from "lodash";
import { ApplicationItem } from "types/ApplicationItem";
import { WorkFlowTaskModel } from "./types";

export const isTaskCompleted = (task: WorkFlowTaskModel) => {
    return !isNil(task.dateCompleted) || !isNil(task.dateRemoved) || !isNil(task.dateClosed);
};

export const completeWorkflowTask = async (applicationNumber: string, task: WorkFlowTaskModel) => {
    const taskNumber = task.wfTaskNumber;
    const baseUrl = getUrl(process.env.REACT_APP_APPLICATION_WORKFLOW_TASK_ENDPOINT, { applicationNumber, taskNumber });

    const query = new URLSearchParams();
    query.append("itemType", "COMPLETETASK");
    query.append("taskType", "APPLICATION");

    const url = `${baseUrl}?${query.toString()}`;

    return httpPutAuthorized(url, undefined);
};

export async function submitApplicationItems(
    applicationNumber: string,
    taskNumber: string,
    pageNumber: string,
    applicationItems: ApplicationItem[]
) {
    const url =
        getUrl(process.env.REACT_APP_APPLICATION_ITEMS_ENDPOINT, { applicationNumber }) +
        `?taskNumber=${taskNumber}&pageNumber=${pageNumber}`;

    return httpPutAuthorized(url, {
        applicationItemList: applicationItems,
    });
}
