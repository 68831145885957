import { DataGridConfig } from "components/DataGrid/types";
import { ErrorSummaryInterface } from "types/ErrorSummary";

export enum ApplicationListColumnKeys {
    Contacts = "c_8c5a9650-feb3-4762-b4f4-2edb716a9c5d",
    AppId = "c_bfbce9eb-03bb-42dc-b4af-46052cc0cfe1",
    Status = "c_0939d01c-63e0-457f-b437-422c7bf6bfe0",
    AppStatus = "c_5255f16d-c125-40a4-821c-497cdd848f82",
    PageNumber = "c_26b6e2f0-13b7-4279-9c46-736033df03d4",
    ProjectName = "c_7ac6e76b-c3a4-4e96-ad2c-ee9ecfa53aef",
    MessagesAndTasks = "c_b1d1937c-dc3e-4fdb-a3c3-5af787aef7d9",
    Favorite = "c_d464633e-eb09-47d9-8aa7-310e69d9bee8",
    ProjectNumber = "c_051a4456-ab77-436f-8e17-81d137e0d3ec",
    DateCreated = "c_3ab0c969-4bb8-4316-8e13-c37247c8af42",
    Program = "c_84f002ef-9fa3-471b-8efe-c2eaaeecd4d5",
    Tasks = "c_4c578a4a-e1d2-41cc-b6b2-2de7d6415704",
    Messages = "c_19e30ba7-79a5-4896-9b4d-a650c5856b28",
}

export enum FilterButtonState {
    AllApps = "",
    FavoriteApps = "c_d464633e-eb09-47d9-8aa7-310e69d9bee8=1",
    IncompleteApps = "c_0939d01c-63e0-457f-b437-422c7bf6bfe0=incomplete",
}

export const DateRanges = {
    Created: "Created",
    LastWeek: "Last Week",
    LastMonth: "Last Month",
    Custom: "Custom date",
} as const;

export const SortOptions = {
    Newest: "Newest",
    Oldest: "Oldest",
    BookmarkedFirst: "Bookmarked first",
} as const;

export interface ApplicationStatus {
    Status: string;
    TotalCount: string;
}

export type FilterPair = {
    title: string;
    field: string;
    value: string;
    displayValue?: string;
};

export type FilterParams = {
    withUnreadMessages: boolean;
    statuses: string[];
    startDate: string;
    endDate: string;
    selectedCreatedDate: string;
};

export interface CustomerDashboardModel {
    isLoadingDashboard: boolean;
    isLoadingApplications: boolean;
    isExporting: boolean;
    isDeletingApplication: boolean;
    applicationsGridConfig?: DataGridConfig;
    applicationCount: number;
    totalRebate: number;
    totalNotifications: number;
    totalNotes: number;
    totalTasks: number;
    savedApplicationsCount: number;
    completedApplicationsCount: number;
    favoriteApplicationsCount: number;
    error: any;
    customerPortalDetailsError?: any;
    errorSummary?: ErrorSummaryInterface;

    searchOptions: { key: string; name: string }[];
    selectedSearchOption: { key: string; name: string };
    setSelectedSearchOption: React.Dispatch<React.SetStateAction<{ key: string; name: string }>>;
    onSearch: (event: React.FormEvent<HTMLFormElement>) => void;

    withToDoTasks: boolean;
    onToDoTasksChange: (isEnabled: boolean) => void;

    withUnreadMessages: boolean;
    setWithUnreadMessages: React.Dispatch<React.SetStateAction<boolean>>;

    selectedCreatedDate: string;
    onDateRangeChange: (dateRange: string) => void;

    startDate?: string;
    onStartDateChange: (date: string) => void;

    endDate?: string;
    onEndDateChange: (date: string) => void;

    statuses: string[];
    setStatuses: React.Dispatch<React.SetStateAction<string[]>>;

    filterButtonState: FilterButtonState;
    filterPairs: FilterPair[];
    removeFilterPair: (filterPair: FilterPair) => void;

    applyFilters: (filters?: FilterParams) => void;
    sort: (sortOption: string) => void;
    activeSortOption?: string;

    applicationStatuses: ApplicationStatus[];
    onFilterButtonStateChange: (state: FilterButtonState) => void;
    onPageChange: (pageNumber: number) => void;
    onPageSizeChange?: (pageNumber: number) => void;
    onSortChange: (key: string) => void;
    onResetFilters: () => void;
    onExportData: () => Promise<void>;
    onDeleteApplication: (appId: string) => Promise<void>;
    programNumber?: string;
}
