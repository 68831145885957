import React from "react";

import { WidgetProps } from "@rjsf/core";
import Form from "react-bootstrap/Form";
import WidgetLabel from "./WidgetLabel";

type CustomWidgetProps = WidgetProps & {
    options: any;
};

const TextareaWidget = ({
    id,
    placeholder,
    value,
    required,
    disabled,
    autofocus,
    label,
    readonly,
    onBlur,
    onFocus,
    onChange,
    options,
    schema,
    uiSchema,
    rawErrors = [],
}: CustomWidgetProps) => {
    const _onChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) =>
        onChange(value === "" ? options.emptyValue : value);
    const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLTextAreaElement>) => onBlur(id, value);
    const _onFocus = ({ target: { value } }: React.FocusEvent<HTMLTextAreaElement>) => onFocus(id, value);
    const isInvalid = rawErrors && rawErrors.length > 0;

    return (
        <>
            <WidgetLabel id={id} label={label} uiSchema={uiSchema} schema={schema} required={required} rawErrors={rawErrors} />
            <Form.Control
                id={id}
                as="textarea"
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readonly}
                isInvalid={isInvalid}
                aria-describedby={isInvalid ? `${id}-error` : undefined}
                aria-invalid={isInvalid}
                value={value}
                required={required}
                autoFocus={autofocus}
                rows={options.rows || 5}
                onChange={_onChange}
                onBlur={_onBlur}
                onFocus={_onFocus}
            />
            {schema.examples ? (
                <datalist id={`examples_${id}`}>
                    {(schema.examples as string[]).concat(schema.default ? ([schema.default] as string[]) : []).map((example: any) => {
                        return <option key={example} value={example} />;
                    })}
                </datalist>
            ) : null}
        </>
    );
};

export default TextareaWidget;
