import removeField from "./remove";
import requireField from "./require";

export const DEFAULT_ACTIONS = {
    remove: removeField,
    require: requireField,
};

export default function execute({ type, params }, schema, uiSchema, formData, extraActions = {}) {
    let action = extraActions[type] ? extraActions[type] : DEFAULT_ACTIONS[type];
    action(params, schema, uiSchema, formData);
}
